import toastr from "toastr";
import "../css/toastr.css"

class NotificationActions {
  static success(message) {
    toastr.options = {
      positionClass: "toast-top-right",
      preventDuplicates: true
    };
    toastr.success(message);
  }

  static error(message) {
    toastr.clear();
    toastr.options = {
      positionClass: "toast-top-right",
      preventDuplicates: true
    };
    toastr.error(message);
  }

  static info(message) {
    toastr.clear();
    toastr.options = {
      positionClass: "toast-top-right",
      preventDuplicates: true,
      processBar: true
    };
    toastr.info(message);
  }

  static logError(err) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
  }

  static stickyError(message, closeHandler) {
    toastr.options = {
      closeButton: true,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      timeOut: "0",
      extendedTimeOut: "0"
    };

    if (closeHandler && typeof closeHandler === "function") {
      toastr.options.onHidden = closeHandler;
    }

    toastr.error(message);
  }
}

export default NotificationActions;
