/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-state */
import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import { NavLink, Route, Redirect, Switch, Link } from "react-router-dom";
import {
  Applications,
  ApplicationTypes,
  MasterSchedule
} from "../constants/AppConstants";
import DoubleChevronExpandIcon from "../Icons/DoubleChevronExpand";
import DoubleChevronCollapseIcon from "../Icons/DoubleChevronCollapse";
import SiteIcon from "../Icons/SiteIcon";
import CalendarIcon from "../Icons/calendar";
import SliderIcon from "../Icons/Slider";
import BarChartIcon from "../Icons/BarChart";
import GearWheelIcon from "../Icons/GearWheel";
import ScheduleManagement from "../Schedule/ScheduleManagement";
import "../css/SideBar.css";
import Schedule from "../Schedule/Schedule";
import Control from "../Control/Control";
import LanguageSelector from "../Language/LanguageSelector";

// Each logical "route" has two components, one for
// the sidebar and one for the main area. We want to
// render both of them in different places when the
// path matches the current URL.

class AppLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      languageSet: true
    };
  }

  componentDidMount() {
    const { siteId } = this.props.match.params;
    const language = LanguageSelector.SelectLanguage(siteId);
    i18next.changeLanguage(language).then(t => {
      this.setState({ languageSet: t.language === language });
    });
  }


  getAppIcon = (applicationName) => {
    switch (applicationName) {
      case ApplicationTypes.Schedule:
        return (
          <CalendarIcon
            tooltip={applicationName}
          />
        );
      case ApplicationTypes.Control:
        return (
          <SliderIcon
            tooltip={applicationName}
          />
        );
      case ApplicationTypes.Monitor:
        return <BarChartIcon tooltip={applicationName} />;
      default:
        return <GearWheelIcon tooltip={applicationName} />;
    }
  };

  handleCollapsibleSidebar = () => {
    const { isCollapsed } = this.state;
    this.setState({
      isCollapsed: !isCollapsed
    });
  };

  hasAppAccess = (selectedApp,selectedSite) => {
    let hasPermission;
    if (selectedApp.Value === MasterSchedule.Value)
      hasPermission = selectedSite.appPermissions.includes(
        Applications[0].Key
      );
    else
      hasPermission= selectedSite.appPermissions.includes(selectedApp.Key);

    return hasPermission;
  };

  render() {
    const { siteId } = this.props.match.params;

    const sites = ScheduleManagement.getSites();

    const selectedSite = sites.find(site => site.id === siteId);

    const selectedField = this.props.match.params.fieldId;

    const language = LanguageSelector.SelectLanguage(siteId);
    i18next.changeLanguage(language);

    let selectedApp;
    if (MasterSchedule.Value === this.props.match.params.app)
      selectedApp = MasterSchedule;
    else
      selectedApp = Applications.find(
        a => a.Value === this.props.match.params.app
      );

    if (!selectedSite || !selectedApp) {
      return <Redirect to="/NotFound" />;
    }

    const hasPermission = this.hasAppAccess(selectedApp,selectedSite);

    if (!hasPermission && selectedSite.appPermissions.length !== 0) {
      const  res = Applications.find(app => selectedSite.appPermissions.includes(app.Key));
      selectedApp = res;
      return <Redirect to={`/Sites/${selectedSite.id}/${selectedApp.Value}`} />;
    }

    const siteList = sites.map(item => (
      <button
        key={item.id}
        className="dropdown-item eua_sidebar_site_dropdown_button pl-0 pr-0"
        role="option"
        type="button"
        aria-selected={false}
        data-value={item.id}
      >
        <Link to={`/Sites/${item.id}/${selectedApp.Value}`} data-value={item.id} className="row row-margin text-center">
          <div className="eua-sidebar-container-drop col-2 p-0">
            <SiteIcon data-value={item.id} tooltip={item.name} />
          </div>
          <p
            data-value={item.id}
            className="col pt-3 sidebar-site-name-dropdown ml-3"
          >
            {item.name}
          </p>
        </Link>
      </button>
    ));
    let applicationTypes = [];
    if (selectedSite.appPermissions.length > 0)
      applicationTypes = selectedSite.appPermissions
        .sort(a => (a === Applications[0].Key ? -1 : 0))
        .map(item => {
          const appItem = Applications.filter(a => a.Key === item)[0];
          // const appIcon = this.getAppIcon(appItem.Value);

          /* eslint react/no-children-prop: "off" */
          if (appItem === null || appItem === undefined) return null;
          return (
            <li
              className="row row-margin list-group-item pl-2"
              key={appItem.Key}
            >
              <NavLink
                activeClassName="eua-selected-navlink-sidebar"
                isActive={(match, location) => location && location.pathname.includes(appItem.Value)}
                to={`/Sites/${selectedSite.id}/${appItem.Value}`}
              >
                <div className="row pl-3 responsiveClass">
                      <div className="col eua_sidebar_icon pr-0">
                        {this.getAppIcon(appItem.Value)}
                      </div>
                  {!this.state.isCollapsed && (
                    <span className="col mt-2"> {i18next.t(appItem.Value.toUpperCase())}</span>
                  )}
                </div>
              </NavLink>
            </li>
          );
        });


    return (
      <div className="row row-margin h-100">
        {(sites.length > 1 ||
          selectedSite.appPermissions.length > 1) && (
          <nav
            className={
              this.state.isCollapsed
                ? "eua_sidebar_collapse"
                : "col-2 eua_sidebar pr-0 pl-0"
            }
          >
            {sites.length > 1 && this.state.isCollapsed && (
              <div className="eua-sidebar-container-collapsed">
                <SiteIcon tooltip={selectedSite.name} />
              </div>
            )}
            {sites.length > 1 && !this.state.isCollapsed && (
              <div className="dropdown ">
                <button
                  className="btn btn-secondary eua-sidebar-container dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div id="dropdownMenuButton">
                    <div className="eua-sidebar-container-close col-2 pr-0 pl-0">
                      <SiteIcon tooltip={selectedSite.name} />
                    </div>
                    <span className="col pt-3 pr-0 ml-3 sidebar-site-name">
                      {selectedSite.name}
                    </span>
                  </div>
                </button>
                <div
                  className=" dropdown-menu col-11"
                  aria-labelledby="dropdownMenuButton"
                >
                  {siteList}
                </div>
              </div>
            )}
            {selectedSite.appPermissions.length > 1 && (
              <ul
                className="list-group eua-app-list"
                style={{ listStyleType: "none", padding: 0 }}
              >
                {applicationTypes}
              </ul>
            )}
            <div
              className={
                this.state.isCollapsed
                  ? "eua-sidebar-collapsed-footer-width"
                  : "row"
              }
            >
              {this.state.isCollapsed && (
                <div
                  className="eua-sidebar-collapsed-footer-icon"
                  role="presentation"
                  onClick={this.handleCollapsibleSidebar}
                  onKeyPress={this.handleCollapsibleSidebar}
                >
                  <DoubleChevronExpandIcon />
                </div>
              )}
              {!this.state.isCollapsed && (
                <>
                  <hr className="row col-2 horizontal-line" />
                  <div
                    className="eua-sidebar-footer-icon"
                    role="presentation"
                    onClick={this.handleCollapsibleSidebar}
                    onKeyPress={this.handleCollapsibleSidebar}
                  >
                    <DoubleChevronCollapseIcon />
                  </div>
                </>
              )}
            </div>
          </nav>
        )}
        <section className="col row main pl-0 pr-0">
          {hasPermission && (
              <Switch>
                <Route
                  path="/Sites/:siteId/Control"
                  render={() => (
                    <Control
                      key={selectedSite.id}
                      selectedSite={selectedSite}
                      selectedField={selectedField}
                    />
                  )}
                />
                <Route
                  path={[
                    "/Sites/:siteId/Schedule/",
                    "/Sites/:siteId/MasterSchedule"
                  ]}
                  render={() => (
                    <Schedule
                      key={selectedSite.id}
                      selectedSite={selectedSite}
                      selectedField={selectedField}
                      App={selectedApp.Value}
                    />
                  )}
                />
                <Redirect
                  to={`/Sites/${selectedSite.id}/${selectedApp.Value}`}
                />
              </Switch>
          )}
          {!hasPermission && <Redirect to="/AccessDenied" />}
        </section>
      </div>
    );
  }
}

AppLanding.propTypes = {
  match: PropTypes.instanceOf(Object)
};

AppLanding.defaultProps = {
  match: null
};

export default AppLanding;
