const FrequencyTypes = {
  Never: "Never",
  Daily: "Daily",
  Weekly: "Weekly",
  Monthly: "Monthly",
  BiWeekly: "BiWeekly"
};
const Frequency = [
  { Key: 0, Value: FrequencyTypes.Never },
  { Key: 1, Value: FrequencyTypes.Daily },
  { Key: 2, Value: FrequencyTypes.Weekly },
  // { Key:3, Value: FrequencyTypes.Monthly },
  { Key: 4, Value: FrequencyTypes.BiWeekly }
];

const MasterFrequency = [
  { Key: 0, Value: FrequencyTypes.Never },
  { Key: 1, Value: FrequencyTypes.Daily },
  { Key: 2, Value: FrequencyTypes.Weekly },
  // { Key:3, Value: FrequencyTypes.Monthly },
  { Key: 4, Value: FrequencyTypes.BiWeekly }
];

export { Frequency, FrequencyTypes, MasterFrequency };
