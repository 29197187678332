import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { Modal, FormGroup, ControlLabel } from "react-bootstrap";
import FieldSchedule from "../datamodels/FieldSchedule";
import UserManagement from "../UserManagement";
import SchedulingApi from "./FieldSchedulingApi";
import MasterScheduleApi from "../MasterSchedule/MasterSchedulingApi";
import RecurringSchedule from "../components/RecurringSchedule";
import { Frequency, FrequencyTypes } from "../constants/FrequencyConstants";
import GifImageContainer from "../components/GifImageContainer";
import NotificationActions from "../helpers/Notification";
import CustomDropdown from "../components/CustomDropdown";
import CalendarIcon from "../Icons/calendar";
import SchedulingUtilities from "../helpers/SchedulingUtilities";
import ScenesDropdown from "../components/ScenesDropdown";
import "../css/Schedule.css";
import ScheduleManagement from "../Schedule/ScheduleManagement";
import * as Labels from "../constants/Labels";
import MasterScheduleManagement from "../MasterSchedule/MasterScheduleManagement";
import LanguageSelector from "../Language/LanguageSelector";
import FieldScheduleManagement from "./FieldScheduleManagement";
import { daysOfWeekKVP } from "../constants/DayOfWeekConstants";
import EditedScheduleManagement from "./EditedScheduleManagement";
import { halfFieldScenesKVP } from "../constants/ScenesConstants";

const submitButton = {
  marginLeft: "10px"
};

const DEFAULT_TIME = "-";
// function validateSchedulingDates(startDt, endDt) {
//   return moment(endDt).isSameOrBefore(startDt);
// }

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["validStartSelection"] }] */
class AddFieldScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    const frequencyType = Frequency.filter(
      f => f.Key === this.props.event.schedulingFrequency
    )[0].Value;
    const parentFrequencyType = Frequency.filter(
      f => f.Key === this.props.event.parentSchedulingFrequency
    )[0].Value;
    const permissions = ScheduleManagement.getFieldPermissions();
    const currentFieldpermissions = permissions.find(
      field => field.id === this.props.event.fieldId
    ).buttons;
    const permissionList = new Map(
      currentFieldpermissions.map(i => [i.name, i.isEnable]),
    );

    // In case of an event we consider recurring schedule start time as it holds particular event details, where as start & end holds whole series details
    const startTime = !this.props.isSeries
      ? this.props.event.recurringScheduleStartTime
      : this.props.event.start;
    const toTime = !this.props.isSeries
      ? this.props.event.recurringScheduleEndTime
      : this.props.event.end;
    const date = this.props.isSeries
      ? this.props.event.actualStartDt
      : new Date(this.props.event.recurringScheduleStartDate);

    this.state = {
      masterData: this.props.masterData,
      dataLoader: false,
      event: {
        ...this.props.event,
        fromTime: this.props.event.scheduleId ? startTime : DEFAULT_TIME,
        toTime: this.props.event.scheduleId ? toTime : DEFAULT_TIME,
        dayOfWeek: SchedulingUtilities.getDayofWeek(
          frequencyType === FrequencyTypes.Never
            ? new Date(this.props.event.start)
            : new Date(this.props.event.scheduleStartDate)
        ),
        daysOfWeek:
          parentFrequencyType === FrequencyTypes.Never ||
          parentFrequencyType === FrequencyTypes.Daily
            ? []
            : this.getDayofWeek(),
        weekOfMonth: SchedulingUtilities.getWeekOfMonth(
          frequencyType === FrequencyTypes.Never
            ? new Date(this.props.event.start)
            : new Date(this.props.event.scheduleStartDate)
        ),
        fromDate: this.props.event.scheduleId ? date : this.props.event.start, // If we are editing a schedule we consider shedule date, else if we are creating a schedule we show th enew Date() which we get in props from FieldSchedule
        frequencyType,
        parentFrequencyType,
        isLoading: false,
        isSaveInProgress: false,
        SceneEdit: false
      },
      permissionList
    };
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleOnlyStartTimeChange = this.handleOnlyStartTimeChange.bind(this);
    this.handleOnlyEndTimeChange = this.handleOnlyEndTimeChange.bind(this);
    this.validStartSelection = this.validStartSelection.bind(this);
    this.validEndSelection = this.validEndSelection.bind(this);
    this.handleSceneChange = this.handleSceneChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleFrequencyTypeChange = this.handleFrequencyTypeChange.bind(this);
    this.handleRecurringEndDtChange = this.handleRecurringEndDtChange.bind(
      this
    );
    this.handleDaysOfWeekChange = this.handleDaysOfWeekChange.bind(this);
    this.getDayofWeek = this.getDayofWeek.bind(this);
    this.handleScene = this.handleScene.bind(this);
  }

  // to convert training-right & training-left scenes to TraningRight & TrainignLeft
  handleScene = sceneName => {
    if (sceneName.includes('-')) {
      const removeHyphenFromScene = sceneName.replace(/-/g, '');
      const formattedScene = removeHyphenFromScene.charAt(0).toUpperCase() + removeHyphenFromScene.slice(1, 8) + removeHyphenFromScene.charAt(8).toUpperCase() + removeHyphenFromScene.slice(9);
      return formattedScene;
    }
    return sceneName;
  }

  handleStartTimeChange(e) {
    const me = this;
    const startDate = e.toDate();
    if (
      moment(startDate)
        .startOf("day")
        .isBefore(moment().startOf("day"))
    )
      return;

    const startMonth = new Date(this.props.startOfMonth);
    const endMonth = new Date(this.props.endOfMonth);
    const prom = new Promise((resolve, reject) => {
      if (startDate <= endMonth && startDate >= startMonth) {
        resolve();
      } else {
        me.setState({ ...me.state, dataLoader: true });
        const newStartOfMonth = moment(startDate)
          .startOf("month")
          .format("YYYY-MM-DD");
        const newEndOfMonth = moment(startDate)
          .endOf("month")
          .format("YYYY-MM-DD");

        MasterScheduleApi.getMasterScheduleForSite(
          this.props.match.params.siteId,
          newStartOfMonth,
          newEndOfMonth
        )
          .then(() => {
            const data = MasterScheduleManagement.getMasterSchedule();

            resolve(data);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      }
    });

    prom.then(data => {
      const oldScheduleStartDate = me.state.event.actualStartDt;
      let endDate = startDate;
      if (me.state.event.scheduleId) {
        const endTime = SchedulingUtilities.getformattedTime(
          me.state.event.toTime
        );
        endDate = SchedulingUtilities.concatDateTime(startDate, endTime);
      }

      me.setState({
        ...me.state,
        dataLoader: false,
        event: {
          ...me.state.event,
          start: startDate,
          end: endDate,
          dayOfWeek: SchedulingUtilities.getDayofWeek(startDate),
          weekOfMonth: SchedulingUtilities.getWeekOfMonth(startDate),
          oldScheduleStartDate,
          fromDate: startDate,
          actualStartDt: startDate
        },
        masterData: data
          ? [...me.state.masterData, ...data]
          : me.state.masterData
      });
    });
  }

  handleOnlyEndTimeChange(time) {
    const endDate =
      this.props.isSeries || this.props.isCreate
        ? SchedulingUtilities.concatDateTime(this.state.event.end, time)
        : SchedulingUtilities.concatDateTime(
            this.state.event.recurringScheduleEndTime,
            time
          );
    if (moment(endDate).isSameOrBefore(this.state.event.fromTime)) return;
    this.setState({
      event: {
        ...this.state.event,
        end: endDate,
        toTime: endDate
      }
    });
  }

  handleOnlyStartTimeChange(time) {
    const selectedDateTime =
      this.props.isSeries || this.props.isCreate
        ? SchedulingUtilities.concatDateTime(this.state.event.start, time)
        : SchedulingUtilities.concatDateTime(
            this.state.event.recurringScheduleStartTime,
            time
          );
    const oldStarTime = this.state.event.start;
    if (
      moment(
        SchedulingUtilities.getformattedTime(selectedDateTime)
      ).isSameOrAfter(
        SchedulingUtilities.getformattedTime(this.state.event.toTime)
      )
    )
      return;
    this.setState({
      event: {
        ...this.state.event,
        start: selectedDateTime,
        fromTime: selectedDateTime,
        oldStarTime
      }
    });
  }

  handleSceneChange(scene) {
    const sceneChange = this.props.event.scene !== scene;
    if (scene)
      this.setState({
        event: {
          ...this.state.event,
          scene,
          SceneEdit: sceneChange
        }
      });
  }

  handleFrequencyTypeChange(frequencyType) {
    this.setState({
      event: {
        ...this.state.event,
        frequencyType,
        parentFrequencyType: frequencyType
      }
    });
  }

  handleRecurringEndDtChange(actualEndDt) {
    this.setState({
      event: {
        ...this.state.event,
        actualEndDt,
        end: actualEndDt
      }
    });
  }

  handleDaysOfWeekChange(day, checked) {
    const daysOfWeekList = [...this.state.event.daysOfWeek];
    let elementIndex = null;
    if (daysOfWeekList.includes(day) && checked === false) {
      elementIndex = daysOfWeekList.indexOf(day);
      daysOfWeekList.splice(elementIndex, 1);
    } else daysOfWeekList.push(day);

    this.setState({
      event: {
        ...this.state.event,
        daysOfWeek: daysOfWeekList
      }
    });
  }

  onSave() {
    if (this.state.isSaveInProgress === false) {
      return;
    }

    this.setState({ isSaveInProgress: true });
    if (
      this.state.event.fromTime === DEFAULT_TIME ||
      this.state.event.toTime === DEFAULT_TIME
    ) {
      NotificationActions.error(i18next.t(Labels.START_END_TIME_REQUIRED));
      this.setState({ isSaveInProgress: false });
      return;
    }

    if (!this.state.permissionList.get(this.handleScene(this.state.event.scene))) {
      NotificationActions.error(i18next.t(Labels.USER_NO_SCENE_PERMISSION));
      this.setState({ isSaveInProgress: false });
      return;
    }

    const daysArray = [];
    this.state.event.daysOfWeek.forEach(day =>
      daysArray.push(daysOfWeekKVP.find(d => d.Key === day).Value)
    );

    // In case of editing an occurrence we cannot change date so, we are considering recurring schedule details, In case of creating an event isSeries will be false & considers recurring details to avoid that we are checking if schedule Id is there or not
    const startDate =
      this.props.isSeries === false && this.props.event.scheduleId
        ? this.state.event.recurringScheduleStartTime
        : this.state.event.actualStartDt;
    const endDate =
      this.props.isSeries === false && this.props.event.scheduleId
        ? this.state.event.recurringScheduleEndTime
        : this.state.event.actualEndDt;
    const halfFieldScene = halfFieldScenesKVP.filter(scene => scene.Key === this.state.event.scene)[0];
    const newSchedule = new FieldSchedule({
      scheduleId: this.state.event.scheduleId,
      parentScheduleId: this.state.event.parentScheduleId,
      scheduleEndDate:
        this.state.event.parentFrequencyType === "Never"
          ? SchedulingUtilities.getOnlyDate(this.state.event.start)
          : SchedulingUtilities.getOnlyDate(endDate),
      scheduleStartDate:
        this.state.event.parentFrequencyType === "Never"
          ? SchedulingUtilities.getOnlyDate(this.state.event.start)
          : SchedulingUtilities.getOnlyDate(startDate),
      endTime: SchedulingUtilities.getformattedTime(this.state.event.toTime),
      startTime: SchedulingUtilities.getformattedTime(
        this.state.event.fromTime
      ),
      scene: halfFieldScene !== undefined ? halfFieldScene.Value : this.state.event.scene,
      fieldId: this.state.event.fieldId,
      siteId: this.props.match.params.siteId,
      description: this.state.event.description,
      userName: UserManagement.getUserName(),
      schedulingFrequency: Frequency.filter(
        f => f.Value === this.state.event.parentFrequencyType
      )[0].Key,
      dayOfWeek:
        this.state.event.parentFrequencyType === "Never" ||
        this.state.event.parentFrequencyType === "Daily"
          ? []
          : daysArray,
      EditType:
        this.props.event.parentSchedulingFrequency === 0 || this.props.isSeries
          ? 0
          : 1,
      SceneEdit: this.state.event.SceneEdit
    });
    if (newSchedule.scheduleId) {
      this.onUpdate(newSchedule);

      return;
    }
    SchedulingApi.createFieldSchedule(newSchedule).then(response => {
      if (response && response.status === 200) {
        this.props.save();
        NotificationActions.success(
          i18next.t(Labels.FIELD_SCHEDULE_SAVE_SUCCESS)
        );
      } else {
        this.setState({ isSaveInProgress: false });
      }
    });
  }

  onUpdate(existingSchedule) {
    NotificationActions.success(i18next.t(Labels.EDIT_SCHEDULE_ACCEPT_MESSAGE));
    this.props.close();
    EditedScheduleManagement.addEditedScheduleList(existingSchedule.parentScheduleId);

    SchedulingApi.editFieldSchedule(existingSchedule).then(response => {
      if (response.status === 200) {
        EditedScheduleManagement.removeEditedScheduleList(response.data);
        this.props.save();
        NotificationActions.success(
          i18next.t(Labels.FIELD_SCHEDULE_UPDATE_SUCCESS)
        );
      } else {
        EditedScheduleManagement.removeEditedScheduleList(response.data);
        this.setState({ isSaveInProgress: false });
      }
    });
  }

  getDayofWeek() {
    const daysOfWeek = [];
    const schedules = FieldScheduleManagement.getFieldSchedule();
    schedules.forEach(schedule => {
      if (
        schedule.parentSchedulingFrequency !== 0 &&
        schedule.parentSchedulingFrequency !== 1
      ) {
        const eventScheduleId =
          this.props.event.parentScheduleId === null
            ? this.props.event.scheduleId
            : this.props.event.parentScheduleId;
        const scheduleIdToCompare =
          schedule.parentScheduleId === null
            ? schedule.scheduleId
            : schedule.parentScheduleId;
        if (eventScheduleId === scheduleIdToCompare) {
          if (!daysOfWeek.includes(schedule.dayOfWeek)) {
            daysOfWeek.push(schedule.dayOfWeek);
          }
        }
      }
    });
    return daysOfWeek;
  }

  validEndSelection(currentDate) {
    // if (this.state.event.frequencyType !== FrequencyTypes.Never) return false;
    const startDate = moment(this.state.event.start).subtract(1, "day");
    return currentDate.isAfter(startDate);
  }

  validStartSelection(currentDate) {
    // if (this.state.event.frequencyType !== FrequencyTypes.Never) return false;
    const yesterday = Datetime.moment().subtract(1, "day");
    return currentDate.isAfter(yesterday);
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="eua-LoadingContainer-Screen flexbox-centered-items">
          <GifImageContainer alt="Loading" src="/images/loading.gif" />
        </div>
      );
    }

    const footerButtons = (
      <div className="flexbox-column-end-item">
        <button
          type="button"
          className="btn eua-btn eua-cancel-btn"
          onClick={this.props.close}
        >
          {i18next.t(Labels.CANCEL_BUTTON)}
        </button>
        <button
          type="button"
          className="btn eua-btn eua-interact-btn"
          style={submitButton}
          onClick={this.onSave}
          disabled={this.state.isSaveInProgress}
        >
          {this.props.event.scheduleId && i18next.t(Labels.SAVE_BUTTON)}
          {!this.props.event.scheduleId && i18next.t(Labels.CREATE_EVENT)}
        </button>
      </div>
    );
    let modal = "";
    if (
      this.props.isSeries ||
      this.props.isCreate ||
      this.state.event.parentSchedulingFrequency === 0
    ) {
      modal = (
        <RecurringSchedule
          dayOfWeek={this.state.event.dayOfWeek}
          defaultDaysOfWeek={this.state.event.daysOfWeek}
          weekOfMonth={this.state.event.weekOfMonth}
          onFrequencyTypeChange={this.handleFrequencyTypeChange}
          defaultfrequencyType={this.state.event.parentFrequencyType}
          key={this.state.event.start}
          start={this.state.event.start}
          // added end
          end={
            this.props.event.scheduleId &&
            this.props.event.parentSchedulingFrequency !== 0
              ? new Date(this.state.event.actualEndDt)
              : new Date(new Date().getFullYear(), 11, 31)
          }
          onRecurringEndDateChange={this.handleRecurringEndDtChange}
          siteId={this.props.match.params.siteId}
          onDaysOfWeekChange={this.handleDaysOfWeekChange}
        />
      );
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        container={this}
        style={{ opacity: 1 }}
      >
        <Modal.Header>
          <Modal.Title style={{ fontWeight: "bold" }}>
            {!this.props.event.scheduleId && i18next.t(Labels.NEW_EVENT)}
            {this.props.event.scheduleId &&
              this.props.isSeries &&
              i18next.t(Labels.EDIT_SERIES)}
            {this.props.event.scheduleId &&
              !this.props.isSeries &&
              i18next.t(Labels.EDIT_OCCURRENCE)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="addScheduleform">
            <div className="row">
              <FormGroup controlId="formControlsDate" className="col-md-12">
                <ControlLabel>{i18next.t(Labels.DATE)}</ControlLabel>
                <div className="eua-ms-dt-control">
                  <div className="icon-container">
                    <CalendarIcon
                      isDisabled={
                        !this.props.isSeries &&
                        this.props.event.parentSchedulingFrequency !== 0
                      }
                    />
                  </div>
                  <Datetime
                    locale={LanguageSelector.SelectLanguage(
                      this.props.match.params.siteId
                    )}
                    defaultValue={this.props.event.start}
                    value={this.state.event.fromDate}
                    inputProps={{
                      readOnly: true,
                      className: "dt",
                      disabled:
                        !this.props.isSeries &&
                        this.props.event.parentSchedulingFrequency !== 0
                    }}
                    closeOnSelect
                    disableOnClickOutside={false}
                    onChange={this.handleStartTimeChange}
                    isValidDate={this.validStartSelection}
                    timeFormat={false}
                  />
                </div>
              </FormGroup>
            </div>
            <div id="masterscheduleinputcontainer">
              <div className="eua-time">
                {this.state.dataLoader ? (
                  <div className="eua-time-loader flexbox-centered-items">
                    <GifImageContainer
                      alt="Loading"
                      src="/images/loading.gif"
                    />
                  </div>
                ) : (
                  <div className="row eua-row">
                    <FormGroup
                      controlId="formControlsFromTime"
                      className="col-6"
                    >
                      <ControlLabel>{i18next.t(Labels.FROM)}</ControlLabel>
                      <CustomDropdown
                        onSelectChange={this.handleOnlyStartTimeChange}
                        startTime={SchedulingUtilities.getOnlyDate(
                          this.props.isSeries || this.props.isCreate ? this.state.event.start : this.state.event.recurringScheduleStartTime
                        )}
                        selectedTime={SchedulingUtilities.getformattedTime(
                          this.state.event.fromTime
                        )}
                        key={this.state.event.fromDate}
                        isFieldScheduleChild
                        masterData={this.state.masterData}
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="formControlsTillTime"
                      className="col-6"
                    >
                      <ControlLabel>{i18next.t(Labels.TO)}</ControlLabel>
                      <CustomDropdown
                        onSelectChange={this.handleOnlyEndTimeChange}
                        startTime={SchedulingUtilities.getOnlyDate(
                          this.props.isSeries || this.props.isCreate ? this.state.event.start : this.state.event.recurringScheduleStartTime
                        )}
                        selectedTime={SchedulingUtilities.getformattedTime(
                          this.state.event.toTime
                        )}
                        isFieldScheduleChild
                        masterData={this.state.masterData}
                      />
                    </FormGroup>
                  </div>
                )}
              </div>
              <div className="row">
                <FormGroup controlId="formControlsMode" className="pl-3 col-6">
                  <ControlLabel>{i18next.t(Labels.SCENE)}</ControlLabel>
                  <ScenesDropdown
                    selectedScene={this.state.event.scene}
                    onSelectChange={this.handleSceneChange}
                    customScenes={this.props.customScenes}
                    scenePermissions={this.state.permissionList}
                    fieldId={this.state.event.fieldId}
                  />
                </FormGroup>
              </div>
              <div className="row">{modal}</div>
              {/* To be uncommented when we implement Never ending */}
              {/* <div className="row">
                <FormGroup>
                  <Col sm={12}>
                    <Checkbox>Never Ending</Checkbox>
                  </Col>
                </FormGroup>
              </div> */}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>{footerButtons}</Modal.Footer>
      </Modal>
    );
  }
}
AddFieldScheduleModal.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  customScenes: PropTypes.instanceOf(Array).isRequired,
  masterData: PropTypes.objectOf(PropTypes.shape),
  startOfMonth: PropTypes.string.isRequired,
  endOfMonth: PropTypes.string.isRequired,
  match: PropTypes.instanceOf(Object),
  isSeries: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired
};

AddFieldScheduleModal.defaultProps = {
  masterData: [],
  match: {}
};

export default AddFieldScheduleModal;