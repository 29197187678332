/* eslint-disable no-param-reassign */
import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import moment from "moment";
import EnergyApi from "../api/EnergyApi";
import EnergyManagement from "./EnergyManagement";
import EnergyGraph from "./EnergyGraph";
import GetCompleteData from "./CompleteData";
import {
  EnergyFrequencyTypes,
  EnergyFrequencyTypesList
} from "../constants/MonitorTypeConstants";
import EnergyAggregationHelper from "../helpers/EnergyAggregationHelper";
import AggregationFilter from "./AggregationFilter";
import GifImageContainer from "../components/GifImageContainer";
import * as Labels from "../constants/Labels";

class Consumption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aggregationType: EnergyFrequencyTypes.Month,
      endDate: moment()
        .subtract(1, "day")
        .toDate(),
      startDate: moment()
        .subtract(31, "days")
        .toDate(),
      energyData: [],
      isLoading: false,
      isFirstLoad: true,
      selected_Sites: []
    };
    this.onGenerateEnergyGraph = this.onGenerateEnergyGraph.bind(this);
    this.getEnergyDataForSites = this.getEnergyDataForSites.bind(this);
    this.RangeOnClick = this.RangeOnClick.bind(this);
  }

  onGenerateEnergyGraph(aggregationType, startDate, endDate) {
    this.getEnergyDataForSites(startDate, endDate, aggregationType);
  }

  onSiteCheckboxCheck = selectedSites => {
    if (selectedSites && selectedSites.length >= 10) return;

    const result = [];
    selectedSites.forEach(site => result.push(site.key));
    this.setState({ selected_Sites: result });
  };

  getEnergyDataForSites(startDate, endDate, aggregationType) {
    this.setState({ isLoading: true, isFirstLoad: false });
    const sites = this.state.selected_Sites;
    let startingDate;
    let endingDate;
    const range = EnergyFrequencyTypesList.find(
      item => item.Key === aggregationType
    );
    if (aggregationType === EnergyFrequencyTypes.Day) {
      startingDate = startDate
      .clone()
      .startOf("day")
      .format('YYYY-MM-DDTHH:mm:ss')

      endingDate = endDate
      .clone()
      .add(1, "d")
      .startOf("day")
      .add(5, "m")
      .format('YYYY-MM-DDTHH:mm:ss')

      EnergyApi.getEnergyData(
        sites,
        startingDate,
        endingDate,
        range.Value.apiValue
      ).then(() => {
        const edata = EnergyManagement.getEnergyData();
        const temp = JSON.parse(JSON.stringify(edata));
        const data = GetCompleteData(startDate, endDate, temp, range);
        data.forEach(item => {
          let total = 0;
          let count = 0;
          item.energydata.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
          const firstReading = item.energydata[0];
          const matchingRecord = edata.find(e => e.siteId === item.siteId);
          item.energydata.forEach(entry => {
            if(!entry.isinterpolate && matchingRecord.energydata.some(energy => moment(energy.date).format("YYYY-MM-DD") === moment(entry.date).format("YYYY-MM-DD") && new Date(energy.date).getHours() === new Date(entry.date).getHours())){
            const date = new Date(entry.date)
            if(date.getHours() === 0 && firstReading.date === entry.date ){
              return;
            }
            total += entry.kwh;
            count += 1;
          }
          });
          const foundItem = this.props.sitesWithPermission.find(
            site => site.id === item.siteId
          );
          item.color = foundItem.color;
          item.siteName = foundItem.name;
          item.avg = +(total / count).toFixed(2);
          item.total = +total.toFixed(2);
        });
        this.setState({
          startDate,
          endDate,
          aggregationType,
          energyData: temp,
          isLoading: false
        });
      });
    }else if(aggregationType === EnergyFrequencyTypes.SevenDays || aggregationType === EnergyFrequencyTypes.Month){
      startingDate = startDate
      .clone()
      .startOf("day")
      .set("hour",0)
      .set("second", 0)
      .set("minute",0)
      .format('YYYY-MM-DDTHH:mm:ss')

      
    endingDate = endDate
      .clone()
      .startOf("day")
      .set("hour",0)
      .set("second", 0)
      .set("minute",0)
      .format('YYYY-MM-DDTHH:mm:ss')
     
      EnergyApi.getEnergyData(
        sites,
        startingDate,
        endingDate,
        range.Value.apiValue
      ).then(() => {
        const edata = EnergyManagement.getEnergyData();
        const temp = JSON.parse(JSON.stringify(edata));
        const data = GetCompleteData(startDate, endDate, temp, range);
        data.sort((a,b)=> b.energydata.length -a.energydata.length)
        data.forEach(item => {
          let total = 0;
          let count = 0;
          item.energydata.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
          const matchingRecord = edata.find(e => e.siteId === item.siteId);
          item.energydata.forEach(entry => {
            if(!entry.isinterpolate && matchingRecord.energydata.some(energy => energy.date === entry.date)){
            total += entry.kwh;
            count += 1;
          }
          });
          
          const foundItem = this.props.sitesWithPermission.find(
            site => site.id === item.siteId
          );
          item.color = foundItem.color;
          item.siteName = foundItem.name;
          item.avg = +(total / count).toFixed(2);
          item.total = +total.toFixed(2);
        });
        this.setState({
          startDate,
          endDate,
          aggregationType,
          energyData: temp,
          isLoading: false
        });
      });

    }
    else if(aggregationType ===  EnergyFrequencyTypes.ThreeMonths ||aggregationType === EnergyFrequencyTypes.Year){
      startingDate = startDate
      .clone()
      .startOf("month")
      .format('YYYY-MM-DDTHH:mm:ss')
     
    endingDate = endDate
      .clone()
      .startOf("month")
      .format('YYYY-MM-DDTHH:mm:ss')
      
      EnergyApi.getEnergyData(
        sites,
        startingDate,
        endingDate,
        range.Value.apiValue
      ).then(() => {
        const edata = EnergyManagement.getEnergyData();
        const temp = JSON.parse(JSON.stringify(edata));
        const data = GetCompleteData(startDate, endDate, temp, range);
        data.sort((a,b)=> b.energydata.length -a.energydata.length);
        data.forEach(item => {
          let total = 0;
          let count = 0;
          item.energydata.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
          const matchingRecord = edata.find(e => e.siteId === item.siteId);
          item.energydata.forEach(entry => {
            if(!entry.isinterpolate && matchingRecord.energydata.some(energy => energy.date === entry.date)){
            total += entry.kwh;
            count += 1;
          }
          });

          const foundItem = this.props.sitesWithPermission.find(
            site => site.id === item.siteId
          );
          item.color = foundItem.color;
          item.siteName = foundItem.name;
          item.avg = +(total / count).toFixed(2);
          item.total = +total.toFixed(2);
        });
        this.setState({
          startDate,
          endDate,
          aggregationType,
          energyData: temp,
          isLoading: false
        });
      });


    }
    else {
      startingDate = startDate;
      endingDate = endDate;
      EnergyApi.getEnergyData(
        sites,
        startingDate,
        endingDate,
        range.Value.apiValue
      ).then(() => {
        const edata = EnergyManagement.getEnergyData();
        const temp = JSON.parse(JSON.stringify(edata));
        const data = GetCompleteData(startDate, endDate, temp, range);
        data.sort((a,b)=> b.energydata.length -a.energydata.length);
        data.forEach(item => {
          let total = 0;
          let count = 0;
          const matchingRecord = edata.find(e => e.siteId === item.siteId);
          item.energydata.forEach(entry => {
            if(!entry.isinterpolate && matchingRecord.energydata.some(energy => energy.date === entry.date))
            total += entry.kwh;
            count += 1;
          });
          const foundItem = this.props.sitesWithPermission.find(
            site => site.id === item.siteId
          );
          item.color = foundItem.color;
          item.siteName = foundItem.name;
          item.avg = +(total / count).toFixed(2);
          item.total = +total.toFixed(2);
        });
        this.setState({
          startDate,
          endDate,
          aggregationType,
          energyData: temp,
          isLoading: false
        });
      });
    }

  }

  RangeOnClick = input => {
    if (
      input === "front" &&
      this.state.aggregationType === EnergyFrequencyTypes.Day
    ) {
      const startDate = this.state.endDate.add("1", "days");
      this.getEnergyDataForSites(
        startDate,
        startDate,
        this.state.aggregationType
      );
    } else if (
      input === "back" &&
      this.state.aggregationType === EnergyFrequencyTypes.Day
    ) {
      const endDate = this.state.startDate.subtract("1", "days");
      this.getEnergyDataForSites(endDate, endDate, this.state.aggregationType);
    } else if (input === "front") {
      const startDate = this.state.endDate.add("1", "days");
      const endDate = EnergyAggregationHelper.getEndDateforAggregation(
        this.state.aggregationType,
        startDate
      );
      this.getEnergyDataForSites(
        startDate,
        endDate,
        this.state.aggregationType
      );
    } else if (input === "back") {
      const endDate = this.state.startDate.subtract("1", "days");
      const startDate = EnergyAggregationHelper.getStartDateforAggregation(
        this.state.aggregationType,
        endDate
      );
      this.getEnergyDataForSites(
        startDate,
        endDate,
        this.state.aggregationType
      );
    }
  };

  render() {
    let energygraph;
    if (this.state.isFirstLoad) {
      energygraph = (
        <div className="w-100 h-50 flexbox-centered-items">
          <div className="monitor-no-preferences">
            <p>
              {i18next.t(Labels.DEFAULT_MONITOR_MESSAGE_PART_ONE)}
              <br />
              {i18next.t(Labels.DEFAULT_MONITOR_MESSAGE_PART_TWO)}
            </p>
          </div>
        </div>
      );
    } else if (this.state.isLoading) {
      energygraph = (
        <div className="eua-LoadingContainer-Screen flexbox-centered-items content">
          <GifImageContainer alt="Loading" src="/images/loading.gif" />
        </div>
      );
    } else {
      energygraph = (
        <EnergyGraph
          rangeType={this.state.aggregationType}
          energyData={this.state.energyData}
          start={this.state.startDate}
          end={this.state.endDate}
          handleRangeClick={this.RangeOnClick}
        />
      );
    }
    return (
      <>
        <AggregationFilter
          onGenerate={this.onGenerateEnergyGraph}
          sitesWithPermission={this.props.sitesWithPermission}
          handleSiteCheck={this.onSiteCheckboxCheck}
          isButtonEnabled={this.state.selected_Sites.length > 0}
        />
        <hr className="hr-line" />
        {energygraph}
      </>
    );
  }
}

Consumption.propTypes = {
  sitesWithPermission: PropTypes.instanceOf(Array)
};

Consumption.defaultProps = {
  sitesWithPermission: []
};

export default Consumption;
