import React from "react";

const DoubleChevronExpand = () => {
  const svgStyle = {
    fill: "#3c3c41"
  };
  return (
    <svg width="22px" height="22px" viewBox="0 0 24 24">
      <path
        style={svgStyle}
        d="M5.1,2,3,3.59,10.43,12,3,20.41,5.1,22l8.83-10Zm7.07,0-2.1,1.59L17.5,12l-7.43,8.42L12.17,22,21,12Z"
      />
    </svg>
  );
};

export default DoubleChevronExpand;
