import React from "react";
import PropTypes from "prop-types";
import LeftArrow from "../Icons/LeftArrow";

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    // eslint-disable-next-line no-unused-vars
    onMove,
    // eslint-disable-next-line no-unused-vars
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <div
      className="site-consumption-arrow-left"
      role="presentation"
      onClick={() => onClick()}
      onKeyPress={() => onClick()}
    >
      <LeftArrow />
    </div>
  );
};

CustomLeftArrow.propTypes = {
  onClick: PropTypes.func
};

CustomLeftArrow.defaultProps = {
  onClick: null
};

export default CustomLeftArrow;
