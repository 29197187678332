import axios from "axios";
import Authenticator from "./Auth";
import history from "./history";
import * as AppConstants from "../constants/AppConstants";
import * as LanguageConstant from "../constants/LanguageConstants"

class Service {
  constructor() {
    const service = axios.create({
      baseURL: AppConstants.BASE_URL
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess = response => response;

  handleError = error => {
    if (typeof error.response !== "undefined") {
      if (error.response.status === 403) {
        this.redirectTo("/AccessDenied");
      } else if(error.response.status === 400){
        return Promise.reject(error);
      }else {
        this.redirectTo("/NotFound");
      }
    }
    return Promise.reject(error);
  };

  redirectTo = path => {
    history.push(path);
  };

  get(path) {
    return this.service
      .get(path, {
        headers: {
          Authorization: `Bearer ${Authenticator.bearerToken}`,
          "Language": localStorage.getItem(LanguageConstant.CULTURE)
        }
      })
      .then(json => json);
  }

  patch(path, payload, callback) {
    return this.service
      .request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload
      })
      .then(response => callback(response.status, response.data));
  }

  post(path, payload) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${Authenticator.bearerToken}`,
          "Language": localStorage.getItem(LanguageConstant.CULTURE)
        },
        data: payload
      })
      .then(response => response);
  }

  put(path, payload) {
    return this.service
      .request({
        method: "PUT",
        url: path,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${Authenticator.bearerToken}`,
          "Content-Type": "application/json",
          "Language": localStorage.getItem(LanguageConstant.CULTURE)
        },
        data: JSON.stringify(payload)
      })
      .then(response => response);
  }

  delete(path, payload) {
    return this.service
      .delete(path, {
        headers: {
          Authorization: `Bearer ${Authenticator.bearerToken}`,
          "Language": localStorage.getItem(LanguageConstant.CULTURE)
        },
        data: payload
      })
      .then(json => json);
  }
}

export default new Service();
