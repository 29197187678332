/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";
import { MonitorTypesList } from "../constants/MonitorTypeConstants";
import Consumption from "../Consumption/Consumption";
// import Usage from "../Usage/Usage";
// import Alerts from "../Alerts/Alerts";
import NotFound from "../components/NotFound";
import * as Labels from "../constants/Labels";

const MonitorDashboard = props => {
  // const navlinks = MonitorTypesList.map(item => {
  //   const itemWithoutSpaces = item.replace(/ +/g, "");
  //   return (
  //     <li key={item} className="pr-4 mr-2 Monitor-tab nav-item">
  //       <NavLink
  //         activeClassName="eua-monitor-selected-navlink-header"
  //         className="m-0 pt-2 pb-2 nav-link" 
  //         to={`/Monitor/${itemWithoutSpaces}`}
  //       >
  //         {item}
  //       </NavLink>
  //     </li>
  //   );
  // });
  return (
    <>
      {/* <div>  <nav className="navbar navbar-expand-sm navbar-light eua-monitor-menu" style={{ outline: "none" }}>
          <button className="navbar-toggler" style={{ border: "none" ,outline: "none" }}  type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
            <span  style={{ paddingLeft: "25px" }}>Energy Usage</span>
          </button> 
          <div className="collapse navbar-collapse" style={{ border: "none" ,outline: "none"}}id="navbarSupportedContent">
          <ul className="row eua-monitor-menu pr-5 pl-4 ml-0 navbar-nav mr-auto">{navlinks}</ul>
          </div>
        </nav></div> */}
      {/* <header className="eua-monitor-header row w-100 row-margin p-0 m-0">
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <ul className="row eua-monitor-menu pr-5 pl-4 ml-0">{navlinks}</ul></nav> }
        
      </header> */}
      <span  style={{ margin: "25px",padding :"8px",borderBottom: "5px solid #ff365e",fontSize: "16px",cursor:"pointer" }}>{i18next.t(Labels.ENERGY_USAGE)}</span>
      { props.selectedApp && <Redirect to={`/Monitor/${props.selectedApp.replace(/ +/g, "")}`} />}
      <Switch>
        {/* <Route exact path="/Monitor/Alerts" component={Alerts} /> */}
        
        <Route
          exact
          path={["/Monitor/Energyusage","/Monitor"]}
          render={() => (
            <Consumption sitesWithPermission={props.SitesWithPermission} />
          )}
        />
        {/* <Route exact path="/Monitor/Fieldusage" component={Usage} /> */}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

MonitorDashboard.propTypes = {
  SitesWithPermission: PropTypes.instanceOf(Array),
  selectedApp: PropTypes.string
};

MonitorDashboard.defaultProps = {
  SitesWithPermission: [],
  selectedApp: ""
};

export default MonitorDashboard;
