import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const Alert = props => (
  <Modal show={props.show} onHide={props.close}>
    <Modal.Header>
      <Modal.Title>Error</Modal.Title>
    </Modal.Header>

    <Modal.Body>{props.message}</Modal.Body>

    <Modal.Footer>
      <Button onClick={props.close}>OK</Button>
    </Modal.Footer>
  </Modal>
);

Alert.propTypes = {
  message: PropTypes.string,
  close: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
Alert.defaultProps = {
  message: '',
  show: false,
};
export default Alert;
