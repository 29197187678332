import React from "react";
import PropTypes from "prop-types";
import RightArrow from "../Icons/RightArrow";

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    // eslint-disable-next-line no-unused-vars
    onMove,
    // eslint-disable-next-line no-unused-vars
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <div
      className="site-consumption-arrow-right"
      role="presentation"
      onClick={() => onClick()}
      onKeyPress={() => onClick()}
    >
      <RightArrow />
    </div>
  );
};

CustomRightArrow.propTypes = {
  onClick: PropTypes.func
};

CustomRightArrow.defaultProps = {
  onClick: null
};

export default CustomRightArrow;
