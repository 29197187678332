import i18next from "i18next";
import Notification from "./Notification";
import * as Labels from "../constants/Labels";

function tryDisplayError(err, displayInterfaceMethod, errorDisplayCallback) {
  let error = "";
  try {
    if (err.response === null && err.message === null) {
      error = i18next.t(Labels.DEFAULT_ERROR_MESSAGE);
    } else {
      //  const parsedError = JSON.parse(err.response.data); Error msg was not in json format, so we are reading it as object
      const parsedError =
        Object.prototype.hasOwnProperty.call(err, "response") &&
        err.response !== undefined
          ? err.response.data.message
          : err.message;
      if (typeof parsedError === "object" && parsedError.message !== "") {
        error = parsedError.message;
      } else if (typeof parsedError === "string") {
        error = parsedError;
      } else {
        error = i18next.t(Labels.DEFAULT_ERROR_MESSAGE);
      }
    }
    displayInterfaceMethod(error, errorDisplayCallback);
  } catch (ex) {
    /* eslint-disable no-console */
    displayInterfaceMethod(
      "Unable to parse error message",
      errorDisplayCallback
    );
    console.log("Unable to parse error message");
    if (error.length) {
      displayInterfaceMethod(err.response, errorDisplayCallback);
      console.log(err.response);
    }
    /* eslint-enable no-console */
  }
}

function handleError(err) {
  tryDisplayError(err, Notification.error);
}

function handleErrorAsSticky(err, errorDisplayCallback) {
  tryDisplayError(err, Notification.stickyError, errorDisplayCallback);
}

export default {
  handleError,
  handleErrorAsSticky
};
