/* eslint-disable react/no-unescaped-entities */
import React from "react";
import i18next from "i18next";
import TouContent from "./TOUContent";
import * as Labels from "../constants/Labels";
// import LanguageSelector from "./Language/LanguageSelector";

const Termsofuse = () => {
  return (
    <div className="tou-container pt-4">
      <p className="tou-content-mega-header">
        {i18next.t(Labels.IAS_LICENSE_AGREEMENT)}
      </p>
      <TouContent />
    </div>
  );
};

export default Termsofuse;
