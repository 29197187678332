import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { Modal, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Datetime from "react-datetime";
import "../css/Schedule.css";
import {
  MasterFrequency,
  FrequencyTypes
} from "../constants/FrequencyConstants";
import {
  MasterScheduleTypes,
  ScheduleTypes,
  TwilightOffset,
  DefaultSunriseOffset,
  DefaultSunsetOffset,
  ScheduleOffSetSign,
  DefaultSunriseOffsetTimeSign,
  DefaultSunsetOffsetTimeSign
} from "../constants/ScheduleTypeConstants";
import MasterSchedule from "../datamodels/MasterSchedule";
import SchedulingApi from "./MasterSchedulingApi";
import NotificationActions from "../helpers/Notification";
import GifImageContainer from "../components/GifImageContainer";
import RecurringSchedule from "../components/RecurringSchedule";
import CalendarIcon from "../Icons/calendar";
import CustomDropdown from "../components/CustomDropdown";
import SchedulingUtilities from "../helpers/SchedulingUtilities";
import "../css/calendar.css";
import * as Labels from "../constants/Labels";
import LanguageSelector from "../Language/LanguageSelector";
import { daysOfWeekKVP } from "../constants/DayOfWeekConstants";
import MasterScheduleManagement from "./MasterScheduleManagement";
import UserManagement from "../UserManagement";
import EditedScheduleManagement from "../FieldSchedule/EditedScheduleManagement";

const DEFAULT_TIME = "-";
function initializeEvent() {
  return {
    startTime: DEFAULT_TIME,
    endTime: DEFAULT_TIME,
    fromDate: new Date(),
    endDate: new Date(new Date().getFullYear(), 11, 31),
    frequencyType: FrequencyTypes.Never,
    scheduleType: ScheduleTypes.FixedSchedule,
    dayOfWeek: SchedulingUtilities.getDayofWeek(new Date()),
    daysOfWeek: [],
    weekOfMonth: SchedulingUtilities.getWeekOfMonth(new Date()),
    isLoading: false,
    isTwilight: false,
    sunRiseFixedTime: SchedulingUtilities.concatDateTime(new Date(), "6:0"),
    sunSetFixedTime: SchedulingUtilities.concatDateTime(new Date(), "22:00"),
    end: new Date(new Date().getFullYear(), 11, 31),
    disableDateTime: false,
    parentFrequencyType: FrequencyTypes.Never
  };
}

function getOffsetDetails(sunriseOffset, sunsetOffset) {
  const offSetTime = {
    SunRiseOffsetHours: sunriseOffset.offsetHours,
    SunRiseOffsetMins: sunriseOffset.offsetMinutes,
    SunRiseOffsetTimeSign: sunriseOffset.offsetTimeSign.Key
      ? sunriseOffset.offsetTimeSign.Key
      : sunriseOffset.offsetTimeSign,
    SunSetOffsetHours: sunsetOffset.offsetHours,
    SunSetOffsetMins: sunsetOffset.offsetMinutes,
    SunSetOffsetTimeSign: sunsetOffset.offsetTimeSign.Key
      ? sunsetOffset.offsetTimeSign.Key
      : sunsetOffset.offsetTimeSign
  };
  return offSetTime;
}

function getDayofWeek(event) {
  const daysOfWeek = [];
  const schedules = MasterScheduleManagement.getMasterSchedule();
  schedules.forEach(schedule => {
    if (
      schedule.parentSchedulingFrequency !== 0 &&
      schedule.parentSchedulingFrequency !== 1
    ) {
      const eventScheduleId =
        event.parentScheduleId === null
          ? event.scheduleId
          : event.parentScheduleId;
      const scheduleIdToCompare =
        schedule.parentScheduleId === null
          ? schedule.scheduleId
          : schedule.parentScheduleId;
      if (eventScheduleId === scheduleIdToCompare) {
        if (!daysOfWeek.includes(schedule.dayOfWeek)) {
          daysOfWeek.push(schedule.dayOfWeek);
        }
      }
    }
  });
  return daysOfWeek;
}

function setupEditEvent(event, isSeries) {
  return {
    ...event,
    dayOfWeek: SchedulingUtilities.getDayofWeek(
      event.schedulingFrequency === FrequencyTypes.Never
        ? event.start
        : new Date(event.scheduleStartDate)
    ),
    daysOfWeek:
      event.parentSchedulingFrequency === 0 ||
        event.parentSchedulingFrequency === 1
        ? []
        : getDayofWeek(event),
    fromDate:
      isSeries ? new Date(event.scheduleStartDate) : new Date(event.recurringScheduleStartDate),
    endDate:
      isSeries && event.parentSchedulingFrequency !== 0 ? new Date(event.scheduleEndDate) : new Date(new Date(new Date().getFullYear(), 11, 31)),
    startTime: isSeries ? new Date(event.scheduleStartDate) : new Date(SchedulingUtilities.concatDateTime(
      event.recurringScheduleStartDate,
      event.startTime)
    ),
    endTime: isSeries ? new Date(event.scheduleEndDate) : new Date(SchedulingUtilities.concatDateTime(
      event.recurringScheduleEndDate,
      event.endTime)
    ),
    sunRiseFixedTime: new Date(SchedulingUtilities.concatDateTime(
      isSeries ? event.scheduleEndDate : event.recurringScheduleEndDate,
      isSeries ? event.parentSunRiseFixedTime : event.sunRiseFixedTime
    )),
    sunSetFixedTime: new Date(SchedulingUtilities.concatDateTime(
      isSeries ? event.scheduleStartDate : event.recurringScheduleStartDate,
      isSeries ? event.parentSunSetFixedTime : event.sunSetFixedTime
    )),
    frequencyType: MasterFrequency.filter(
      f => f.Key === event.schedulingFrequency
    )[0].Value,
    parentFrequencyType: MasterFrequency.filter(
      f => f.Key === event.parentSchedulingFrequency
    )[0].Value,
    end: new Date(event.scheduleEndDate)
  };
}

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["validFromDateSelection"] }] */
class AddMasterScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    //  const event = initializeEvent();
    if (this.props.isCreate) {
      const sunriseOffset = TwilightOffset[DefaultSunriseOffset].Value;
      sunriseOffset.offsetTimeSign = DefaultSunriseOffsetTimeSign;
      const sunsetOffset = TwilightOffset[DefaultSunsetOffset].Value;
      sunsetOffset.offsetTimeSign = DefaultSunsetOffsetTimeSign;
      this.state = {
        event: initializeEvent(),
        isLoading: false,
        sunriseOffset,
        sunsetOffset
      };
    }
    else {
      let sunriseOffset;
      let sunsetOffset;
      if (props.schedule.parentMasterScheduleType === 1 && this.props.isSeries) {
        sunriseOffset = TwilightOffset.filter(
          t =>
            t.Value.offsetHours ===
            props.schedule.parentOffSetTime.sunRiseOffsetHours &&
            t.Value.offsetMinutes ===
            props.schedule.parentOffSetTime.sunRiseOffsetMins
        )[0].Value;
        sunriseOffset = {
          ...sunriseOffset,
          offsetTimeSign: ScheduleOffSetSign[props.schedule.parentOffSetTime.sunRiseOffsetTimeSign].Key           
        };

        sunsetOffset = TwilightOffset.filter(
          t =>
            t.Value.offsetHours ===
            props.schedule.parentOffSetTime.sunSetOffsetHours &&
            t.Value.offsetMinutes ===
            props.schedule.parentOffSetTime.sunSetOffsetMins
        )[0].Value;
        sunsetOffset = {
          ...sunsetOffset,
          offsetTimeSign: ScheduleOffSetSign[props.schedule.parentOffSetTime.sunSetOffsetTimeSign].Key
        };
      }
      if (!this.props.isSeries && props.schedule.isTwilight) {
        sunriseOffset = TwilightOffset.filter(
          t =>
            t.Value.offsetHours ===
            props.schedule.offSetTime.sunRiseOffsetHours &&
            t.Value.offsetMinutes ===
            props.schedule.offSetTime.sunRiseOffsetMins
        )[0].Value;
        sunriseOffset = {
          ...sunriseOffset,
          offsetTimeSign: ScheduleOffSetSign[props.schedule.offSetTime.sunRiseOffsetTimeSign].Key
        };

        sunsetOffset = TwilightOffset.filter(
          t =>
            t.Value.offsetHours ===
            props.schedule.offSetTime.sunSetOffsetHours &&
            t.Value.offsetMinutes ===
            props.schedule.offSetTime.sunSetOffsetMins
        )[0].Value;
        sunsetOffset = {
          ...sunsetOffset,
          offsetTimeSign: ScheduleOffSetSign[props.schedule.offSetTime.sunSetOffsetTimeSign].Key
        };
      }
      
      this.state = {
        isLoading: false,
        event: setupEditEvent(props.schedule, props.isSeries),
        sunRiseOffsetTimeSignIndex: (!props.isSeries && props.schedule.isTwilight) || (props.isSeries && props.schedule.parentMasterScheduleType === 1) ?
          ScheduleOffSetSign.findIndex(
            x => x.Key === sunriseOffset.offsetTimeSign
          ) : 0,
        sunriseOffsetIndex: (!props.isSeries && props.schedule.isTwilight) || (props.isSeries && props.schedule.parentMasterScheduleType === 1)
          ? TwilightOffset.findIndex(
            x =>
              x.Value.offsetHours === sunriseOffset.offsetHours &&
              x.Value.offsetMinutes === sunriseOffset.offsetMinutes
          )
          : DefaultSunsetOffset,

        sunsetOffsetTimeSignIndex: (!props.isSeries && props.schedule.isTwilight) || (props.isSeries && props.schedule.parentMasterScheduleType === 1)
          ? ScheduleOffSetSign.findIndex(
            x => x.Key === sunsetOffset.offsetTimeSign
          )
          : 0,
        sunsetOffsetIndex: (!props.isSeries && props.schedule.isTwilight) || (props.isSeries && props.schedule.parentMasterScheduleType === 1)
          ? TwilightOffset.findIndex(
            x =>
              x.Value.offsetHours === sunsetOffset.offsetHours &&
              x.Value.offsetMinutes === sunsetOffset.offsetMinutes
          )
          : DefaultSunsetOffset,
        sunriseOffset: (!props.isSeries && props.schedule.isTwilight) || (props.isSeries && props.schedule.parentMasterScheduleType === 1)
          ? sunriseOffset
          : TwilightOffset[DefaultSunriseOffset].Value,
        sunsetOffset: (!props.isSeries && props.schedule.isTwilight) || (props.isSeries && props.schedule.parentMasterScheduleType === 1)
          ? sunsetOffset
          : TwilightOffset[DefaultSunsetOffset].Value
      };
    }
    this.handlefromDateChange = this.handlefromDateChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.validFromDateSelection = this.validFromDateSelection.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleRecurringEndDtChange = this.handleRecurringEndDtChange.bind(
      this
    );
    this.handleFrequencyTypeChange = this.handleFrequencyTypeChange.bind(this);
    this.handleTwilightToggleChange = this.handleTwilightToggleChange.bind(
      this
    );
    this.handleSunsetFixedTimeChange = this.handleSunsetFixedTimeChange.bind(
      this
    );
    this.handleSunriseFixedTimeChange = this.handleSunriseFixedTimeChange.bind(
      this
    );
    this.handleSunriseOffsetChange = this.handleSunriseOffsetChange.bind(this);
    this.handleDaysOfWeekChange = this.handleDaysOfWeekChange.bind(this);
  }

  handlefromDateChange(e) {
    const fromDate = e.toDate();
    // const endDate = this.state.event.parentFrequencyType === FrequencyTypes.Never ? e.toDate() : this.state.event.endDate;
    if (moment(fromDate).isBefore(moment().format(), "day")) return;
    const oldScheduleStartDate = this.state.event.fromDate;
    if (
      moment(fromDate)
        .startOf("day")
        .isBefore(moment().startOf("day"))
    )
      return;
    this.setState({
      event: {
        ...this.state.event,
        fromDate,
        // endDate,
        dayOfWeek: SchedulingUtilities.getDayofWeek(fromDate),
        weekOfMonth: SchedulingUtilities.getWeekOfMonth(fromDate),
        oldScheduleStartDate
      }
    });
  }

  handleStartTimeChange(time) {
    const selectedDateTime = SchedulingUtilities.concatDateTime(
      this.props.isCreate || this.props.isSeries || this.state.event.parentFrequencyType === "Never"
        ? this.state.event.fromDate
        : this.state.event.recurringScheduleStartDate,
      time);
    if (moment(selectedDateTime).isBefore(moment().format())) return;

    this.setState({
      event: {
        ...this.state.event,
        startTime: selectedDateTime
      }
    });
  }

  handleEndTimeChange(time) {
    const selectedDateTime = SchedulingUtilities.concatDateTime(
      this.props.isCreate || this.props.isSeries || this.state.event.parentFrequencyType === "Never"
        ? this.state.event.endDate
        : this.state.event.recurringScheduleEndDate,
      time);
    if (moment(selectedDateTime).isSameOrBefore(this.state.event.startTime))
      return;
    this.setState({
      event: {
        ...this.state.event,
        endTime: selectedDateTime
      }
    });
  }

  handleRecurringEndDtChange(endDate) {
    this.setState({
      event: {
        ...this.state.event,
        endDate
      }
    });
  }

  handleFrequencyTypeChange(frequencyType) {
    this.setState({
      event: {
        ...this.state.event,
        frequencyType,
        parentFrequencyType: frequencyType
      }
    });
  }

  handleTwilightToggleChange(evt) {
    const checked = evt.target.checked === true ? 1 : 0;
    const isParentAlreadyTwilight = this.props.schedule.parentMasterScheduleType === 1;
    if (this.props.isCreate) {
      this.setState({
        event: {
          ...this.state.event,
          isTwilight: evt.target.checked,
          parentMasterScheduleType: evt.target.checked === true ? 1 : 0
        }
      });
    } else {
      const isTwilightAlready = this.props.isSeries ? isParentAlreadyTwilight : this.props.schedule.isTwilight;
      if (!isTwilightAlready) {
        this.setState({
          event: {
            ...this.state.event,
            isTwilight: evt.target.checked,
            parentMasterScheduleType: this.props.isSeries ? checked : this.props.schedule.parentMasterScheduleType,
            sunRiseFixedTime: evt.target.checked
              ? SchedulingUtilities.concatDateTime(new Date(), "6:0")
              : null,
            sunSetFixedTime: evt.target.checked
              ? SchedulingUtilities.concatDateTime(new Date(), "22:00")
              : null
          },
          sunriseOffsetIndex: evt.target.checked
            ? TwilightOffset.findIndex(x => x.Key === "30 minutes")
            : 0,
          sunsetOffsetIndex: evt.target.checked
            ? TwilightOffset.findIndex(x => x.Key === "30 minutes")
            : 0
        });
      } else {
        this.setState({
          event: {
            ...this.state.event,
            isTwilight: evt.target.checked,
            parentMasterScheduleType: this.props.isSeries ? checked : this.props.schedule.parentMasterScheduleType,
            startTime: evt.target.checked ? null : DEFAULT_TIME,
            endTime: evt.target.checked ? null : DEFAULT_TIME
          }
        });
      }
    }
  }

  handleSunriseFixedTimeChange(time) {
    const selectedDateTime = SchedulingUtilities.concatDateTime(
      this.state.event.fromDate,
      time
    );
    this.setState({
      event: {
        ...this.state.event,
        sunRiseFixedTime: selectedDateTime
      }
    });
  }

  handleSunsetFixedTimeChange(time) {
    const selectedDateTime = SchedulingUtilities.concatDateTime(
      this.state.event.fromDate,
      time
    );
    this.setState({
      event: {
        ...this.state.event,
        sunSetFixedTime: selectedDateTime
      }
    });
  }

  handleSunriseOffsetChange(evt) {
    this.setState({
      sunriseOffset: TwilightOffset[evt.target.value].Value
    });
  }

  handleDaysOfWeekChange(day, checked) {
    const daysOfWeekList = [...this.state.event.daysOfWeek];
    let elementIndex = null;
    if (daysOfWeekList.includes(day) && checked === false) {
      elementIndex = daysOfWeekList.indexOf(day);
      daysOfWeekList.splice(elementIndex, 1);
    } else daysOfWeekList.push(day);

    this.setState({
      event: {
        ...this.state.event,
        daysOfWeek: daysOfWeekList
      }
    });
  }

  onSave() {
    if (
      !this.state.event.isTwilight &&
      (this.state.event.startTime === DEFAULT_TIME ||
        this.state.event.endTime === DEFAULT_TIME)
    ) {
      i18next.t(Labels.START_END_TIME_REQUIRED)
      return;
    }
    this.setState({ isLoading: false });
    const childScheduleType = this.state.event.isTwilight
      ? ScheduleTypes.TwilightSchedule
      : ScheduleTypes.FixedSchedule;
    const scheduleType = this.props.isSeries ? MasterScheduleTypes[this.state.event.parentMasterScheduleType].Value : childScheduleType;
    const daysArray = [];
    this.state.event.daysOfWeek.forEach(day =>
      daysArray.push(daysOfWeekKVP.find(d => d.Key === day).Value)
    );
    const endDate = this.props.isSeries === false && this.props.schedule.scheduleId ? new Date(this.state.event.recurringScheduleEndDate) : new Date(this.state.event.endDate);
    const startDate = this.props.isSeries === false && this.props.schedule.scheduleId ? new Date(this.state.event.recurringScheduleStartDate) : new Date(this.state.event.fromDate);
    let oldMasterScheduleType;
    if (!this.props.isCreate && this.props.isSeries) {
      oldMasterScheduleType = this.props.schedule.parentMasterScheduleType
    }
    else if (!this.props.isCreate && !this.props.isSeries) {
      oldMasterScheduleType = this.props.schedule.masterScheduleType
    }
    else {
      oldMasterScheduleType = null
    }

    const newSchedule = new MasterSchedule({
      scheduleId: this.props.schedule.scheduleId,
      scheduleEndDate: this.state.event.parentFrequencyType === "Never" ? SchedulingUtilities.getOnlyDate(this.state.event.fromDate) : SchedulingUtilities.getOnlyDate(endDate),
      scheduleStartDate: this.state.event.parentFrequencyType === "Never" ? SchedulingUtilities.getOnlyDate(this.state.event.fromDate) : SchedulingUtilities.getOnlyDate(startDate),
      endTime: (!this.props.isSeries && this.state.event.isTwilight) || (this.props.isSeries && this.state.event.parentMasterScheduleType === 1)
        ? undefined
        : SchedulingUtilities.getformattedTime(this.state.event.endTime),
      startTime: (!this.props.isSeries && this.state.event.isTwilight) || (this.props.isSeries && this.state.event.parentMasterScheduleType === 1)
        ? undefined
        : SchedulingUtilities.getformattedTime(this.state.event.startTime),
      id: this.state.event.id,
      description: this.state.event.description,
      schedulingFrequency: MasterFrequency.filter(
        f => f.Value === this.state.event.parentFrequencyType
      )[0].Key,
      siteId: this.props.siteId,
      masterScheduleType: MasterScheduleTypes.filter(
        f => f.Value === scheduleType
      )[0].Key,
      OldMasterScheduleType: oldMasterScheduleType,
      dayOfWeek: this.state.event.parentFrequencyType === "Never" ||
        this.state.event.parentFrequencyType === "Daily"
        ? []
        : daysArray,
      EditMasterType: this.props.schedule.parentSchedulingFrequency === 0 || this.props.isSeries
        ? 0
        : 1,
      sunRiseFixedTime: (!this.props.isSeries && this.state.event.isTwilight) || (this.props.isSeries && this.state.event.parentMasterScheduleType === 1)
        ? SchedulingUtilities.getformattedTime(
          this.state.event.sunRiseFixedTime
        )
        : undefined,
      sunSetFixedTime: (!this.props.isSeries && this.state.event.isTwilight) || (this.props.isSeries && this.state.event.parentMasterScheduleType === 1)
        ? SchedulingUtilities.getformattedTime(this.state.event.sunSetFixedTime)
        : undefined,
      offSetTime: (!this.props.isSeries && this.state.event.isTwilight) || (this.props.isSeries && this.state.event.parentMasterScheduleType === 1)
        ? getOffsetDetails(this.state.sunriseOffset, this.state.sunsetOffset)
        : undefined,
      oldScheduleStartDate: this.state.event.oldScheduleStartDate,
      isEdit: this.state.event.id !== undefined,
      userName: UserManagement.getUserName(),
    });
    if (newSchedule.id) {
      this.onUpdate(newSchedule);
      return;
    }
    SchedulingApi.createMasterSchedule(newSchedule)
      .then(response => {
        this.setState({ isLoading: false });
        if (response && response.status !== 202 && response.status !== 200) {
          /* eslint-disable no-console */
          console.log(response.message);
          this.props.close();
          /* eslint-enable no-console */
          //  NotificationActions.error("Master schedule creation failed");
        } else {
          this.props.onSave();
          NotificationActions.success(
            i18next.t(Labels.MASTER_SCHEDULE_SAVE_SUCCESS)
          );
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        /* eslint-disable no-console */
        console.log(error.message);
        /* eslint-enable no-console */
        this.props.close();
      });
  }

  onUpdate(existingSchedule) {
    NotificationActions.success(i18next.t(Labels.EDIT_SCHEDULE_ACCEPT_MESSAGE));
    this.props.close();
    EditedScheduleManagement.addEditedScheduleList(existingSchedule.id);
    SchedulingApi.editMasterSchedule(existingSchedule)
      .then(response => {
        this.setState({ isLoading: false });
        if (response.status === 202) {
          EditedScheduleManagement.removeEditedScheduleList(response.data);
          this.props.onSave();
          NotificationActions.success(
            i18next.t(Labels.MASTER_SCHEDULE_UPDATE_SUCCESS)
          );
        } 
        else {
          EditedScheduleManagement.removeEditedScheduleList(response.data);
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        /* eslint-disable no-console */
        console.log(error.message);
        /* eslint-enable no-console */
      });
  }

  onCancel() {
    const event = initializeEvent();
    this.setState({ event });
    this.props.close();
  }

  suriseOffsetTranslation = sunriseOffset => {
    const reg = /([a-zA-Z])\w+/g;
    const result = sunriseOffset.match(reg);
    let offset = sunriseOffset;
    result.map((item) => {
      offset = offset.replace(item, i18next.t(item.toUpperCase()));
      return offset;
    });
    return offset;
  }

  validFromDateSelection(currentDate) {
    const yesterday = Datetime.moment().subtract(1, "day");
    return currentDate.isAfter(yesterday);
  }

  render() {
    const language = LanguageSelector.SelectLanguage(this.props.siteId);
    i18next.changeLanguage(language);
    if (this.state.isLoading) {
      return (
        <div className="eua-LoadingContainer-Screen flexbox-centered-items">
          <GifImageContainer alt="Loading" src="images/loading.gif" />
        </div>
      );
    }
    let headerTitle = i18next.t(Labels.CREATE_NEW_RULE);
    let footerButtons = (
      <div className="flexbox-column-end-item">
        <button
          type="button"
          className="btn eua-btn eua-cancel-btn"
          onClick={this.props.close}
        >
          {i18next.t(Labels.CANCEL_BUTTON)}
        </button>
        <button
          className="btn eua-btn eua-interact-btn"
          type="button"
          onClick={this.onSave}
        >
          {i18next.t(Labels.CREATE_RULE)}
        </button>
      </div>
    );
    if (!this.props.isCreate) {
      headerTitle = i18next.t(Labels.EDIT_RULE);
      footerButtons = (
        <div>
          <button
            type="button"
            className="btn eua-btn eua-cancel-btn"
            onClick={this.props.close}
          >
            {i18next.t(Labels.CANCEL_BUTTON)}
          </button>
          <button
            className="btn eua-btn eua-interact-btn"
            type="button"
            onClick={this.onSave}
          >
            {i18next.t(Labels.SAVE_BUTTON)}
          </button>
        </div>
      );
    }

    const sunriseOffsets = [];
    const scheduleOffSetSigns = [];

    TwilightOffset.forEach((item, index) => {
      sunriseOffsets.push(
        <option key={item.Key} value={index}>
          {this.suriseOffsetTranslation(item.Key)}
        </option>
      );
    });

    ScheduleOffSetSign.forEach((item, index) => {
      scheduleOffSetSigns.push(
        <option key={item.Key} value={index}>
          {item.Value}
        </option>
      );
    });

    let twilight = null;
    if ((this.props.isSeries && this.state.event.parentMasterScheduleType === 1) || ((!this.props.isSeries || this.props.isCreate) && this.state.event.isTwilight)) {
      twilight = (
        <section id="twilightsection">
          <p className="large-p-text"> {i18next.t(Labels.SUNSET_SETTING)}</p>
          <div className="twilight-setting-container">
            <div className="col-sm-6" style={{ paddingLeft: "0px" }}>
              <FormGroup controlId="formControlsFromSunsetTime">
                <ControlLabel>{i18next.t(Labels.POWER_ON)}</ControlLabel>
                <FormControl
                  componentClass="select"
                  defaultValue={
                    !this.props.isCreate
                      ? this.state.sunsetOffsetTimeSignIndex
                      : 1
                  }
                  style={{ marginBottom: "10px", width: "fit-content" }}
                  onChange={e =>
                    this.setState({
                      sunsetOffset: {
                        ...this.state.sunsetOffset,
                        offsetTimeSign: e.target.value
                      }
                    })
                  }
                >
                  {scheduleOffSetSigns}
                </FormControl>
                <span />
                <FormControl
                  componentClass="select"
                  defaultValue={
                    !this.props.isCreate
                      ? this.state.sunsetOffsetIndex
                      : DefaultSunsetOffset
                  }
                  onChange={e =>
                    this.setState({
                      sunsetOffset: {
                        ...this.state.sunsetOffset,
                        offsetHours:
                          TwilightOffset[e.target.value].Value.offsetHours,
                        offsetMinutes:
                          TwilightOffset[e.target.value].Value.offsetMinutes
                      }
                    })
                  }
                >
                  {sunriseOffsets}
                </FormControl>
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup controlId="formControlsTillSunsetTime">
                <ControlLabel>{i18next.t(Labels.POWER_OFF)}</ControlLabel>
                <CustomDropdown
                  onSelectChange={this.handleSunsetFixedTimeChange}
                  startTime={SchedulingUtilities.getOnlyDate(
                    this.state.event.fromDate
                  )}
                  selectedTime={SchedulingUtilities.getformattedTime(
                    this.state.event.sunSetFixedTime
                  )}
                />
              </FormGroup>
            </div>
          </div>

          <p className="large-p-text"> {i18next.t(Labels.SUNRISE_SETTING)}</p>

          <div className="twilight-setting-container">
            <div className="col-sm-6" id="formControlsSunriseTime">
              <FormGroup controlId="formControlsFromSunriseTime">
                <ControlLabel>{i18next.t(Labels.POWER_ON)}</ControlLabel>
                <CustomDropdown
                  onSelectChange={this.handleSunriseFixedTimeChange}
                  startTime={SchedulingUtilities.getOnlyDate(
                    this.state.event.fromDate
                  )}
                  selectedTime={SchedulingUtilities.getformattedTime(
                    this.state.event.sunRiseFixedTime
                  )}
                  key={this.state.event.fromDate}
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup controlId="formControlsTillSunriseTime">
                <ControlLabel>{i18next.t(Labels.POWER_OFF)}</ControlLabel>
                <FormControl
                  componentClass="select"
                  defaultValue={
                    !this.props.isCreate
                      ? this.state.sunRiseOffsetTimeSignIndex
                      : 0
                  }
                  style={{ marginBottom: "10px", width: "fit-content" }}
                  onChange={e =>
                    this.setState({
                      sunriseOffset: {
                        ...this.state.sunriseOffset,
                        offsetTimeSign: e.target.value
                      }
                    })
                  }
                >
                  {scheduleOffSetSigns}
                </FormControl>
                <FormControl
                  componentClass="select"
                  defaultValue={
                    !this.props.isCreate
                      ? this.state.sunriseOffsetIndex
                      : DefaultSunriseOffset
                  }
                  onChange={e =>
                    this.setState({
                      sunriseOffset: {
                        ...this.state.sunriseOffset,
                        offsetHours:
                          TwilightOffset[e.target.value].Value.offsetHours,
                        offsetMinutes:
                          TwilightOffset[e.target.value].Value.offsetMinutes
                      }
                    })
                  }
                >
                  {sunriseOffsets}
                </FormControl>
              </FormGroup>
            </div>
          </div>
        </section>
      );
    }
    let modal = "";
    if (
      this.props.isSeries ||
      this.props.isCreate ||
      this.state.event.parentSchedulingFrequency === 0
    ) {
      modal = (
        <RecurringSchedule
          dayOfWeek={this.state.event.dayOfWeek}
          defaultDaysOfWeek={this.state.event.daysOfWeek}
          weekOfMonth={this.state.event.weekOfMonth}
          onFrequencyTypeChange={this.handleFrequencyTypeChange}
          defaultfrequencyType={this.state.event.parentFrequencyType}
          key={this.state.event.fromDate}
          start={this.state.event.fromDate} // added end
          end={
            this.props.schedule.scheduleId &&
            this.props.schedule.parentSchedulingFrequency !== 0
              ? this.state.event.endDate
              : new Date(new Date().getFullYear(), 11, 31)
          }
          onRecurringEndDateChange={this.handleRecurringEndDtChange}
          siteId={this.props.siteId}
          onDaysOfWeekChange={this.handleDaysOfWeekChange}
        />
      );
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        container={this}
        style={{ opacity: 1 }}
      >
        <Modal.Header>
          <Modal.Title style={{ fontWeight: "bold" }}>
            {headerTitle}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form id="addScheduleform">
            <div className="row">
              <FormGroup controlId="formControlsDate" className="col-md-12">
                <ControlLabel>{i18next.t(Labels.DATE)}</ControlLabel>
                <div className="eua-ms-dt-control">
                  <div className="icon-container">
                    <CalendarIcon
                      isDisabled={
                        (this.props.isCreate ? this.state.event.disableDateTime : !this.props.isSeries && this.props.schedule.parentSchedulingFrequency !== 0)
                      }
                    />
                  </div>
                  <Datetime
                    locale={LanguageSelector.SelectLanguage(this.props.siteId)}
                    defaultValue={this.state.event.fromDate}
                    value={this.state.event.fromDate}
                    inputProps={{
                      readOnly: true, className: "dt", disabled:
                        (this.props.isCreate ? this.state.event.disableDateTime : !this.props.isSeries && this.props.schedule.parentSchedulingFrequency !== 0)
                    }}
                    closeOnSelect
                    disableOnClickOutside={false}
                    onChange={this.handlefromDateChange}
                    isValidDate={this.validFromDateSelection}
                    timeFormat={false}
                  />
                </div>
              </FormGroup>
            </div>
            <div id="masterscheduleinputcontainer">
              {(((!this.props.isSeries || this.props.isCreate) && this.state.event.isTwilight) || (this.props.isSeries && this.state.event.parentMasterScheduleType === 1)) && ( // Enables twilight while creating a twilight sch/an occurrence is twilight/whole series is twilight
                <p style={{ marginLeft: "25px" }}>
                  {i18next.t(Labels.TWILIGHT_RULE_INFO)}
                </p>
              )}
              <div className="row twilight-container">
                <ControlLabel id="label-twilight" className="col-md-10">
                  {((this.props.isSeries && this.state.event.parentMasterScheduleType === 1) || ((!this.props.isSeries || this.props.isCreate) && this.state.event.isTwilight)) &&
                    i18next.t(Labels.TWILIGHT_ENABLED_LABEL)}
                  {(!this.state.event.isTwilight || (this.props.isCreate && !this.state.event.isTwilight) || (this.props.isSeries && this.state.event.parentMasterScheduleType !== 1)) &&
                    i18next.t(Labels.TWILIGHT_ENABLE_LABEL)}
                </ControlLabel>
                <label htmlFor="isTwilight" className="switch">
                  <input
                    id="isTwilight"
                    type="checkbox"
                    onChange={this.handleTwilightToggleChange}
                    checked={(this.props.isSeries && this.state.event.parentMasterScheduleType === 1) || ((!this.props.isSeries || this.props.isCreate) && this.state.event.isTwilight)}
                  />
                  <span className="slider round" />
                </label>
                {((this.props.isSeries && this.state.event.parentMasterScheduleType === 1) || ((!this.props.isSeries || this.props.isCreate) && this.state.event.isTwilight)) && (
                  <p className="small-p-text">
                    {i18next.t(Labels.TWILIGHT_ENABLE_MSG)}
                  </p>
                )}
                <hr className="seperator" />
                {twilight}
              </div>
              {((!this.state.event.isTwilight && !this.props.isSeries) || (this.props.isCreate && !this.state.event.isTwilight) || (this.props.isSeries && this.state.event.parentMasterScheduleType !== 1)) && (
                <div className="row">
                  <FormGroup
                    controlId="formControlsFromTime"
                    className="col-md-6"
                  >
                    <ControlLabel>{i18next.t(Labels.FROM)}</ControlLabel>
                    <CustomDropdown
                      onSelectChange={this.handleStartTimeChange}
                      startTime={SchedulingUtilities.getOnlyDate(
                        this.state.event.fromDate
                      )}
                      selectedTime={SchedulingUtilities.getformattedTime(
                        this.state.event.startTime
                      )}
                      key={this.state.event.fromDate}
                    />
                  </FormGroup>
                  <FormGroup
                    controlId="formControlsTillTime"
                    className="col-md-6"
                  >
                    <ControlLabel>{i18next.t(Labels.TO)}</ControlLabel>
                    <CustomDropdown
                      onSelectChange={this.handleEndTimeChange}
                      startTime={SchedulingUtilities.getOnlyDate(
                        this.state.event.fromDate
                      )}
                      selectedTime={SchedulingUtilities.getformattedTime(
                        this.state.event.endTime
                      )}
                    />
                  </FormGroup>
                </div>
              )}
              <div className="row">{modal}</div>
              {/* To be uncommented when we implement Never ending */}
              {/* <div className="row">
              <FormGroup>
                <Col sm={12}>
                  <Checkbox>Never Ending</Checkbox>
                </Col>
              </FormGroup>
            </div> */}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>{footerButtons}</Modal.Footer>
      </Modal>
    );
  }
}

AddMasterScheduleModal.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.bool,
  siteId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  schedule: PropTypes.instanceOf(MasterSchedule),
  isSeries: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired
};
AddMasterScheduleModal.defaultProps = {
  show: false,
  schedule: null
};
export default AddMasterScheduleModal;