import React from "react";
import PropTypes from "prop-types";

const BarChart = props => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
    <title>{props.tooltip}</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M22.0003,4 L18.0003,4 C17.4473,4 17.0003,4.448 17.0003,5 L17.0003,23 L23.0003,23 L23.0003,5 C23.0003,4.448 22.5523,4 22.0003,4 M6.0003,1 L2.0003,1 C1.4473,1 1.0003,1.448 1.0003,2 L1.0003,23 L7.0003,23 L7.0003,2 C7.0003,1.448 6.5523,1 6.0003,1 M14.0003,9 L10.0003,9 C9.4473,9 9.0003,9.448 9.0003,10 L9.0003,22.988 L15.0003,22.988 L15.0003,10 C15.0003,9.448 14.5523,9 14.0003,9"
        id="path-bar"
      />
    </defs>
    <g id="VD2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="placeholder-/-monitoring-02"
        transform="translate(-1.000000, -1.000000)"
      >
        <mask id="mask-bar" fill="white">
          <use href="#path-bar" />
        </mask>
        <use id="Mask" fill="#000000" fillRule="evenodd" href="#path-bar" />
        <g
          id="color-/-100-dark-grey"
          mask="url(#mask-bar)"
          fill="#3C3C41"
          fillRule="evenodd"
        >
          <rect id="icon_color" x="0" y="0" width="24" height="24" />
        </g>
      </g>
    </g>
  </svg>
);
BarChart.propTypes = {
  tooltip: PropTypes.string
};

BarChart.defaultProps = {
  tooltip: null
};
export default BarChart;
