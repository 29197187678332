import ScheduleManagement from "../Schedule/ScheduleManagement";
import Service from "../helpers/Service";
import ApiErrorHandler from "../helpers/ApiErrorHandler";
import LanguageSelector from "../Language/LanguageSelector";

class SchedulingApi {
  static getSites() {
    LanguageSelector.SelectLanguage();
    return Service.get("/api/Site/sites")
      .then(json => {
        ScheduleManagement.setSites(json.data.sites);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  /*
//MI: master api in field api class: do we need to remove?
  static getMasterScheduleForSite(site) {
    return Service.get(`api/Schedule/master/${site}`)
      .then(json => {
        ScheduleManagement.setMasterScheduleDetails(json.data);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }
*/
  static getFieldsForSite(site) {
    LanguageSelector.SelectLanguage(site);
    return Service.get(`api/Field/GetField/${site}`)
      .then(json => {
        ScheduleManagement.setFields(json.data);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  static getStateForSite(site) {
    LanguageSelector.SelectLanguage(site);
    return Service.get(`api/Control/sites/${site}/state`)
      .then(json => {
        if (json.data.fields.length === 0) {
          throw new Error("failed to load states for the pitches");
        } else {
          const states = json.data.fields;
          const statesField = new Map(states.map(i => [i.id, i.state]));
          ScheduleManagement.setStates(statesField);
        }
        return json;
      })
      .catch(error => {
        if (error.response !== undefined) {
          ApiErrorHandler.handleError(error);
        }
      });
  }

  static getFieldPermssions(site) {
    LanguageSelector.SelectLanguage(site);
    return Service.get(`api/Control/Permissions/${site}`)
      .then(json => {
        ScheduleManagement.setFieldPermissions(json.data.fields);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  static setStateForField(field, payload, siteId) {
    LanguageSelector.SelectLanguage(siteId);
    return Service.put(`api/Control/fields/${field}/state`, payload)
      .then(json => json)
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  static switchOffSite(site) {
    LanguageSelector.SelectLanguage(site);
    return Service.post(`api/Control/sites/${site}/off`)
      .then(json => json)
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  static getScenes(siteId) {
    LanguageSelector.SelectLanguage(siteId);
    return Service.get(`/api/Control/scenes/${siteId}`)
      .then(json => {
        ScheduleManagement.setScenes(json.data);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }
}

export default SchedulingApi;
