import React from "react";

const DoubleChevronCollapse = () => {
  const svgStyle = {
    fill: "#3c3c41"
  };
  return (
    <svg width="22px" height="22px" viewBox="0 0 24 24">
      <path
        style={svgStyle}
        d="M13.72,3.82,11.83,2,3,12l8.83,10,1.87-1.85L6.5,12Zm7,0L18.9,2,10.07,12,18.9,22l1.86-1.86L13.57,12Z"
      />
    </svg>
  );
};

export default DoubleChevronCollapse;
