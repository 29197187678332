import React from "react";
import PropTypes from "prop-types";

const SceneComfort = props => {
  const svgStyle = {
    fill: props.active === "true" ? "#FF375F" : "#3c3c41"
  };
  return (
    <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.label}</title>
      <path
        style={svgStyle}
        d="M12.48,8h-1A3.94,3.94,0,0,0,8,11.19,3.65,3.65,0,0,0,9.3,14.53a2,2,0,0,1,.64,1,.59.59,0,0,0,.56.44h3a.59.59,0,0,0,.56-.44,2,2,0,0,1,.64-1A3.65,3.65,0,0,0,16,11.19,3.94,3.94,0,0,0,12.48,8Z"
      />
      <rect style={svgStyle} x="4" y="11" width="2" height="2" />
      <rect style={svgStyle} x="18" y="11" width="2" height="2" />
      <rect
        style={svgStyle}
        x="6"
        y="6"
        width="2"
        height="2"
        transform="translate(-2.9 7) rotate(-45)"
      />
      <rect style={svgStyle} x="11" y="4" width="2" height="2" />
      <rect
        style={svgStyle}
        x="16"
        y="6"
        width="2"
        height="2"
        transform="translate(0.03 14.07) rotate(-45)"
      />
      <path style={svgStyle} d="M13,17.5H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
      <path
        style={svgStyle}
        d="M12,22.5A1.5,1.5,0,0,0,13.5,21h-3A1.5,1.5,0,0,0,12,22.5Z"
      />
    </svg>
  );
};

SceneComfort.propTypes = {
  active: PropTypes.string,
  label: PropTypes.string
};

SceneComfort.defaultProps = {
  active: "",
  label: ""
};
export default SceneComfort;
