import {
	axisBottom,
	timeFormat,
	timeHour,
	timeDay,
	timeMonth,
	timeParse,
	timeYear
  } from "d3";
  import { EnergyFrequencyTypes } from "../constants/MonitorTypeConstants";
  
  // parse the energy prop data
  const flattenData = (data, siteStates) => {
	const strictIsoParse = timeParse("%Y-%m-%dT%H:%M:%S");
	const result = [];
	data.forEach(site => {
	  site.energydata.forEach(energyReading => {
		const newData = {
		  siteId: site.siteId,
		  date: strictIsoParse(`${energyReading.date}`),
		  energy: +energyReading.kwh.toFixed(2),
		  color: site.color.Maincolor,
		  bgColor: site.color.bgColor,
		  siteName: site.siteName,
		  active: siteStates.get(site.siteId),
		  isinterpolate: energyReading.isinterpolate
		};
		result.push(newData);
	  });
	});
	result.sort((a, b) => a.date - b.date);
	return result;
  };
  
  const formatTime = timeFormat("%H:%M");
  
  // for 24 hour tick
  let currentDay=-1;
  const customTicks = (d) => {
	  if(currentDay===-1 || d.getHours() === 23)
	  	currentDay=d.getDay();

	if (d.getHours() === 0 && d.getDay()!==currentDay) {
		currentDay=-1;
	    return "24:00";
	 
	}
	return formatTime(d);
  };
  
  // x-axis scale for different rangeTypes
  const timeScale = (rangeType, xScale) => {
	
	switch (rangeType) {
	  case EnergyFrequencyTypes.Day:
		return axisBottom(xScale)
		  .ticks(timeHour.every(2))
		  .tickFormat(d => customTicks(d))
		  .tickPadding(10);
  
	  case EnergyFrequencyTypes.SevenDays:
		return axisBottom(xScale)
		  .ticks(timeDay.every(1))
		  .tickFormat(timeFormat("%m/%d"))
		  .tickPadding(10);
  
	  case EnergyFrequencyTypes.Month:
		return axisBottom(xScale)
		  .ticks(timeDay.every(2))
		  .tickFormat(timeFormat("%m/%d"))
		  .tickPadding(10);
  
	  case EnergyFrequencyTypes.Year:
	  case EnergyFrequencyTypes.ThreeMonths:
		return axisBottom(xScale)
		  .ticks(timeMonth)
		  .tickFormat(timeFormat("%m/%Y"))
		  .tickPadding(10);
  
	  case EnergyFrequencyTypes.FiveYears:
		return axisBottom(xScale)
		  .ticks(timeYear)
		  .tickFormat(timeFormat("%Y"))
		  .tickPadding(10);
  
	  default:
		return axisBottom(xScale).tickFormat(timeFormat("%B"));
	}
  };
  
  // Tooltip date text
  const dateFormatter = (date, rangeType) => {
	switch (rangeType) {
	  case EnergyFrequencyTypes.Day: {
		const formatDate = timeFormat("%m/%d/%Y");
		const formatHour = timeFormat("%H:%M");
		const currentDate = formatDate(date);
		const currentHour = formatHour(date);
		return `${currentDate}, ${currentHour}`;
	  }
	  case EnergyFrequencyTypes.Month:
	  case EnergyFrequencyTypes.SevenDays: {
		const formatDate = timeFormat("%m/%d/%Y");
		// const formatDay = timeFormat("%A");
		const currentDate = formatDate(date);
		// const currentDay = formatDay(date);
		return `${currentDate}`;
	  }
	  case EnergyFrequencyTypes.Year:
	  case EnergyFrequencyTypes.ThreeMonths: {
		const formatDate = timeFormat("%m/%Y");
		const currentDate = formatDate(date);
		return currentDate;
	  }
	  case EnergyFrequencyTypes.FiveYears: {
		const formatDate = timeFormat("%Y");
		const currentDate = formatDate(date);
		return currentDate;
	  }
  
	  default:
		return "";
	}
  };
  
  export default {
	flattenData,
	timeScale,
	dateFormatter
  };
  