import React from "react";
import PropTypes from "prop-types";

const GearWheel = props => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
    <title>{props.tooltip}</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M12.0005,14.5365 C10.6025,14.5365 9.4645,13.3985 9.4645,12.0005 C9.4645,10.6025 10.6025,9.4645 12.0005,9.4645 C13.3975,9.4645 14.5355,10.6025 14.5355,12.0005 C14.5355,13.3985 13.3975,14.5365 12.0005,14.5365 M22.4805,14.5195 L20.6375,13.4945 L20.6375,10.5055 L22.4805,9.4805 C22.9775,9.2045 23.1465,8.5915 22.8595,8.1125 L20.9235,4.8825 C20.7315,4.5625 20.3845,4.3835 20.0265,4.3835 C19.8525,4.3835 19.6755,4.4265 19.5125,4.5165 L17.6655,5.5435 L14.9745,4.0515 L14.9745,1.9995 C14.9745,1.4475 14.5115,0.9995 13.9395,0.9995 L10.0605,0.9995 C9.4885,0.9995 9.0255,1.4475 9.0255,1.9995 L9.0255,4.0515 L6.3345,5.5435 L4.4875,4.5165 C4.3245,4.4265 4.1465,4.3835 3.9725,4.3835 C3.6155,4.3835 3.2685,4.5615 3.0765,4.8825 L1.1395,8.1125 C0.8525,8.5915 1.0215,9.2045 1.5195,9.4805 L3.3625,10.5055 L3.3625,13.4945 L1.5195,14.5195 C1.0215,14.7955 0.8525,15.4095 1.1395,15.8885 L3.0765,19.1175 C3.2685,19.4375 3.6155,19.6165 3.9725,19.6165 C4.1465,19.6165 4.3245,19.5735 4.4875,19.4835 L6.3345,18.4565 L9.0255,19.9485 L9.0255,21.9995 C9.0255,22.5525 9.4885,22.9995 10.0605,22.9995 L13.9395,22.9995 C14.5115,22.9995 14.9745,22.5525 14.9745,21.9995 L14.9745,19.9485 L17.6655,18.4565 L19.5125,19.4835 C19.6755,19.5735 19.8525,19.6165 20.0265,19.6165 C20.3845,19.6165 20.7315,19.4375 20.9235,19.1175 L22.8595,15.8885 C23.1465,15.4095 22.9775,14.7955 22.4805,14.5195"
        id="path-gw"
      />
    </defs>
    <g id="VD2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Account-Copy" transform="translate(-13.000000, -13.000000)">
        <g id="Settings">
          <rect id="Bounds" x="0" y="0" width="48" height="48" />
          <g
            id="placeholder-/-settings"
            transform="translate(12.000000, 12.000000)"
          >
            <mask id="mask-gw" fill="white">
              <use href="#path-gw" />
            </mask>
            <use id="Mask" fill="#000000" fillRule="evenodd" href="#path-gw" />
            <g
              id="color-/-100-dark-grey"
              mask="url(#mask-gw)"
              fill="#3C3C41"
              fillRule="evenodd"
            >
              <rect id="icon_color" x="0" y="0" width="24" height="24" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
GearWheel.propTypes = {
  tooltip: PropTypes.string
};

GearWheel.defaultProps = {
  tooltip: null
};
export default GearWheel;
