import React from 'react';
import PropTypes from "prop-types";

const  AlertInfo = props => {
  const cls1 = {
    fill: props.color,
    fillRule: "evenodd"
  }  
  return (
    <svg height={props.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>{props.label}</title><g id="icons"><g id="action_and_navigation_icons_-_heart" data-name="action and navigation icons - heart"><path style={cls1} d="M13,2H3A1,1,0,0,0,2,3V13a1,1,0,0,0,1,1H13a1,1,0,0,0,1-1V3A1,1,0,0,0,13,2ZM9,12H7V10H9ZM9,8.5H7V4H9Z"/></g></g></svg>
    );
}

AlertInfo.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string
};

AlertInfo.defaultProps = {
  label: "",
  color: "#DC3205",
  height: "14px"
};

export default AlertInfo;
