import React, { Component } from "react";
import i18next from "i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import * as Labels from "../constants/Labels";
import SchedulingApi from "./MasterSchedulingApi";
import NotificationActions from "../helpers/Notification";
import MasterSchedule from "../datamodels/MasterSchedule";
import SchedulingUtilities from "../helpers/SchedulingUtilities";
import LanguageSelector from "../Language/LanguageSelector";
import "../css/Schedule.css";

class DeleteMasterScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteSeries: false,
      deleteModal: this.props.show,
      onRemove: false
    };
    this.handleDeleteSeries = this.handleDeleteSeries.bind(this);
    this.handleDeleteOccurrence = this.handleDeleteOccurrence.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.removeOk = this.removeOk.bind(this);
  }

  handleDeleteSeries() {
    this.setState({
      deleteSeries: true,
    });
    this.onRemove();
  }

  handleDeleteOccurrence() {
    this.setState({
      deleteSeries: false,
    });
    this.onRemove();
  }

  handleCancelDelete() {
    this.setState({
      deleteSeries: false,
      deleteModal: true,
      onRemove: false
    });
  }

  onRemove = () => {
    this.setState({
      deleteModal: false,
      onRemove: true
    });
  }

  removeOk() {
    const deleteSchedule = new MasterSchedule({
      scheduleId: this.props.event.scheduleId,
      scheduleStartDate: !this.state.deleteSeries || this.props.event.parentSchedulingFrequency === 0 ? SchedulingUtilities.getOnlyDate(new Date(this.props.event.recurringScheduleStartDate)) : SchedulingUtilities.getOnlyDate(new Date(this.props.event.scheduleStartDate)),
      scheduleEndDate: !this.state.deleteSeries || this.props.event.parentSchedulingFrequency === 0 ? SchedulingUtilities.getOnlyDate(new Date(this.props.event.recurringScheduleEndDate)) : SchedulingUtilities.getOnlyDate(new Date(this.props.event.scheduleEndDate)),
      userName: this.props.event.userName,
      siteId: this.props.siteId,
      DeleteType: this.state.deleteSeries || this.props.event.parentSchedulingFrequency === 0 ? 0 : 1,
      masterScheduleType: this.props.event.masterScheduleType // send parentMasterScheduleType incase of series
    });

    SchedulingApi.removeMasterSchedule(deleteSchedule)
      .then(response => {
        if (response && response.status !== 202 && response.status !== 200) {
          /* eslint-disable no-console */
          console.log(response.message);
          /* eslint-enable no-console */
          NotificationActions.error(
            i18next.t(Labels.MASTER_SCHEDULE_DELETE_FAILURE)
          );
        } else {
          this.props.save();
          NotificationActions.success(
            i18next.t(Labels.MASTER_SCHEDULE_DELETE_SUCCESS)
          );
        }
      })
      .catch(error => {
        /* eslint-disable no-console */
        console.log(error.message);
        /* eslint-enable no-console */
      });
  }

  render() {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.siteId));
    let modal = '';
    if (this.state.onRemove) {
      modal = (
        <Modal show={this.state.onRemove} className="delete-schedule-container">
          <Modal.Body className="delete-schedule-modalbody">
            <div>
              <p className="delete-title">{i18next.t(Labels.REMOVE_BUTTON)}</p>
              <p className="delete-message">{i18next.t(Labels.MASTER_SCHEDULE_DELETE_CONFIRM)}</p>
              <div>
                <button
                  type="button"
                  onClick={this.removeOk}
                >
                  {i18next.t(Labels.OK)}
                </button>
                <br />
                <button
                  type="button"
                  onClick={this.handleCancelDelete}
                >
                  {i18next.t(Labels.CANCEL_BUTTON)}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }

    else if (this.state.deleteModal) {
      modal = (<Modal show={this.state.deleteModal} onHide={this.props.close} className="delete-schedule-container">
        <Modal.Body className="delete-schedule-modalbody">
          <div>
            <p className="delete-title">{`${i18next.t(Labels.DELETE)} ${i18next.t(Labels.EVENT)}`}</p>
            <p className="delete-message">{i18next.t(Labels.DELETE_SERIES_OCCURRENCE_MESSAGE)}</p>
            <div>
              <button
                type="button"
                onClick={this.handleDeleteOccurrence}
                disabled={this.props.event.parentSchedulingFrequency !== 0 && SchedulingUtilities.getOnlyDate(new Date(this.props.event.recurringScheduleStartDate)) === SchedulingUtilities.getOnlyDate(new Date()) || moment(SchedulingUtilities.getOnlyDate(new Date(this.props.event.recurringScheduleStartDate))).isBefore(moment(SchedulingUtilities.getOnlyDate(new Date())))}
              >
                {i18next.t(Labels.DELETE_OCCURRENCE)}
              </button>
              <br />
              <button
                type="button"
                onClick={this.handleDeleteSeries}
                disabled={this.props.event.parentSchedulingFrequency === 0}
              >
                {i18next.t(Labels.DELETE_SERIES)}
              </button>
            </div>
            <button
              type="button"
              className="delete-cancel"
              onClick={this.props.save}
            >
              {i18next.t(Labels.CANCEL_BUTTON)}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      )
    }
    return (
      <div>{modal}</div>
    );
  }
}

DeleteMasterScheduleModal.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  show: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired
}

export default DeleteMasterScheduleModal;
