const daysOfWeekList = {
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday"
};
const daysOfWeekKVP = [
  { Key: 0, Value: daysOfWeekList.Sunday },
  { Key: 1, Value: daysOfWeekList.Monday },
  { Key: 2, Value: daysOfWeekList.Tuesday },
  { Key: 3, Value: daysOfWeekList.Wednesday },
  { Key: 4, Value: daysOfWeekList.Thursday },
  { Key: 5, Value: daysOfWeekList.Friday },
  { Key: 6, Value: daysOfWeekList.Saturday },
];

export { daysOfWeekList, daysOfWeekKVP};