const WeekOfMonth = [null, "First", "Second", "Third", "Fourth", "Fifth"];

const DayofWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const Views = [
  { Key: "day", Value: "Day" },
  { Key: "week", Value: "Week" },
  { Key: "month", Value: "Month" }
];

export { WeekOfMonth, DayofWeek, Views };
