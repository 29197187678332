const SceneTypes = {
  // OFF: "Off",
  COMFORT: "Comfort",
  TRAINING: "Training",
  MATCH: "Match",
};

const ControlSceneTypes = {
  // OFF: "Off",
  COMFORT: "Comfort",
  TRAINING: "Training",
  MATCH: "Match",
  HALFFIELDLEFT: "TrainingLeft",
  HALFFIELDRIGHT: "TrainingRight",
  OFF: "Off"
};

const CustomScenesView = [
  "Match",
  "Training",
  "Comfort",
  "TrainingLeft",
  "TrainingRight",
  "Off"
];

const halfFieldScenesKVP = [
  { Key: ControlSceneTypes.HALFFIELDRIGHT, Value: "training-right" },
  { Key: ControlSceneTypes.HALFFIELDLEFT, Value: "training-left" },
];

export { SceneTypes, ControlSceneTypes, CustomScenesView, halfFieldScenesKVP };
