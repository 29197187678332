import React from "react";

const Twilight = () => {
  const svgStyle = {
    fill: "#3c3c41"
  };
  return (
    <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>Twilight</title>
      <rect style={svgStyle} x="2" y="18" width="20" height="2" />
      <path style={svgStyle} d="M17,16A5,5,0,0,0,7,16Z" />
      <rect style={svgStyle} x="11" y="5" width="2" height="4" />
      <path
        style={svgStyle}
        d="M19.78,8.64,18.36,7.22,15.59,10a6.79,6.79,0,0,1,1.56,1.27Z"
      />
      <path
        style={svgStyle}
        d="M8.41,10,5.64,7.22,4.22,8.64l2.63,2.63A6.79,6.79,0,0,1,8.41,10Z"
      />
      <path style={svgStyle} d="M18.71,14A6.91,6.91,0,0,1,19,16h3V14Z" />
      <path style={svgStyle} d="M5.29,14H2v2H5A6.91,6.91,0,0,1,5.29,14Z" />
    </svg>
  );
};

export default Twilight;
