export const FACILITIES = "Facilities";
export const MONITOR = "Monitor";
export const ABOUT = "About";
export const SIGNOUT = "logout";
export const INTERNET_LOST = "Internet Connection lost";
export const NO_INTERNET = "No Internet Connection available When restored, the window will";
export const NETWORK_DIALOG_CLOSE = "close automatically";
export const REPEAT = "Repeat";
export const END_REPEAT = "End repeat";
export const NEW_EVENT = "Create new event";
export const CREATE_EVENT_BUTTON = "Create";
export const NOTFOUND_404 = "404 Not Found";
export const RESOURCE_NOT_FOUND = "The resource you are looking for is not found.";
export const CREATE_RULE = "Create rule";
export const CANCEL_BUTTON = "Cancel";
export const REMOVE_BUTTON = "Remove";
export const SAVE_BUTTON = "Save";
export const SUNSET_SETTING = "Sunset setting";
export const POWER_ON = "Power on";
export const POWER_OFF = "Power off";
export const FROM = "From";
export const TO = "To";
export const CREATE_NEW_RULE = "Create New Rule";
export const EDIT_RULE = "Edit Rule";
export const SUNRISE_SETTING = "Sunrise setting";
export const DATE = "Date";
export const MASTERSCHEDULE_NEW_RULE = "New rule";
export const RANGE = "Range";
export const GENERATE = "Generate";
export const EXPORT = "Export";
export const NO_FIELDS = "No fields are present in the selected site";
export const NO_SCENE_ACTIVE = "No scene active";
export const FIELD_SCHEDULE = "Field schedule";
export const TURN_OFF_ALL_LIGHTING = "Turn off all lighting";
export const USAGE_PAGE = "This is usage page";
export const ALERTS_PAGE = "This is alerts page";
export const ACCEPT = "Accept";
export const SPORTS = "Sports";
export const LICENSE_AGREEMENT = "Software License Agreement";
export const IAS_LICENSE_AGREEMENT = "INTERACT SPORTS SOFTWARE LICENSE AGREEMENT";
export const ACCESS_DENIED = "This account is not authorized to view this page.";
export const PREFERENCES = "Preferences";
export const DONE = "Done";
export const LANGUAGE = "Language";

