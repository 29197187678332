import React from "react"
import UserManagement from "../UserManagement";

const TouContent = () => {
  const content = UserManagement.getEULAContent();
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default TouContent;
