class EnergyManagement {
  getEnergyData() {
    return this.energyData;
  }

  setEnergyData(data) {
    this.energyData = data;
  }
}

const instance = new EnergyManagement();
export default instance;
