import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import SceneComfortIcon from "../Icons/SceneComfort";
import SceneMatchIcon from "../Icons/SceneMatch";
import SceneTrainingIcon from "../Icons/SceneTraining";
import SceneOffIcon from "../Icons/SceneOff";
import SceneHalfFieldLeftIcon from "../Icons/SceneHalfFieldLeft";
import SceneHalfFieldRightIcon from "../Icons/SceneHalfFieldRight";
import { ControlSceneTypes } from "../constants/ScenesConstants";
import * as Labels from "../constants/Labels";

class Scene extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   scene: this.props.sceneName,
    //   active: this.props.active
    // };
    this.getSceneIcon = this.getSceneIcon.bind(this);
    this.handleSceneClick = this.handleSceneClick.bind(this);
    this.handleSceneTranslate = this.handleSceneTranslate.bind(this);
  }

  handleSceneClick() {
    this.props.onSceneClick(this.props.sceneName);
  }
  
  getSceneIcon = scene => {
    switch (scene.perfectPlayScene) {
      case ControlSceneTypes.COMFORT:
        return (
          <SceneComfortIcon
            active={this.props.active}
            label={scene.displayScene}
          />
        );

      case ControlSceneTypes.MATCH:
        return (
          <SceneMatchIcon
            active={this.props.active}
            label={scene.displayScene}
          />
        );

      case ControlSceneTypes.TRAINING:
        return (
          <SceneTrainingIcon
            active={this.props.active}
            label={scene.displayScene}
          />
        );

      case ControlSceneTypes.HALFFIELDLEFT:
        return (
          <SceneHalfFieldLeftIcon
            active={this.props.active}
            label={scene.displayScene}
          />
        );

      case ControlSceneTypes.HALFFIELDRIGHT:
        return (
          <SceneHalfFieldRightIcon
            active={this.props.active}
            label={scene.displayScene}
          />
        );

      case ControlSceneTypes.OFF:
        return (
          <SceneOffIcon active={this.props.active} label={scene.displayScene} />
        );

      default:
        return (
          <SceneOffIcon active={this.props.active} label={scene.displayScene} />
        );
    }
  };

  handleSceneTranslate = scene => {
    const result = Labels.SceneTypeForTranslation.filter(
      st => st.Key === scene
    )[0];
    const sceneName = result === undefined ? scene : i18next.t(result.Value);
    return sceneName;
  };

  render() {
    if (this.props.sceneName.length === 0) {
      return <li className="col-3" />;
    }
    const sceneIcon = this.getSceneIcon(this.props.sceneName);
    const sceneLabel =
      this.props.sceneName.displayScene !== ControlSceneTypes.OFF
        ? this.props.sceneName.displayScene
        : Labels.TURN_OFF_FIELD_LIGHTING;

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li
        onClick={this.handleSceneClick}
        onKeyPress={this.handleSceneClick}
        disabled={!this.props.isEnabled}
      >
        <div className="col scene-box pl-0 pr-0">
          <div
            className={
              this.props.active === "true"
                ? "col control-scene-text-bold"
                : "col control-scene-text"
            }
          >
            {this.handleSceneTranslate(sceneLabel)}
          </div>
          <div className="col-6 mx-auto my-auto scene-icon">{sceneIcon}</div>
        </div>
      </li>
    );
  }
}

Scene.propTypes = {
  sceneName: PropTypes.instanceOf(Object),
  active: PropTypes.string,
  onSceneClick: PropTypes.func,
  isEnabled: PropTypes.bool
};

Scene.defaultProps = {
  sceneName: {},
  active: "",
  onSceneClick: null,
  isEnabled: false
};
export default Scene;
