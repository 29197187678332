import React from "react";
import i18next from "i18next";
import moment from "moment";
import {timeFormat} from "d3";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import Graph from "./Graph";
import SiteListFooter from "./SiteListFooter";
import RangeNavigator from "./RangeNavigator";
import { EnergyFrequencyTypes } from "../constants/MonitorTypeConstants";
import GraphUtils from "./GraphUtils";
import * as Labels from "../constants/Labels";

function getState(data) {
  const sitesStates = new Map();
  // data.forEach(site => {
  //   sitesStates.set(site.siteId, false);
  // });
  sitesStates.set(data[0].siteId,true);
  for(let i=1;i<data.length;i+=1){
    sitesStates.set(data[i].siteId,false);
  }
  // if(sitesStates.size > 0)
  // sitesStates.set([...sitesStates][0][0],true);

  return {
    sitesStates
  };
}
class EnergyGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = getState(props.energyData);
    this.siteClick = this.siteClick.bind(this);
    this.getEnergyFlattenData = this.getEnergyFlattenData.bind(this);
    this.handleExportClick=this.handleExportClick.bind(this);
    // const data=[];
    // this.state= {csvData:data, sitesStates:new Map()}
    this.csvLink = React.createRef();
  }

  handleExportClick() {
    // this.getEnergyFlattenData(this.state.csvData)
    this.csvLink.current.link.click();
  }

  getEnergyFlattenData(energydata)
  {
    const result = [];
   // const cdata=[];
   // this.setState( {csvData:cdata});
    const energyMetric="(kWh)";
    let formatDate = timeFormat("%m/%d/%Y");
    if(this.props.rangeType === EnergyFrequencyTypes.Day )
    {	
      formatDate = timeFormat("%H:%M");
    }
    else if(this.props.rangeType === EnergyFrequencyTypes.Month || this.props.rangeType === EnergyFrequencyTypes.SevenDays)
    {
      formatDate = timeFormat("%m/%d/%Y");
    }
    else if(this.props.rangeType === EnergyFrequencyTypes.Year || this.props.rangeType === EnergyFrequencyTypes.ThreeMonths)
    {
      formatDate = timeFormat("%m/%Y");
    }
    else if(this.props.rangeType === EnergyFrequencyTypes.FiveYears )
    {
      formatDate = timeFormat("%Y");
    }
    
    energydata.forEach(data => 
      {
        const csvrow={
          date : formatDate(data.date),
          siteName: data.siteName + energyMetric,
          energy: data.energy
        }
        result.push(csvrow);
      });

   const output = [];
   this.tempEnergy=[];
   result.forEach(e=> {
  const datedata = e.date;
  
   if(!this.tempEnergy[datedata]) {
    this.tempEnergy[datedata] = {"":e.date}
    output.push(this.tempEnergy[datedata]);
  }

  if(!this.tempEnergy[datedata][e.siteName])
  this.tempEnergy[datedata][e.siteName] = e.energy;
  
}, {}); 
    
const jsonAllKeys =  output.reduce((keys, obj) => keys.concat(Object.keys(obj).filter(key => keys.indexOf(key) === -1)), []);
jsonAllKeys.forEach(key=>
{
  output.forEach((row, index)=>
    {
      if(!row[key])
      output[index][key]=0;
    });
});

    this.setState({csvData:output});// , sitesStates:statesData});
  }
  
  siteClick(siteId) {
   const  currentState = this.state.sitesStates.get(siteId);
    const sitesStates = new Map();
    this.props.energyData.forEach(site => {
      sitesStates.set(site.siteId, false);
    });
    sitesStates.set(siteId, !currentState);
    this.setState({
      sitesStates
    });
     this.getEnergyFlattenData(GraphUtils.flattenData(this.props.energyData,
      this.state.sitesStates));
  }
 
  render() {
    if ( this.props.energyData.every((d)=>d.energydata.length === 0)) {
      return (
        <div className="energy-Graph-container pt-3">
          <RangeNavigator
            rangeType={this.props.rangeType}
            startDate={this.props.start}
            endDate={this.props.end}
            handleRangeClick={this.props.handleRangeClick}
          />
          <p className="monitor-no-data mt-5 pt-5">
            {i18next.t(Labels.MONITOR_ERROR_MESSAGE)}
          </p>
        </div>
      );
    }
    // const ListofSitesSelected = this.props.energyData.map(site => (
    //   <SiteConsumptionDetails
    //     key={site.siteId}
    //     siteDetails={site}
    //     rangeType={this.props.rangeType}
    //     siteActiveState={this.state.sitesStates.get(site.siteId)}
    //     handleSiteClick={this.siteClick}
    //   />
    // ));

    return (
      <div className="energy-Graph-container pt-3">
        <RangeNavigator
          rangeType={this.props.rangeType}
          startDate={this.props.start}
          endDate={this.props.end}
          handleRangeClick={this.props.handleRangeClick}
        />

        <div  className=" col-sm-2" style={{marginLeft:"auto", marginTop:"-80px"}}>
        <button
              type="button"
              className="btn eua-monitor-generate-button eua-interact-btn"
              onClick={this.handleExportClick}
            >
              {i18next.t(Labels.EXPORT)}
            </button>
            <CSVLink data={this.state.csvData === undefined ? []:this.state.csvData} ref={this.csvLink} filename={`Energy_${this.props.rangeType}_from_${this.props.start.format("YYYY-MM-DD")}_to_${this.props.end.format("YYYY-MM-DD")}.csv`} target="_blank" style={{ textDecoration: 'none' }}/>
        </div>

        <Graph
          data={this.props.energyData}
          rangeType={this.props.rangeType}
          endDate={this.props.end}
          siteStates={this.state.sitesStates}
          handleSiteClick={this.siteClick}
          getEnergyFlattenData={this.getEnergyFlattenData}
        />
        {/* <ul className="row row-margin pl-4 pt-2">{ListofSitesSelected}</ul> */}
        <SiteListFooter
          rangeType={this.props.rangeType}
          handleSiteClick={this.siteClick}
          sitesStates={this.state.sitesStates}
          energyData={this.props.energyData}
        />
      </div>
    );
  }
}

EnergyGraph.propTypes = {
  energyData: PropTypes.instanceOf(Array),
  rangeType: PropTypes.string,
  end: PropTypes.instanceOf(moment),
  start: PropTypes.instanceOf(moment),
  handleRangeClick: PropTypes.instanceOf(Function)
};

EnergyGraph.defaultProps = {
  energyData: [],
  rangeType: "",
  end: moment(),
  start: moment(),
  handleRangeClick: null
};
export default EnergyGraph;
