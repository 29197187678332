class ScheduleManagement {
  getSites() {
    return this.sites;
  }

  setSites(data) {
    this.sites = data;
  }

  setFields(data) {
    this.fields = data;
  }

  getFields() {
    return this.fields;
  }

  setStates(data) {
    this.states = data;
  }

  getStates() {
    return this.states;
  }

  getFieldPermissions() {
    return this.fieldPermissions;
  }

  setFieldPermissions(data) {
    this.fieldPermissions = data;
  }

  getScenes() {
    return this.scenes;
  }

  setScenes(data) {
    this.scenes = data;
  }

  getIsNetworkDisconnected() {
    return this.isNetworkDown;
  }

  setIsNetworkDisconnected(data) {
    this.isNetworkDown = data;
  }
}

const instance = new ScheduleManagement();
export default instance;
