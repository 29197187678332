/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import i18next from "i18next";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import "./i18n/i18n";
import { MonitorApplication } from "./constants/AppConstants";
import "./css/Home.css";
import FeatureConfigurations from "./helpers/FeatureConfigurations";
import * as Features from "./constants/Features";
import Logo from "./Icons/Logo";
import Help from "./Icons/Help";
import Caret from "./Icons/Caret";
import User from "./Icons/User";
import { networkPolling } from "./constants/PollingConstants";
import NetworkDetector from "./NetworkDetector";
import ScheduleManagement from "./Schedule/ScheduleManagement";
import * as Labels from "./constants/Labels";
import LanguageSelector from "./Language/LanguageSelector";
import LanguageModal from "./Language/LanguageModal";
import LanguageApi from './api/LanguageApi';
import LanguageManagement from "./Language/LanguageManagement";
import "./css/Language.css";

class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHelpTooltipOpen: false,
      isUserTooltipOpen: false,
      isNetworkDown: false,
      languagePreference: false,
      languageSet: true
    };
    this.onSave = this.onSave.bind(this);
    this.handleLanguagePreferencesClose = this.handleLanguagePreferencesClose.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
    this.handleConnectionChange();
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
    const language = LanguageSelector.SelectLanguage();
    i18next.changeLanguage(language).then(t => {
      this.setState({ languageSet: t.language === language });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  handleClickOutside = event => {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      if(this.state.isUserTooltipOpen || this.state.isHelpTooltipOpen ) {
        this.setState({
          isUserTooltipOpen: false,
          isHelpTooltipOpen: false,
          languagePreference: false
        });
      }
    }
  };

  handleToolTipClick = () => {
    this.setState({
      isHelpTooltipOpen: !this.state.isHelpTooltipOpen,
      isUserTooltipOpen: false,
      languagePreference: false
    });
  };

  handleUserToolTipClick = () => {
    this.setState({
      isUserTooltipOpen: !this.state.isUserTooltipOpen,
      isHelpTooltipOpen: false,
      languagePreference: false
    });
  };

  handleConnectionChange = () => {
    const webPing = setInterval(() => {
      fetch(
        `https://www.google.com/images/nav_logo242.png?d=Tue%20Sep%2010%202019%2016%3A27%3A05%20GMT+0530%20%28India%20Standard%20Time%29&timestamp=${new Date().getTime()}`,
        {
          mode: "no-cors",
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            Pragma: "no-cache",
            Expires: 0
          }
        }
      )
        .then(() => {
          ScheduleManagement.setIsNetworkDisconnected(false);
          if (this.state.isNetworkDown) {
            this.setState({ isNetworkDown: false }, () =>
              clearInterval(webPing)
            );
          }
        })
        .catch(() => {
          ScheduleManagement.setIsNetworkDisconnected(true);
          if (!this.state.isNetworkDown) {
            this.setState({ isNetworkDown: true });
          }
        });
    }, networkPolling);
  };

  handlePreferencesClick = () => {
    this.setState({
      languagePreference: true,
      isUserTooltipOpen: false
    });
  }

  handleLanguagePreferencesClose() {
    this.setState({
      languagePreference: false
    });
  }

  onSave(userLanguage) {
    let userPreference = userLanguage;
    if (LanguageManagement.getUserLanguage() === null && userLanguage === null)
    {
      userPreference = "en-US";
    }
    if (LanguageManagement.getUserLanguage() !== userPreference) {
      LanguageApi.setUserLanguage(userPreference).then(response => {
        if(response && response.status === 202)
        {
          LanguageManagement.setUserLanguage(userPreference);
          this.setState({ languagePreference: false });
        }
        window.location.reload(true);
      });   
    }
    else {
      this.setState({ languagePreference: false });
    }
  }

  render() {
    const language = LanguageSelector.SelectLanguage();
    i18next.changeLanguage(language);
    if (this.state.isNetworkDown)
      return <NetworkDetector isDisconnected={this.state.isNetworkDown} />;
    const hasMonitorAccess = () => {
      const appPermission = this.props.sites.some(site =>
        site.appPermissions.includes(MonitorApplication.Key)
      );
      const stageLock = FeatureConfigurations.isStageLocked(
        Features.MONITOR_FEATURE
      );

      return !stageLock && appPermission;
    };

    if (this.state.languagePreference) {
      return <LanguageModal 
        show={this.state.languagePreference}
        onSave={this.onSave}
        handleClose={this.handleLanguagePreferencesClose}
      />;
    }

    const partialTillAccessCheck = <div />;
    const hasMonitoraccess = hasMonitorAccess();
    const isDisabled = !this.props.sites.some(item => item.appPermissions.includes(MonitorApplication.Key));
    const partialPostAccessCheck = (
      <div className="brand-horizontal-bar">
        <NavLink className="brandName-logo" to="/">
          <Logo />
        </NavLink>

        <div className="row navlinks-container">
          <li className="Navbar-link col">
            <NavLink
              activeClassName="Navbar-link-active"
              isActive={(match, location) => location && location.pathname.includes("Sites")}
              to="/Sites"
            >
              {i18next.t(Labels.FACILITIES)}
            </NavLink>
          </li>
          {hasMonitoraccess && (
            <li className={isDisabled ? "disabled-link" : "Navbar-link col"}>
              <NavLink activeClassName="Navbar-link-active" to="/Monitor/Energyusage">
                {i18next.t(Labels.MONITOR)}
              </NavLink>
            </li>
          )}
        </div>
        <div className="userProfile">
          <div className="row row-margin help-header-icon-container">
            <div
              role="button"
              tabIndex={0}
              onClick={this.handleToolTipClick}
              onKeyPress={this.handleToolTipClick}
              className="help-header-icon"
              aria-label="helpIcon"
            >
              <Help />
            </div>
            <div
              role="button"
              tabIndex={0}
              className="help-header-icon-caret"
              onClick={this.handleToolTipClick}
              onKeyPress={this.handleToolTipClick}
              aria-label="caretIcon"
            >
              <Caret />
            </div>
          </div>
          {this.state.isHelpTooltipOpen && (
            <div className="help-tooltip">
              <Link to="/About">{i18next.t(Labels.ABOUT)}</Link>
            </div>
          )}
          <div className="row row-margin help-header-icon-container">
            <div
              role="button"
              tabIndex={0}
              onClick={this.handleUserToolTipClick}
              onKeyPress={this.handleUserToolTipClick}
              className="help-header-icon"
              aria-label="userIcon"
            >
              <User />
            </div>
            <div
              role="button"
              tabIndex={0}
              className="help-header-icon-caret"
              onClick={this.handleUserToolTipClick}
              onKeyPress={this.handleUserToolTipClick}
              aria-label="caretIcon"
            >
              <Caret />
            </div>
          </div>
          {this.state.isUserTooltipOpen && (
            <div className="about-tooltip ">
              <p
                data-toggle="tooltip"
                className="username"
                title={this.props.userName}
              >
                {this.props.userName}
              </p>
              <div
                role="button"
                onClick={this.handlePreferencesClick}
                tabIndex="0"
                onKeyPress={this.handlePreferencesClick}
                className="preferencesButton"
              >
                {i18next.t(Labels.PREFERENCES)}
              </div>
              <div
                role="button"
                onClick={this.props.onLogoutClick}
                tabIndex="0"
                onKeyPress={this.props.onLogoutClick}
                className="logout"
              >
                {i18next.t(Labels.SIGNOUT)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
    if (!this.props.userName) {
      return partialTillAccessCheck;
    }
    return partialPostAccessCheck;
  }
}

MainHeader.propTypes = {
  sites: PropTypes.instanceOf(Array),
  userName: PropTypes.string,
  onLogoutClick: PropTypes.instanceOf(Function)
};
MainHeader.defaultProps = {
  sites: [],
  userName: "",
  onLogoutClick: null,
};
export default MainHeader;
