import moment from "moment";
import { DayofWeek, WeekOfMonth } from "../constants/CalendarConstants";
import { ScheduleOffSetSign } from "../constants/ScheduleTypeConstants";

class SchedulingUtilities {
  static doubleZeros(digit) {
    return digit < 10 ? `0${digit}` : `${digit}`;
  }

  static concatDateTime(date, time) {
    const newDate = moment(date)
      .format("YYYY-MM-DD")
      .toString();
    return moment(`${newDate} ${time}`).toDate();
  }

  static getOnlyDate(dateTime) {
    return `${dateTime.getFullYear()}-${dateTime.getMonth() +
      1}-${dateTime.getDate()}`;
  }

  static getformattedTime(dateTime) {
    if (dateTime instanceof Date)
      return `${this.doubleZeros(dateTime.getHours())}:${this.doubleZeros(
        dateTime.getMinutes()
      )}`;
    return dateTime;
  }
  /* eslint-enable no-console */

  static getDayofWeek(date) {
    const dayOfWeek = DayofWeek[date.getDay()];
    return dayOfWeek;
  }

  static getWeekOfMonth(date) {
    const startWeekDayIndex = 1; // 1 MonthDay 0 Sundays
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDay = firstDate.getDay();

    let weekNumber = Math.ceil((date.getDate() + firstDay) / 7);
    if (startWeekDayIndex === 1) {
      if (date.getDay() === 0 && date.getDate() > 1) {
        weekNumber -= 1;
      }

      if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
        weekNumber += 1;
      }
    }
    return WeekOfMonth[weekNumber];
  }

  static computeHoursMins(date, hours, mins, sign) {
    let newDate = moment(date);
    switch (sign) {
      // Add Sign
      case ScheduleOffSetSign[0].Key:
        newDate = newDate.add(mins, "minutes");
        newDate = newDate.add(hours, "hours");
        return newDate.toDate();
      // subtract sign
      case ScheduleOffSetSign[1].Key:
        newDate = newDate.subtract(mins, "minutes");
        newDate = newDate.subtract(hours, "hours");
        return newDate.toDate();
      default:
        return newDate.toDate();
    }
  }
}

export default SchedulingUtilities;
