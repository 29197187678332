const authorizationRoot = CONFIG.IDENTITY_SERVICE_URL;
function generateRandomNumber() {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0] / (2 ** 32);
}
function makeid() {
  let id = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 5; i += 1) {
    id += possible.charAt(Math.floor(generateRandomNumber() * possible.length));
  }
  return id;
}

function parseUrlToTokens() {
  const hash = window.location.hash.substr(1);
  const result = hash.split("&").reduce((dict, item) => {
    const dictObj = dict;
    const parts = item.split("=");
    dictObj[parts[0]] = decodeURI(parts[1]);
    return dictObj;
  }, {});
  return result;
}

function redirectToSignOutPage() {
  const authBaseUrl = authorizationRoot;
  const authorizationUrl = `${authBaseUrl}/core/connect/endsession`;
  let redirectUri = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) {
    redirectUri = `${redirectUri}:${window.location.port}`;
  }
  const logoutUrl =
    `${authorizationUrl}?` +
    `post_logout_redirect_uri=${encodeURIComponent(`${redirectUri}/`)}&` +
    `id_token_hint=${sessionStorage.getItem("idToken")}`;
  sessionStorage.removeItem("bearerToken");
  sessionStorage.removeItem("idToken");
  window.location.href = logoutUrl;
}

function redirectToAuthPage() {
  const authBaseUrl = authorizationRoot;
  const authorizationUrl = `${authBaseUrl}/core/connect/authorize`;
  const clientId = "sportswebenduserapplication";
  const responseType = "id_token token";
  const scope = "openid email name culture read write";
  const state = `${Date.now()}${generateRandomNumber()}`;
  let redirectUri = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) {
    redirectUri = `${redirectUri}:${window.location.port}`;
  }
  if (window.location.pathname && window.location.pathname.length > 1) {
    redirectUri = `${redirectUri}${window.location.pathname}/`;
  }
  const loginUrl =
    `${authorizationUrl}?` +
    `client_id=${encodeURI(clientId)}&` +
    `redirect_uri=${encodeURI(redirectUri)}&` +
    `response_type=${encodeURI(responseType)}&` +
    `scope=${encodeURI(scope)}&` +
    `nonce=${encodeURI(makeid())}&` +
    `state=${encodeURI(state)}`;

  window.location.href = loginUrl;
}

function hasAccessToken(url) {
  return (
    url && url.indexOf("access_token") > -1 && url.indexOf("id_token") > -1
  );
}

class Auth {
  constructor() {
    this.bearerToken = null;
  }

  isUserAuthenticated() {
    if (!sessionStorage) {
      throw new Error("This app requires session storage to work.");
    }
    const bearerToken = sessionStorage.getItem("bearerToken");
    const idToken = sessionStorage.getItem("idToken");
    if (bearerToken && idToken) {
      // user is already authenticated, just init the ajax API
      this.bearerToken = bearerToken;
      return true;
    }
    if (hasAccessToken(window.location.href)) {
      // user is not authenticated
      // url contains access token, store and init ajax API
      const parsedTokens = parseUrlToTokens();
      this.bearerToken = parsedTokens.access_token;
      sessionStorage.setItem("bearerToken", this.bearerToken);
      sessionStorage.setItem("idToken", parsedTokens.id_token);
      return true;
    }
    // redirected to ID server
    redirectToAuthPage();
    return false;
  }

  signOut() {
    const bearerToken = sessionStorage.getItem("bearerToken");
    this.bearerToken = bearerToken;
    redirectToSignOutPage();
  }
}

const Authenticator = new Auth();
export default Authenticator;
