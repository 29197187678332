import UserManagement from "../UserManagement";
import Authenticator from "../helpers/Auth";
import Service from "../helpers/Service";
import ApiErrorHandler from "../helpers/ApiErrorHandler";
import LanguageSelector from "../Language/LanguageSelector";

class HomeApi {
  static getLoggedInUserData() {
    LanguageSelector.SelectLanguage();
    return Service.get("/api/User/details")
      .then(json => {
        UserManagement.setUserDetail(json.data);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  static signOut() {
    Authenticator.signOut();
  }

  static setUserAgreement(isaccepted) {
    LanguageSelector.SelectLanguage();
    return Service.post("/api/User/Useragreement", isaccepted).catch(error => {
      ApiErrorHandler.handleError(error);
    });
  }
}

export default HomeApi;
