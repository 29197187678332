import React from "react";
import PropTypes from "prop-types";
import {useLocation } from "react-router-dom";
import {ApplicationTypes} from "../constants/AppConstants";

const Calendar = props => {
  const location = useLocation();
  const isSelected  = location.pathname.includes(ApplicationTypes.Schedule);
  const Iconcolor = isSelected ? "#FF375F" : "#3c3c41";
  const disabledColor = "#BEBEBE";
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 22 22"
      version="1.1"
    >
      <title>{props.tooltip}</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M5.25,6 L6.75,6 C7.164,6 7.5,5.731 7.5,5.4 L7.5,0.6 C7.5,0.269 7.164,0 6.75,0 L5.25,0 C4.836,0 4.5,0.269 4.5,0.6 L4.5,5.4 C4.5,5.731 4.836,6 5.25,6 Z M17,12.5 C17,12.776 16.776,13 16.5,13 L14.5,13 C14.224,13 14,12.776 14,12.5 L14,10.5 C14,10.224 14.224,10 14.5,10 L16.5,10 C16.776,10 17,10.224 17,10.5 L17,12.5 Z M17,17.5 C17,17.776 16.776,18 16.5,18 L14.5,18 C14.224,18 14,17.776 14,17.5 L14,15.5 C14,15.224 14.224,15 14.5,15 L16.5,15 C16.776,15 17,15.224 17,15.5 L17,17.5 Z M12.5,12.5 C12.5,12.776 12.276,13 12,13 L10,13 C9.724,13 9.5,12.776 9.5,12.5 L9.5,10.5 C9.5,10.224 9.724,10 10,10 L12,10 C12.276,10 12.5,10.224 12.5,10.5 L12.5,12.5 Z M12.5,17.5 C12.5,17.776 12.276,18 12,18 L10,18 C9.724,18 9.5,17.776 9.5,17.5 L9.5,15.5 C9.5,15.224 9.724,15 10,15 L12,15 C12.276,15 12.5,15.224 12.5,15.5 L12.5,17.5 Z M8,12.5 C8,12.776 7.776,13 7.5,13 L5.5,13 C5.224,13 5,12.776 5,12.5 L5,10.5 C5,10.224 5.224,10 5.5,10 L7.5,10 C7.776,10 8,10.224 8,10.5 L8,12.5 Z M8,17.5 C8,17.776 7.776,18 7.5,18 L5.5,18 C5.224,18 5,17.776 5,17.5 L5,15.5 C5,15.224 5.224,15 5.5,15 L7.5,15 C7.776,15 8,15.224 8,15.5 L8,17.5 Z M21.5,3 L19.5,3 C19.224,3 19,3.224 19,3.5 L19,5 L19,6.5 C19,6.776 18.776,7 18.5,7 L17,7 L15,7 L13.5,7 C13.224,7 13,6.776 13,6.5 L13,5 L13,3.5 C13,3.224 12.776,3 12.5,3 L9.5,3 C9.224,3 9,3.224 9,3.5 L9,5 L9,6.5 C9,6.776 8.776,7 8.5,7 L7,7 L5,7 L3.5,7 C3.224,7 3,6.776 3,6.5 L3,5 L3,3.5 C3,3.224 2.776,3 2.5,3 L0.5,3 C0.224,3 0,3.224 0,3.5 L0,21 C0,21.552 0.447,22 1,22 L21,22 C21.553,22 22,21.552 22,21 L22,3.5 C22,3.224 21.776,3 21.5,3 Z M15.25,6 L16.75,6 C17.164,6 17.5,5.731 17.5,5.4 L17.5,0.6 C17.5,0.269 17.164,0 16.75,0 L15.25,0 C14.836,0 14.5,0.269 14.5,0.6 L14.5,5.4 C14.5,5.731 14.836,6 15.25,6 Z"
          id="path-cal"
        />
      </defs>
      <g id="VD2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="↳-Color">
          <mask id="mask-cal" fill="white">
            <use href="#path-cal" />
          </mask>
          <use id="Mask" fill="#000000" href="#path-cal" />
          <g id="color-/-100-dark-grey" mask="url(#mask-cal)" fill={props.isDisabled ? disabledColor : Iconcolor}>
            <g transform="translate(-1.000000, -2.000000)" id="icon_color">
              <rect x="0" y="0" width="24" height="24" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Calendar.propTypes = {
  tooltip: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isDisabled: PropTypes.bool
};

Calendar.defaultProps = {
  tooltip: null,
  width: 22,
  height: 22,
  isDisabled: false
};
export default Calendar;
