import React from "react";
import PropTypes from "prop-types";

function GifImageContainer(props) {
  return (
    <div>
      <img alt={props.alt} src={props.src} />
    </div>
  );
}

GifImageContainer.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default GifImageContainer;
