/* eslint-disable no-param-reassign */
import moment from "moment";

const getHourlyData = (d) => {
  for (let z = 0; z < d.length; z += 1) {
    const { energydata } = d[z];
    if (energydata.length !== 0) {
      const singleH0urly = [];

      // normalise date format
      energydata.forEach((element) => {
        element.date = moment(element.date)
          .set("minute", 0)
          .set("second", 0)
          .format("YYYY-MM-DDTHH:mm:ss");
      });

      energydata.sort((a, b) => new Date(a.date) - new Date(b.date));

      const firstRecord = moment(energydata[0].date);
      const lastRecord = moment(energydata[energydata.length - 1].date);

      const actualDateToPlotGraph = moment(energydata[1].date);

      // check whether 1st record is 0th hour of actual date
      const actualDateToPlotGraphBefore = actualDateToPlotGraph.clone();
      if (
        firstRecord.get("date") !== actualDateToPlotGraphBefore.get("date") ||
        firstRecord.get("hour") !== 0
      ) {
        if (Math.floor(energydata[0].kwh) !== -1) {
          // we interpolate and create 0th record
          // calculate number of hour differences
          const appendedfirstRecord = actualDateToPlotGraphBefore
            .clone()
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            .format("YYYY-MM-DDTHH:mm:ss");
          const totalHours = Math.round(
            moment
              .duration(
                moment(energydata[1].date).diff(moment(energydata[0].date))
              )
              .asHours()
          );
          const energyInterpolatedForEachMissingData =
            Math.abs(energydata[1].kwh) / (totalHours - 1);
          // const energyInterpolatedForEachMissingDataActual=Math.abs(energydata[1].kwh - energydata[0].kwh) / (totalHours-1);

          // reduce the kwh value that we are not showing before

          // we should not change the energy value for the dates which we have energy
          // const totalHOurBeforZerothHour = Math.round(
          //   moment
          //     .duration(
          //       moment(energydata[1].date).diff(moment(appendedfirstRecord))
          //     )
          //     .asHours()
          // );

          // // eslint-disable-next-line operator-assignment
          // energydata[1].kwh =
          //   totalHOurBeforZerothHour * energyInterpolatedForEachMissingData;   // shd not interpolate the actually present data


          // Remove the 1st record, which is of different Date
          energydata.shift();
          // Add at the begining, the interpolated 0th record
          energydata.unshift({
            date: appendedfirstRecord,
            kwh: energyInterpolatedForEachMissingData,
          });
        } else {
          // Remove the 1st record, whose all previous values are null. i.e its a first record in DB
          energydata.shift();
        }
      }

      // checking whether last record is 0th hour of the next day
      const actualDateToPlotGraphNext = actualDateToPlotGraph
        .clone()
        .add(1, "days");

      if (
        lastRecord.get("date") !== actualDateToPlotGraphNext.get("date") ||
        (lastRecord.get("date") === actualDateToPlotGraphNext.get("date") &&
          lastRecord.get("hour") !== 0) ||
        Math.floor(energydata[energydata.length - 1].kwh) === -1
      ) {
        if (Math.floor(energydata[energydata.length - 1].kwh) === -1) {
          // Remove last record, whose next records are empty. i.e its a last record in DB
          energydata.pop();
        } else {
          // we interpolate and create 24th hour  record
          // set the date to next day's 0th hour i.e 24th hour
          const appendedLastRecord = actualDateToPlotGraphNext
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            .format("YYYY-MM-DDTHH:mm:ss");

          const totalHours = Math.round(
            moment
              .duration(
                moment(lastRecord).diff(
                  moment(energydata[energydata.length - 2].date)
                )
              )
              .asHours()
          );
          // const eachEnergyRecord=
          // Math.abs(energydata[energydata.length-1].kwh - energydata[energydata.length-2].kwh) / totalHours ;

          const eachEnergyRecordActual =
            Math.abs(energydata[energydata.length - 1].kwh) / totalHours;
          // reduce the kwh value that we are not showing before
          const totalHOurBefor24thHour = Math.round(
            moment
              .duration(
                moment(appendedLastRecord).diff(
                  moment(energydata[energydata.length - 2].date)
                )
              )
              .asHours()
          );

          // Remove last record
          energydata.pop();
          // add 24th hour at the end
          energydata.push({
            date: appendedLastRecord,
            kwh: +(totalHOurBefor24thHour * eachEnergyRecordActual),
          });
        }
      }

      const actualFirstRecordDate =
        firstRecord.get("date") !== actualDateToPlotGraph.get("date") ||
        firstRecord.get("hour") !== 0
          ? actualDateToPlotGraph.set("hour", 0)
          : firstRecord;
      const actualFirstHOur = actualFirstRecordDate.get("hour");
      // normalise date formate
      actualFirstRecordDate.set("minute", 0);
      actualFirstRecordDate.set("second", 0);

      const first = moment(energydata[0].date);
      const diffhrs =
        Math.round(
          moment
            .duration(
              moment(moment(energydata[energydata.length - 1].date)).diff(first)
            )
            .asHours()
        ) + 1;

      for (let i = 0, j = 0; i < diffhrs; ) {
        const dfInHours = Math.round(
          moment
            .duration(
              moment(energydata[j].date).diff(moment(energydata[0].date))
            )
            .asHours()
        );

        if (dfInHours !== i) {
          actualFirstRecordDate.set("hour", actualFirstHOur);
          const changedDate = actualFirstRecordDate.add(
            +first.get("hour") + i,
            "hours"
          );
          const thours = Math.round(
            moment
              .duration(
                moment(energydata[j].date).diff(moment(energydata[j - 1].date))
              )
              .asHours()
          );
          const en = thours && Math.abs(energydata[j].kwh) / (thours - 1);
          singleH0urly.push({
            date: moment(changedDate).format("YYYY-MM-DDTHH:mm:ss"),
            kwh: en,
            isinterpolate: true,
          });

          // replicate data used only for graph to show interpolate, not used for energy calculation
          singleH0urly.push({
            date: moment(changedDate).format("YYYY-MM-DDTHH:mm:ss"),
            kwh: en,
            isinterpolate: false,
          });

          i += 1;

          // Replace the accomodated actual value with interpolation
          if (dfInHours === i) {
            actualFirstRecordDate.set("hour", actualFirstHOur);
            // const changeHour = actualFirstRecordDate.add(
            //   +first.get("hour") + i,
            //   "hours"
            // );
            singleH0urly.push({
              date: moment(energydata[j].date)
              .set("minute", 0)
              .set("second", 0)
              .format("YYYY-MM-DDTHH:mm:ss"),
              kwh: energydata[j].kwh,
              isinterpolate: true,
            });

            // replicate data used only for graph to show interpolate, not used for energy calculation
            singleH0urly.push({
              date: moment(energydata[j].date)
              .set("minute", 0)
              .set("second", 0)
              .format("YYYY-MM-DDTHH:mm:ss"),
              kwh: energydata[j].kwh,
              isinterpolate: false,
            });
            i += 1;
            j += 1;
          }
        } else {
          // add actual data
          singleH0urly.push({
            date: moment(energydata[j].date)
              .set("minute", 0)
              .set("second", 0)
              .format("YYYY-MM-DDTHH:mm:ss"),
            kwh: energydata[j].kwh,
            isinterpolate: false,
          });
          j += 1;
          i += 1;
        }
      }

      // // if 24th hour is interpolate, add this . THis is used only for graph calculation
      // if(lastRecord.get("date")=== actualDateToPlotGraph.get("date") || lastRecord.get("hour")!==0){
      //     singleH0urly[singleH0urly.length -1]={
      //       date:energydata[energydata.length -1].date,
      //       kwh:energydata[energydata.length -1].kwh,
      //       isinterpolate:true
      //     }
      //   }

      // if single value, add a dummy to show a single line bar graph
      if (singleH0urly.length === 1) {
        singleH0urly.push({
          date: singleH0urly[0].date,
          kwh: 0,
          isinterpolate: singleH0urly[0].isinterpolate,
        });
      }
      // eslint-disable-next-line no-param-reassign
      d[z].energydata = singleH0urly;
    }
  }
  return d;
};

const getDailyDataNew = (s, e, d) => {
  for (let z = 0; z < d.length; z += 1) {
    const { energydata } = d[z];
    if (energydata.length !== 0) {
      const singleH0urly = [];

      const startTime = s
        .set("hour", 0)
        .set("minute", 0)
        .set("minute", 0)
        .set("second", 0);

      const endTime = e
        .set("hour", 0)
        .set("minute", 0)
        .set("minute", 0)
        .set("second", 0);
      // normalise date formate
      energydata.forEach((element) => {
        element.date = moment(element.date)
          .set("minute", 0)
          .set("second", 0)
          .format("YYYY-MM-DDTHH:mm:ss");
        element.interpolate = false;
      });

      energydata.sort((a, b) => new Date(a.date) - new Date(b.date));
      const firstRecord = moment(energydata[0].date);
      const lastRecord = moment(energydata[energydata.length - 1].date);

      // check whether 1st record is same as startDay, if true skip, else calculate inpterpolation
      // const actualDateToPlotGraphBefore =actualDateToPlotGraph.clone();
      if (firstRecord.get("date") !== startTime.get("date")) {
        // -1 repesents ,no record before this date
        if (Math.floor(energydata[0].kwh) !== -1) {
          // we interpolate start Date's record
          // calculate number of day differences

          const appendedfirstRecord = startTime.clone();
          const totalDays =
            energydata.length > 1
              ? Math.round(
                  moment
                    .duration(
                      moment(energydata[1].date).diff(
                        moment(energydata[0].date)
                      )
                    )
                    .asDays()
                )
              : 1;
          const energyInterpolatedForEachMissingData =
            Math.abs(energydata[1].kwh) / (totalDays - 1);

          // reduce the kwh value that we are not showing before

          // we should not change the energy value for the dates which we have energy
          // const totalDaytsBeforeStartDate = Math.round(
          //   moment
          //     .duration(
          //       moment(energydata[1].date).diff(moment(appendedfirstRecord))
          //     )
          //     .asDays()
          // );

          // energydata[1].kwh =
          //   totalDaytsBeforeStartDate * energyInterpolatedForEachMissingData; // shd not interpolate the actually present data

          // Remove the 1st record, which is of different Date
          energydata.shift();
          // Add at the begining, the interpolated 0th record
          energydata.unshift({
            date: appendedfirstRecord.format("YYYY-MM-DDTHH:mm:ss"),
            kwh: energyInterpolatedForEachMissingData,
            interpolate: true,
          });
        } else {
          // Remove the 1st record, whose all previous values are null. i.e its a first record in DB
          energydata.shift();
        }
      }

      // check whether last record is same as endDate, if true skip, else calculate inpterpolation

      if (lastRecord.get("date") !== endTime.get("date")) {
        // Remove last record, whose next records are empty. i.e its a last record in DB
        if (Math.floor(energydata[energydata.length - 1].kwh) === -1) {
          energydata.pop();
        } else {
          // we interpolate  last date
          const appendedLastRecord = endTime.clone();
          const totalDays = Math.round(
            moment
              .duration(
                moment(lastRecord).diff(
                  moment(energydata[energydata.length - 2].date)
                )
              )
              .asDays()
          );

          const eachEnergyRecordActual =
            Math.abs(energydata[energydata.length - 1].kwh) / totalDays;

          // reduce the kwh value that we are not showing before
          const totalHOurBefor24thHour =
            energydata.length > 1
              ? Math.round(
                  moment
                    .duration(
                      moment(appendedLastRecord).diff(
                        moment(energydata[energydata.length - 2].date)
                      )
                    )
                    .asDays()
                )
              : 1;

          // Remove last record
          energydata.pop();
          // add 24th hour at the end
          energydata.push({
            date: appendedLastRecord.format("YYYY-MM-DDTHH:mm:ss"),
            kwh: totalHOurBefor24thHour * eachEnergyRecordActual,
            interpolate: true,
          });
        }
      }

      const diffDays =
        Math.round(
          moment
            .duration(
              moment(moment(energydata[energydata.length - 1].date)).diff(
                moment(energydata[0].date)
              )
            )
            .asDays()
        ) + 1;

      for (let i = 0, j = 0; i < diffDays; ) {
        const dfInDays = Math.round(
          moment
            .duration(
              moment(energydata[j].date).diff(moment(energydata[0].date))
            )
            .asDays()
        );

        if (dfInDays !== i) {
          const startDay = moment(energydata[0].date).clone();
          const changedDate = startDay.add(i, "days");
          const tdays = Math.round(
            moment
              .duration(
                moment(energydata[j].date).diff(moment(energydata[j - 1].date))
              )
              .asDays()
          );
          const en = tdays && Math.abs(energydata[j].kwh) / (tdays - 1);

          singleH0urly.push({
            date: moment(changedDate).format("YYYY-MM-DDTHH:mm:ss"),
            kwh: en,
            isinterpolate: true,
          });

          // replicate data used only for graph to show interpolate, not used for energy calculation
          singleH0urly.push({
            date: moment(changedDate).format("YYYY-MM-DDTHH:mm:ss"),
            kwh: en,
            isinterpolate: false,
          });

          i += 1;

          //  Replace the accomodated actual value with interpolation
          if (dfInDays === i) {
            const startDayNext = moment(energydata[0].date).clone();
            const changeDay = startDayNext.add(i, "days");
            singleH0urly.push({
              date: moment(changeDay).format("YYYY-MM-DDTHH:mm:ss"),
              kwh: energydata[j].kwh,
              isinterpolate: true,
            });

            // replicate data used only for graph to show interpolate, not used for energy calculation
            singleH0urly.push({
              date: moment(changeDay).format("YYYY-MM-DDTHH:mm:ss"),
              kwh: energydata[j].kwh,
              isinterpolate: false,
            });
            i += 1;
            j += 1;
          }
        } else {
          // add actual data
          singleH0urly.push({
            date: moment(energydata[j].date)
              .set("minute", 0)
              .set("second", 0)
              .format("YYYY-MM-DDTHH:mm:ss"),
            kwh: energydata[j].kwh,
            isinterpolate: false,
          });
          j += 1;
          i += 1;
        }
      }

      // if last record is interpolate, add this . THis is used only for graph dots
      if (
        lastRecord.get("date") !== endTime.get("date") &&
        endTime.get("date") ===
          moment(energydata[energydata.length - 1].date).get("date")
      ) {
        // already interpolated
        if (
          moment(singleH0urly[singleH0urly.length - 1].date).get("date") !==
          moment(singleH0urly[singleH0urly.length - 2].date).get("date")
        ) {
          singleH0urly.pop();
          singleH0urly.push({
            date: energydata[energydata.length - 1].date,
            kwh: energydata[energydata.length - 1].kwh,
            isinterpolate: true,
          });
          singleH0urly.push({
            date: energydata[energydata.length - 1].date,
            kwh: energydata[energydata.length - 1].kwh,
            isinterpolate: false,
          });
        }
      }

      // if single value, add a dummy to show a single line bar graph
      if (singleH0urly.length === 1) {
        singleH0urly.push({
          date: singleH0urly[0].date,
          kwh: 0,
          isinterpolate: singleH0urly[0].isinterpolate,
        });
      }
      // eslint-disable-next-line no-param-reassign
      d[z].energydata = singleH0urly;
    }
  }
  return d;
};

const getMonthlyDataNew = (s, e, d) => {
  for (let z = 0; z < d.length; z += 1) {
    const { energydata } = d[z];
    if (energydata.length !== 0) {
      const singleH0urly = [];

      s = s.clone().startOf("month");

      e = e.clone().startOf("month");

      // normalise date formate
      energydata.forEach((element) => {
        element.date = moment(element.date)
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ss");
        element.interpolate = false;
      });

      energydata.sort((a, b) => new Date(a.date) - new Date(b.date));
      const firstRecord = moment(energydata[0].date);
      const lastRecord = moment(energydata[energydata.length - 1].date);

      // check whether 1st record is same as startDay, if true skip, else calculate inpterpolation

      if (firstRecord.get("month") !== s.get("month")) {
        // check whether it is 1st record in the DB table
        if (Math.floor(energydata[0].kwh) !== -1) {
          // we interpolate start Date's record
          // calculate number of month differences

          // const appendedfirstRecord = moment(energydata[0].date).clone();
          const appendedfirstRecord = s.clone();
          const totalMonths = Math.round(
            moment
              .duration(
                moment(energydata[1].date).diff(moment(energydata[0].date))
              )
              .asMonths()
          );
          const energyInterpolatedForEachMissingData =
            Math.abs(energydata[1].kwh) / (totalMonths - 1);

          // reduce the kwh value that we are not showing before

          // we should not change the energy value for the dates which we have energy
          // const totalDaytsBeforeStartDate = Math.round(
          //   moment
          //     .duration(
          //       moment(energydata[1].date).diff(moment(appendedfirstRecord))
          //     )
          //     .asMonths()
          // );

          // energydata[1].kwh =
          //   totalDaytsBeforeStartDate * energyInterpolatedForEachMissingData;  // shd not interpolate the actually present data


          // Remove the 1st record, which is of different Date
          energydata.shift();
          // Add at the begining, the interpolated 0th record
          energydata.unshift({
            date: appendedfirstRecord,
            kwh: energyInterpolatedForEachMissingData,
            interpolate: true,
          });
        } else {
          // Remove the 1st record, whose all previous values are null. i.e its a first record in DB
          energydata.shift();
        }
      }

      // check whether last record is same as endDate, if true skip, else calculate inpterpolation

      if (lastRecord.get("month") !== e.get("month")) {
        console.log(
          `True : ${Math.floor(energydata[energydata.length - 1].kwh) !== -1}`
        );
        if (Math.floor(energydata[energydata.length - 1].kwh) !== -1) {
          // we interpolate  last date
          // const appendedLastRecord = moment(
          //   energydata[energydata.length - 1].date
          // ).clone();
          const appendedLastRecord = e.clone();
          const totalMonths = Math.round(
            moment
              .duration(
                moment(lastRecord).diff(
                  moment(energydata[energydata.length - 2].date)
                )
              )
              .asMonths()
          );

          const eachEnergyRecordActual =
            Math.abs(energydata[energydata.length - 1].kwh) / totalMonths;
          // reduce the kwh value that we are not showing before
          const totalHOurBefor24thHour = Math.round(
            moment
              .duration(
                moment(appendedLastRecord).diff(
                  moment(energydata[energydata.length - 2].date)
                )
              )
              .asDays()
          );

          // Remove last record
          energydata.pop();
          // add 24th hour at the end
          energydata.push({
            date: appendedLastRecord.format("YYYY-MM-DDTHH:mm:ss"),
            kwh: totalHOurBefor24thHour * eachEnergyRecordActual,
            interpolate: true,
          });
        } else {
          // Remove last record, whose next records are empty. i.e its a last record in DB
          energydata.pop();
        }
      }

      const first = moment(energydata[0].date);
      const diffMonths =
        Math.round(
          moment
            .duration(
              moment(moment(energydata[energydata.length - 1].date)).diff(
                moment(energydata[0].date)
              )
            )
            .asMonths()
        ) + 1;

      for (let i = 0, j = 0; i < diffMonths; ) {
        const dfInMonths = Math.round(
          moment
            .duration(
              moment(energydata[j].date).diff(moment(energydata[0].date))
            )
            .asMonths()
        );

        if (dfInMonths !== i) {
          console.log(moment(energydata[0].date).get("date"));
          const startDay = moment(energydata[0].date).clone();
          const changedDate = startDay.add(+first.month() + i, "months");
          const tmonths = Math.round(
            moment
              .duration(
                moment(energydata[j].date).diff(moment(energydata[j - 1].date))
              )
              .asMonths()
          );
          const en = tmonths && Math.abs(energydata[j].kwh) / (tmonths - 1);

          singleH0urly.push({
            date: moment(changedDate).format("YYYY-MM-DDTHH:mm:ss"),
            kwh: en,
            isinterpolate: true,
          });

          // replicate data used only for graph to show interpolate, not used for energy calculation
          singleH0urly.push({
            date: moment(changedDate).format("YYYY-MM-DDTHH:mm:ss"),
            kwh: en,
            isinterpolate: false,
          });

          i += 1;

          // Replace the accomodated actual value with interpolation
          if (dfInMonths === i) {
            const startDayNext = moment(energydata[0].date).clone();
            const changeDay = startDayNext.add(+first.month() + i, "months");
            singleH0urly.push({
              date: moment(changeDay).format("YYYY-MM-DDTHH:mm:ss"),
              kwh: energydata[j].kwh, // replace with energydata[j].kwh
              isinterpolate: true,
            });

            // replicate data used only for graph to show interpolate, not used for energy calculation
            singleH0urly.push({
              date: moment(changeDay).format("YYYY-MM-DDTHH:mm:ss"),
              kwh: energydata[j].kwh, // replace with energydata[j].kwh
              isinterpolate: false,
            });
            i += 1;
            j += 1;
          }
        } else {
          // add actual data
          singleH0urly.push({
            date: moment(energydata[j].date)
              .startOf("month")
              .format("YYYY-MM-DDTHH:mm:ss"),
            kwh: energydata[j].kwh,
            isinterpolate: false,
          });
          j += 1;
          i += 1;
        }
      }

      // if last record is interpolate, add this . THis is used only for graph dots
      if (
        lastRecord.get("month") !== e.get("month") &&
        e.get("month") ===
          moment(energydata[energydata.length - 1].date).get("month")
      ) {
        singleH0urly.pop();
        singleH0urly.push({
          date: energydata[energydata.length - 1].date,
          kwh: energydata[energydata.length - 1].kwh,
          isinterpolate: true,
        });
        singleH0urly.push({
          date: energydata[energydata.length - 1].date,
          kwh: energydata[energydata.length - 1].kwh,
          isinterpolate: false,
        });
      }

      // if single value, add a dummy to show a single line bar graph
      if (singleH0urly.length === 1) {
        singleH0urly.push({
          date: singleH0urly[0].date,
          kwh: 0,
          isinterpolate: singleH0urly[0].isinterpolate,
        });
      }
      // eslint-disable-next-line no-param-reassign
      d[z].energydata = singleH0urly;
    }
  }
  return d;
};

const getYearlyData = (d) => {
  // for each site
  for (let z = 0; z < d.length; z += 1) {
    const { energydata } = d[z];
    if (energydata.length !== 0) {
      const completeYearly = [];
      // sort by date
      energydata.sort((a, b) => new Date(a.date) - new Date(b.date));

      const startDate = moment(energydata[0].date);
      startDate.set("month", 0);
      startDate.set("date", 1);

      const durat = moment.duration(
        moment(moment(energydata[energydata.length - 1].date)).diff(startDate)
      );
      const diffDays = Math.round(durat.asYears()) + 1;

      for (let i = 0, j = 0, k = 1; i < diffDays; ) {
        const dura = moment.duration(
          moment(energydata[j].date).diff(moment(energydata[0].date))
        );
        const df = Math.round(dura.asYears());
        if (df !== i) {
          if (i === 0) {
            completeYearly.push({
              date: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
              kwh: 0,
              isinterpolate: true,
            });
          } else {
            let en;
            const changedDate = moment(startDate).add(i, "years");
            if (j === 0) {
              completeYearly.push({
                date: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
                kwh: 0,
                isinterpolate: true,
              });
            } else {
              const duration = moment.duration(
                moment(energydata[j].date).diff(moment(energydata[j - 1].date))
              );
              const years = duration.asYears();
              en =
                years &&
                energydata[j - 1].kwh +
                  ((energydata[j].kwh - energydata[j - 1].kwh) / years) * k;
              completeYearly.push({
                date: moment(changedDate).format("YYYY-MM-DDTHH:mm:ss"),
                kwh: +en.toFixed(2),
                isinterpolate: true,
              });
              k += 1;
            }
            completeYearly.push({
              date: moment(changedDate).format("YYYY-MM-DDTHH:mm:ss"),
              kwh: +en.toFixed(2),
              isinterpolate: false,
            });
          }
          i += 1;
        } else {
          energydata[j].isinterpolate = false;
          completeYearly.push(energydata[j]);
          j += 1;
          i += 1;
          k = 1;
        }
      }
      // if single value, add a dummy to show a single line bar graph
      if (completeYearly.length === 1) {
        completeYearly.push({
          date: completeYearly[0].date,
          kwh: 0,
          isinterpolate: completeYearly[0].isinterpolate,
        });
      }

      // eslint-disable-next-line no-param-reassign
      d[z].energydata = completeYearly;
    }
  }
  return d;
};

export default function GetCompleteData(s, e, data, range) {
  if (range !== undefined) {
    switch (range.Value.Display) {
      case "A Day":
        return getHourlyData(data);

      case "7 days":
        return getDailyDataNew(s, e, data);

      case "31 Days":
        return getDailyDataNew(s, e, data);

      case "3 months":
        return getMonthlyDataNew(s, e, data);

      case "12 months":
        return getMonthlyDataNew(s, e, data);

      case "5 Years":
        return getYearlyData(data);

      default:
        return data;
    }
  }
}
