import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import RepeatIcon from "../Icons/Repeat";
import SceneComfortIcon from "../Icons/SceneComfort";
import SceneMatchIcon from "../Icons/SceneMatch";
import SceneTrainingIcon from "../Icons/SceneTraining";
import TwilightIcon from "../Icons/Twilight";
import SceneHalfFieldLeftIcon from "../Icons/SceneHalfFieldLeft";
import SceneHalfFieldRightIcon from "../Icons/SceneHalfFieldRight";
import { SceneTypes, ControlSceneTypes } from "../constants/ScenesConstants";
import FieldSchedule from "../datamodels/FieldSchedule";
import WarningIcon from "../components/WarningIcon";
import AlertInfo from "../components/AlertInfo";

const CustomEvent = props => {
  // eslint-disable-next-line class-methods-use-this
  const scenes = new Map(
    props.event.customScenes.map(i => [i.perfectPlayScene, i.displayScene])
  );
  const getSceneIcon = scene => {
    let sceneIcon;

    switch (scene) {
      case SceneTypes.COMFORT:
        sceneIcon = (
          <SceneComfortIcon data-value={scene} label={scenes.get(scene)} />
        );
        break;
      case SceneTypes.MATCH:
        sceneIcon = (
          <SceneMatchIcon data-value={scene} label={scenes.get(scene)} />
        );
        break;
      case SceneTypes.TRAINING:
        sceneIcon = (
          <SceneTrainingIcon data-value={scene} label={scenes.get(scene)} />
        );
        break;
      case ControlSceneTypes.HALFFIELDLEFT:
        sceneIcon = (
          <SceneHalfFieldLeftIcon 
            data-value={scene.perfectPlayScene}
            label={scene.perfectPlayScene}
          />
        );
        break;
      case ControlSceneTypes.HALFFIELDRIGHT:
        sceneIcon = (
          <SceneHalfFieldRightIcon 
            data-value={scene.perfectPlayScene}
            label={scene.perfectPlayScene}
          />
        );
        break;
      default:
        sceneIcon = (
          <SceneComfortIcon data-value={scene} label={scenes.get(scene)} />
        );
        break;
    }
    return <div className="eua_scene_dd_icon">{sceneIcon}</div>;
  };
  const recurrenceIcon =
    props.event.schedulingFrequency !== 0 ? (
      <div className="eua_scene_dd_icon">
        <RepeatIcon />
      </div>
    ) : null;

  const twilightIcon = props.event.isTwilight ? (
    <div className="eua_scene_dd_icon">
      <TwilightIcon />
    </div>
  ) : null;

  // const sceneIcon = props.event.scene ? getSceneIcon(props.event.scene) : {};
  const timeInfo = (
    <div className="eua-time-info">{`${moment(props.event.start).format("HH:mm")} - ${moment(
      props.event.end
    ).format("HH:mm")}`}</div>
  );

  const getOverlapInfoIcon = info => {
    let isPartialOverlap = false;
    let isOutOfSchedule = false;
    if(info.event instanceof FieldSchedule){
      
      const currentDate = moment(info.event.start).format("YYYY-MM-DD");
      const currentDaySchedules = info.event.masterScheduleData.filter(item => moment(item.start).format("YYYY-MM-DD") === currentDate );

      if(currentDaySchedules && currentDaySchedules.length > 0){

        const twilightSchedules =[];
        const fixedSchedules = [];

        currentDaySchedules.map(item => {
          if(item.isTwilight){
            twilightSchedules.push(item);
          } else {
            fixedSchedules.push(item);
          }
          return item;
        })


        let prioritySchedules;

        if(twilightSchedules.length > 0 ){
          prioritySchedules = twilightSchedules;
        } else {
          prioritySchedules = fixedSchedules ;
        }

         // check for within master schedules
         let isWithin = false;
         prioritySchedules.map(schedule => {
           if(!isWithin) {
             isWithin = info.event.end <= schedule.end && info.event.start >= schedule.start;
           }
           return isWithin;
         });

         // flag false means Schedule is not within master schedule
         if(!isWithin){
          prioritySchedules.map(schedule => {
            if(!isPartialOverlap) {
              isPartialOverlap = (info.event.end > schedule.start && info.event.end < schedule.end ) || (info.event.start > schedule.start && info.event.start < schedule.end);
            }
            return isPartialOverlap;
          });

           // if event is not within master schedule and not partial schedule then it should be out of master schedule
          isOutOfSchedule = !isPartialOverlap;
         }
      } else {
        // No Master Schedule for the day then we should allow user to create Field Schedules for that day
        isOutOfSchedule = false;
      }
     
    }
    return (<div className="eua_alert_icon">
      {isPartialOverlap && <AlertInfo height="14px"  label="Event partially within the master schedule" color="#DC3205"/>}
      {isOutOfSchedule && <WarningIcon height="14px" label="Event out of the master schedule" color="#FF0019"/>}
    </div>);
  }
  // to convert training-right & training-left scenes to TraningRight & TrainignLeft
  const handleScene = sceneName => {
    if (sceneName.includes('-')) {
      const removeHyphenFromScene = sceneName.replace(/-/g, '');
      const formattedScene = removeHyphenFromScene.charAt(0).toUpperCase() + removeHyphenFromScene.slice(1, 8) + removeHyphenFromScene.charAt(8).toUpperCase() + removeHyphenFromScene.slice(9);
      return formattedScene;
    }
    return sceneName;
  }
  return (   
    <div className="eua-event-container">
      <div className="flexbox">
        {getOverlapInfoIcon(props)}
        {timeInfo}
      </div>
      <div className="flexbox">
        {recurrenceIcon}
        {twilightIcon}
        {props.event.scene && getSceneIcon(handleScene(props.event.scene))}
      </div>
    </div>
  );
};
CustomEvent.propTypes = {
  event: PropTypes.instanceOf(Object)
};

CustomEvent.defaultProps = {
  event: null
};
export default CustomEvent;
