import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(detector)
  .use(reactI18nextModule)
  .init({
    detection: {
      caches: []
    },
    fallbackLng: "en-US",
  });

export default i18n;
