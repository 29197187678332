import React from "react";
import i18next from "i18next";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import * as Labels from "./constants/Labels"

const NetworkDetector = props => {
  const modalbox = {
    position: "absolute",
    top: "30%"
  };
  const Title = {
    fontSize: "18px",
    color: "white",
    backgroundColor: "rgb(47, 45, 45)",
    marginBottom: "5%",
    padding: "1% 0 1% 5%"
  };
  const modalBody = {
    fontSize: "14px",
    color: "black",
    paddingLeft: "5%",
    marginBottom: "5%"
  };
  const body = {
    padding: "0"
  };
  const { isDisconnected } = props;
  return (
    <Modal show={isDisconnected} style={modalbox}>
      <Modal.Body style={body}>
        <div style={Title}> {i18next.t(Labels.INTERNET_LOST)} </div>
        <div style={modalBody}>
          {i18next.t(Labels.NO_INTERNET)}
          <br />
          {i18next.t(Labels.NETWORK_DIALOG_CLOSE)}
        </div>
      </Modal.Body>
    </Modal>
  );
};
NetworkDetector.propTypes = {
  isDisconnected: PropTypes.bool
};
NetworkDetector.defaultProps = {
  isDisconnected: false
};

export default NetworkDetector;
