import MasterSchedule from "../datamodels/MasterSchedule";
import SchedulingUtilities from "../helpers/SchedulingUtilities";

function createMasterScheduleEvent(event) {
  const scheduleEvent = new MasterSchedule(event);

  return scheduleEvent;
}
function updateTwilightMasterScheduleEvent(eventList, e) {
  const event = e;
  if (!event.isTwilight || event.sunrise === null || event.sunset === null)
    eventList.push(event);
  else {
    const sunriseEvent = { ...event};
    sunriseEvent.start = SchedulingUtilities.concatDateTime(
      event.start,
      event.sunRiseFixedTime
    );
    sunriseEvent.end = SchedulingUtilities.computeHoursMins(
      event.sunrise,
      event.offSetTime.sunRiseOffsetHours,
      event.offSetTime.sunRiseOffsetMins,
      event.offSetTime.sunRiseOffsetTimeSign
    );
    sunriseEvent.allDay = false;
    eventList.push(sunriseEvent);
    const sunsetEvent = { ...event};
    sunsetEvent.start = SchedulingUtilities.computeHoursMins(
      event.sunset,
      event.offSetTime.sunSetOffsetHours,
      event.offSetTime.sunSetOffsetMins,
      event.offSetTime.sunSetOffsetTimeSign
    );
    sunsetEvent.end = SchedulingUtilities.concatDateTime(
      event.start,
      event.sunSetFixedTime
    );
    sunsetEvent.allDay = false;
    eventList.push(sunsetEvent);
  }
  return eventList;
}
class MasterScheduleManagement {
  constructor() {
    this.masterScheduleEvents = [];
    this.newMasterEvent = {};
    this.twilightEventList = [];
    this.sunriseSunsetDetailsForSite = [];
  }

  setMasterSchedule(data) {
    this.masterScheduleEvents = data.map(createMasterScheduleEvent);
    this.setTwilightEventList();
    this.setToolTipforSameDayFixedMSwithExistingTwilight();
    this.masterScheduleEvents = this.masterScheduleEvents.reduce(
      updateTwilightMasterScheduleEvent,
      []
    );
  }

  getMasterSchedule() {
    return this.masterScheduleEvents;
  }

  getNewMasterSchedule(data) {
    this.newMasterEvent = createMasterScheduleEvent(data);
    return this.newMasterEvent;
  }

  setTwilightEventList() {
    this.twilightEventList = [];
    this.masterScheduleEvents.forEach(event => {
      if (event.isTwilight) {
        const twilightEvent = {
          start: event.start,
          end: event.end
        };
        this.twilightEventList.push(twilightEvent);
      }
    });
  }

  setToolTipforSameDayFixedMSwithExistingTwilight() {
    this.masterScheduleEvents.map(e => {
      if (e.isTwilight) return e;
      const event = e;
      const eventStartDate =
        event.schedulingFrequency === 0
          ? event.scheduleStartDate
          : event.recurringScheduleStartDate;
      if (
        this.twilightEventList.filter(
          te => te.start.getTime() === new Date(eventStartDate).getTime()
        ).length > 0
      ) {
        event.title = "Twilight based schedules are given higher priority";
      }
      return event;
    });
  }

  getTwilightEventList() {
    return this.twilightEventList;
  }

  setSunriseSunsetDetails(data) {
    this.sunriseSunsetDetailsForSite = data;
    this.masterScheduleEvents = this.masterScheduleEvents.reduce(
      this.updateTwilightMasterScheduleEvent.bind(data),
      []
    );
  }
}

const instance = new MasterScheduleManagement();
export default instance;
