import React from "react";
import PropTypes from "prop-types";

const SiteIcon = props => (
  <svg x="0px" y="0px" viewBox="0 0 85.63 113.65" height="90%">
    <title>{props.tooltip}</title>
    <g>
      <rect
        x="28.458"
        y="0.109"
        fill="currentColor"
        width="28.633"
        height="10.322"
      />
      <rect
        x="28.875"
        y="103.263"
        fill="currentColor"
        width="28.631"
        height="10.322"
      />
      <path
        fill="currentColor"
        d="M42.655,32.476c12.179,0,22.327,8.824,24.603,20.192h18.539c-0.034-12.79,0.029-25.525-0.051-38.108
                              c-0.04-6.674-3.93-12.527-9.852-13.757c-4.039-0.839-3.717-0.557-8.083-0.804l0.033,21.404H17.701c0-2.842,0.04-18.031,0.04-21.261
                              c-3.6,0-1.958-0.203-5.268-0.021c-3.365,0.186-6.44,1.643-8.636,4.094c-2.639,2.948-3.693,7.065-3.668,11.158
                              C0.238,27.69,0.204,39.878,0.214,52.668h17.837C20.327,41.3,30.476,32.476,42.655,32.476"
      />
      <path
        fill="currentColor"
        d="M85.748,61.194H67.344c-2.087,12.789-12.343,20.67-24.689,20.67c-12.345,0-22.602-7.881-24.688-20.67H0.167
                              c0.032,12.789-0.03,25.244,0.049,37.826c0.041,6.673,3.931,12.39,9.853,13.619c4.039,0.839,3.716,0.768,8.083,1.012l-0.034-21.194
                              h50.143c0,2.843-0.038,17.75-0.038,20.981c3.599,0,1.956,0.064,5.267-0.121c3.367-0.188,6.44-1.711,8.636-4.163
                              c2.64-2.948,3.693-6.819,3.669-10.912C85.725,85.927,85.76,73.983,85.748,61.194"
      />
      <path
        fill="currentColor"
        d="M57.181,56.841c0,8.021-6.504,14.525-14.526,14.525c-8.023,0-14.526-6.504-14.526-14.525
                              c0-8.022,6.503-14.527,14.526-14.527C50.677,42.313,57.181,48.818,57.181,56.841"
      />
    </g>
  </svg>
);
SiteIcon.propTypes = {
  tooltip: PropTypes.string
};

SiteIcon.defaultProps = {
  tooltip: null
};
export default SiteIcon;
