import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";
import SchedulingApi from "../api/SchedulingApi";
import ScheduleManagement from "./ScheduleManagement";
import MasterSchedule from "../MasterSchedule/MasterSchedule";
import FieldRow from "../FieldRow";
import GifImageContainer from "../components/GifImageContainer";
import "../css/Schedule.css";
import { ApplicationTypes } from "../constants/AppConstants";
import * as Labels from "../constants/Labels";

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      isLoading: true
    };
    this.fetchFields = this.fetchFields.bind(this);
  }

  componentDidMount() {
    this.fetchFields(this.props.selectedSite.id);
  }

  fetchFields(siteId) {
    const customScenes = SchedulingApi.getScenes(siteId);
    const fieldsForSite = SchedulingApi.getFieldsForSite(siteId);
    const fieldPermissionData = SchedulingApi.getFieldPermssions(
      siteId
    );

    Promise.all([fieldsForSite, customScenes, fieldPermissionData]).then(() => {
      const fields = ScheduleManagement.getFields();
      const scenes = ScheduleManagement.getScenes();
      // fieldPermissionData is used in addscheduleModal class from inmemory model
      const fieldswithCustomScenes = fields.map(field => {
        // eslint-disable-next-line no-param-reassign
        field.customScenes = scenes.fieldLevelCustomScenes.find(
          f => f.fieldId === field.id
        ).customScenes;
        return field;
      });
      this.setState({ fields: fieldswithCustomScenes, isLoading: false});
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="eua-LoadingContainer-Screen flexbox-centered-items content">
          <GifImageContainer alt="Loading" src="/images/loading.gif" />
        </div>
      );
    }
    const defaultField = this.props.selectedField
      ? this.state.fields.find(field => field.id === this.props.selectedField)
      : this.state.fields[0];
    if (this.props.selectedField && !defaultField) {
      return <Redirect to="/NotFound" />;
    }
    return (
      <>
        {this.state.fields.length !== 0 && (
          <ul className="row w-100 eua-schedule-menu">
            <li className="active">
              <NavLink
                activeClassName="eua-selected-navlink-header"
                to={`/Sites/${this.props.selectedSite.id}/Schedule/${defaultField.id}`}
              >
                {i18next.t(Labels.FIELD_SCHEDULE)}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="eua-selected-navlink-header"
                to={`/Sites/${this.props.selectedSite.id}/MasterSchedule`}
              >
                {i18next.t(Labels.MASTER_SCHEDULE)}
              </NavLink>
            </li>
          </ul>
        )}
        <>
          <Switch>
            <Route
              path="/Sites/:siteId/Schedule/:fieldId"
              render={() => (
                <FieldRow
                  key={this.props.selectedSite.id}
                  comp={ApplicationTypes.Schedule}
                  fields={this.state.fields}
                  defaultField={defaultField}
                  selectedSite={this.props.selectedSite}
                />
              )}
            />
            <Route
              path="/Sites/:siteId/MasterSchedule"
              render={() => (
                <MasterSchedule
                  key={this.props.selectedSite.id}
                  fields={this.state.fields}
                  selectedSite={this.props.selectedSite}
                />
              )}
            />
            {this.props.App === ApplicationTypes.MasterSchedule ? (
              <Redirect
                to={`/Sites/${this.props.selectedSite.id}/MasterSchedule`}
              />
            ) : (
              <Redirect
                to={`/Sites/${this.props.selectedSite.id}/Schedule/${defaultField.id}`}
              />
            )}
          </Switch>
        </>
      </>
    );
  }
}
Schedule.propTypes = {
  selectedSite: PropTypes.instanceOf(Object),
  selectedField: PropTypes.string,
  App: PropTypes.string
};

Schedule.defaultProps = {
  selectedSite: {},
  selectedField: null,
  App: ""
};
export default Schedule;
