import React from "react";

const Repeat = () => {
  const svgStyle = {
    fill: "#3c3c41"
  };
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ enablebBackground: "new 0 0 24 24" }}
    >
      <g>
        <path
          style={svgStyle}
          d="M23,6l-4.3,3V7H7.6C6.4,7,5.3,7.8,4.9,8.9l-1,2.5C3.7,11.8,3.4,12,3,12c-0.1,0-0.2,0-0.4-0.1s-0.4-0.3-0.5-0.5
		c-0.1-0.2-0.1-0.5,0-0.8l1-2.5C3.8,6.2,5.6,5,7.6,5h11.1V3L23,6z M22.4,12.1c0.2,0.1,0.4,0.3,0.5,0.5s0.1,0.5,0,0.8l-1,2.5
		c-0.7,1.9-2.5,3.1-4.5,3.1H6.3v2L2,18l4.3-3v2h11.1c1.2,0,2.3-0.8,2.7-1.9l1-2.5c0.1-0.2,0.3-0.4,0.5-0.5
		C21.9,12,22.1,12,22.4,12.1z"
        />
      </g>
    </svg>
  );
};

export default Repeat;
