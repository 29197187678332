import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Logo from "../Icons/Logo";
import TouContent from "./TOUContent";
import * as AppLabels from "../constants/AppLabels"

const AcceptLicense = props => {
  const footerButtons = (
    <div className="flexbox-column-end-item">
      <button
        type="button"
        className="AcceptLicense-Accept-button"
        onClick={props.handleAcceptClick}
      >
        {AppLabels.ACCEPT}
      </button>
    </div>
  );

  return (
    <Modal show={props.show} container={this} bsSize="small">
      <Modal.Body bsClass="accept-body">
        <div className="row ml-0 pb-3 pt-4 license-header">
          <div className="AcceptLicense-icon-container">
            <Logo color="#FF375F" />
          </div>
          <span className="AcceptLicense-sports-text">{AppLabels.SPORTS}</span>
          <p className="license-text pt-3 pb-1">{AppLabels.LICENSE_AGREEMENT}</p>
        </div>
        <div className="termsOfUseContainer">
          <TouContent />
        </div>
      </Modal.Body>
      <Modal.Footer>{footerButtons}</Modal.Footer>
    </Modal>
  );
};

AcceptLicense.propTypes = {
  handleAcceptClick: PropTypes.instanceOf(Function),
  show: PropTypes.bool
};

AcceptLicense.defaultProps = {
  handleAcceptClick: null,
  show: false
};

export default AcceptLicense;
