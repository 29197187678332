import React from 'react';
import PropTypes from "prop-types";

const  WarningIcon = props => {
  const cls1 = {
    fill: props.color,
    fillRule: "evenodd"
  }
    return (
      <svg height={props.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>{props.label}</title>
        <g id="icons">
          <g id="action_and_navigation_icons_-_heart" data-name="action and navigation icons - heart">
            <path style={cls1} d="M14.88,12.52l-.05-.08L8.88,1.52h0A1,1,0,0,0,8,1a1,1,0,0,0-.92.61h0L1.17,12.44l0,.08A1,1,0,0,0,1,13a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1A1,1,0,0,0,14.88,12.52ZM9,12H7V10H9ZM9,8.5H7V4H9Z"/>
          </g>
        </g>
        </svg>
    );
}

WarningIcon.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string
};

WarningIcon.defaultProps = {
  label: "",
  color: "#FF0019",
  height: "14px"
};

export default WarningIcon;
