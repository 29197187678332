import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import SceneComfortIcon from "../Icons/SceneComfort";
import SceneMatchIcon from "../Icons/SceneMatch";
import SceneTrainingIcon from "../Icons/SceneTraining";
import SceneHalfFieldLeftIcon from "../Icons/SceneHalfFieldLeft";
import SceneHalfFieldRightIcon from "../Icons/SceneHalfFieldRight";
import { SceneTypes, ControlSceneTypes } from "../constants/ScenesConstants";
import ScheduleManagement from "../Schedule/ScheduleManagement";
import "../css/Schedule.css";
import * as Labels from "../constants/Labels";

const dashboardButton = {
  cursor: "pointer"
};
class ScenesDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.getSceneIcon = this.getSceneIcon.bind(this);
    this.handleSceneTranslate = this.handleSceneTranslate.bind(this);
    this.handleScene = this.handleScene.bind(this);
    this.generateRandomNumber = this.generateRandomNumber.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getSceneIcon(scene) {
    switch (scene.perfectPlayScene) {
      case SceneTypes.COMFORT:
        return (
          <SceneComfortIcon
            data-value={scene.perfectPlayScene}
            label={scene.displayScene}
          />
        );
      case SceneTypes.MATCH:
        return (
          <SceneMatchIcon
            data-value={scene.perfectPlayScene}
            label={scene.displayScene}
          />
        );
      case SceneTypes.TRAINING:
        return (
          <SceneTrainingIcon
            data-value={scene.perfectPlayScene}
            label={scene.displayScene}
          />
        );
      case ControlSceneTypes.HALFFIELDLEFT:
        return(
          <SceneHalfFieldLeftIcon 
            data-value={scene.perfectPlayScene}
            label={scene.perfectPlayScene}
          />
        );
      case ControlSceneTypes.HALFFIELDRIGHT:
        return(
          <SceneHalfFieldRightIcon 
            data-value={scene.perfectPlayScene}
            label={scene.perfectPlayScene}
          />
        );
      default:
        return (
          <SceneComfortIcon
            data-value={scene.perfectPlayScene}
            label={scene.displayScene}
          />
        );
    }
  }

  // if comfort/training/match scene has any custom scene name will return the scene as it is or else returns actual scene name to be displayed
  handleSceneTranslate = scene => {
    const result = Labels.SceneTypeForTranslation.filter(
      st => st.Key === scene
    )[0];
    const sceneName = result === undefined ? scene : result.Value;
    return sceneName;
  };

  // to convert training-right & training-left scenes to TraningRight & TrainignLeft
  handleScene = sceneName => {
    if (sceneName.includes('-')) {
      const removeHyphenFromScene = sceneName.replace(/-/g, '');
      const formattedScene = removeHyphenFromScene.charAt(0).toUpperCase() + removeHyphenFromScene.slice(1, 8) + removeHyphenFromScene.charAt(8).toUpperCase() + removeHyphenFromScene.slice(9);
      return formattedScene;
    }
    return sceneName;
  }

  generateRandomNumber = () => {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    return array[0];
  }

  render() {
    const sceneList = [];
    const field = ScheduleManagement.getFields().find(reqField => reqField.id === this.props.fieldId);
    const dropdownScenes = this.props.customScenes.filter(scene => {
      if (Object.values(SceneTypes).includes(scene.perfectPlayScene))
        return scene;
      // training left & right scenes are enabled only if half-field control is enabled and both comfort, training scenes are enabled for a particular field
      if ((scene.perfectPlayScene === ControlSceneTypes.HALFFIELDLEFT || scene.perfectPlayScene === ControlSceneTypes.HALFFIELDRIGHT) && field.halfFieldControl && (this.props.scenePermissions.get(ControlSceneTypes.COMFORT) && this.props.scenePermissions.get(ControlSceneTypes.TRAINING)))
        return scene;
      return null;
    });
    dropdownScenes.forEach(scene => {
      const sceneIcon = this.getSceneIcon(scene);
      sceneList.push(
        <li
          className={this.props.scenePermissions.get(scene.perfectPlayScene) ?"dropdown-item eua-time-select-li p-0 pl-3 pb-3" : "disabled dropdown-item eua-time-select-li p-0 pl-3 pb-3"}
          style={dashboardButton}
          key={`${this.generateRandomNumber()}${scene.perfectPlayScene}`}
          role="option"
          aria-selected="false"
          data-toggle="tooltip"
          data-placement="right"
          title={scene.displayScene}
          data-value={scene.perfectPlayScene}
          onClick={event =>
            this.props.onSelectChange(event.target.getAttribute("data-value"))
          }
          onKeyPress={event =>
            this.props.onSelectChange(event.target.getAttribute("data-value"))
          }
        >
          <div
            data-value={scene.perfectPlayScene}
            className="link-button "
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start"
            }}
          >
            <div
              data-value={scene.perfectPlayScene}
              className="eua_scene_dd_icon"
            >
              {sceneIcon}
            </div>
            <span
              data-value={scene.perfectPlayScene}
              className="col-9 p-0"
              style={{
                margin: "0 10px ",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {i18next.t(this.handleSceneTranslate(scene.displayScene))}
            </span>
          </div>
        </li>
      );
    });
    const scenes = new Map(
      dropdownScenes.map(i => [i.perfectPlayScene, i.displayScene])
    );
    
    if (scenes.has(ControlSceneTypes.HALFFIELDLEFT) || (this.props.selectedScene === ControlSceneTypes.HALFFIELDLEFT || this.props.selectedScene === "training-left")) {
      scenes.set('training-left', ControlSceneTypes.HALFFIELDLEFT);
    }
    if (scenes.has(ControlSceneTypes.HALFFIELDRIGHT) || (this.props.selectedScene === ControlSceneTypes.HALFFIELDRIGHT || this.props.selectedScene === "training-right")) {
      scenes.set('training-right', ControlSceneTypes.HALFFIELDRIGHT);
    }

    return (
      // <div className="eua_site_dd_container">
      <div className="dropdown dropdown-background">
        <button
          className="btn btn-secondary dropdown-toggle eua-time-select-btn"
          type="button"
          id="dropdownMenuButton4"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className={ this.props.scenePermissions.get(this.handleScene(this.props.selectedScene)) ?"scene-dropdown-current" : "scene-dropdown-current-disabled "}>
            <span className="eua_scene_dd_icon">
              {this.getSceneIcon(this.props.customScenes.find(scene => scene.perfectPlayScene === this.handleScene(this.props.selectedScene)))}
            </span>
            <span className="scene-dropdown-current-name">
               {i18next.t(this.handleSceneTranslate(scenes.get(this.props.selectedScene)))}
            </span>
          </div>
        </button>
        <ul
          className="dropdown-menu eua-time-select-ul"
          aria-labelledby="dropdownMenuButton4"
        >
          {sceneList}
        </ul>
      </div>
      // </div>
    );
  }
}
ScenesDropdown.propTypes = {
  selectedScene: PropTypes.string,
  onSelectChange: PropTypes.func.isRequired,
  customScenes: PropTypes.instanceOf(Array),
  scenePermissions: PropTypes.instanceOf(Map),
  fieldId: PropTypes.string.isRequired
};
ScenesDropdown.defaultProps = {
  selectedScene: null,
  customScenes: null,
  scenePermissions: null
};
export default ScenesDropdown;
