import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import "./css/fieldrow.css";
import FieldSchedule from "./FieldSchedule/FieldSchedule";
import ScheduleManagement from "./Schedule/ScheduleManagement";
import SiteIcon from "./Icons/SiteIcon";
// import { PitchTypesLables } from "./constants/PitchTypeConstants";
import FieldControl from "./Control/FieldControl";
import SchedulingApi from "./api/SchedulingApi";
import { scenePolling } from "./constants/PollingConstants";
import GifImageContainer from "./components/GifImageContainer";
import { ApplicationTypes } from "./constants/AppConstants";
import { ControlSceneTypes } from "./constants/ScenesConstants";
import * as Labels from "./constants/Labels";
import LanguageSelector from "./Language/LanguageSelector";

const fieldHeaderStyle = {
  fontWeight: "bold",
  marginLeft: "15px"
};

class FieldRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.comp !== ApplicationTypes.Schedule,
      fieldStates: new Map(),
      fieldPermissions: [],
      isTurnOffButtonDisabled: false
    };

    this.handleSwitchoff = this.handleSwitchoff.bind(this);
    this.handleSceneChange = this.handleSceneChange.bind(this);
    this.fetchFieldStates = this.fetchFieldStates.bind(this);
    this.compareTwoSceneStates = this.compareTwoSceneStates.bind(this);
    this.handleTurnOffButtonState = this.handleTurnOffButtonState.bind(this);
    this.handleSceneTranslate = this.handleSceneTranslate.bind(this);
  }

  componentDidMount() {
    if (this.props.comp !== ApplicationTypes.Schedule) {
      const fieldStateData = SchedulingApi.getStateForSite(
        this.props.selectedSite.id
      );
      const fieldPermissionData = SchedulingApi.getFieldPermssions(
        this.props.selectedSite.id
      );

      Promise.all([fieldStateData, fieldPermissionData]).then(() => {
        const states = ScheduleManagement.getStates();
        const permissions = ScheduleManagement.getFieldPermissions();
        this.timer = setInterval(
          () => this.fetchFieldStates(this.props.selectedSite.id),
          scenePolling
        );
        this.setState({
          isLoading: false,
          fieldStates: states,
          fieldPermissions: permissions
        });
      });
    }
  }

  componentWillUnmount() {
    if (this.props.comp !== ApplicationTypes.Schedule) {
      clearInterval(this.timer);
    }
  }

  handleTurnOffButtonState(scene) {
    const { fields } = this.props;
    if (this.props.comp !== ApplicationTypes.Schedule) {
      if (fields.length !== 0) {
        let isMatch = false;
        fields.forEach(field => {
          // field.state = field.customScenes.find(
          //   item => item.sceneId === currentState
          // ).displayScene;
          const fieldState = field.customScenes.find( // Assignment to property of function parameter 'field'  no-param-reassign
            item => item.sceneId === scene.sceneId
          ).displayScene;

          if (fieldState === ControlSceneTypes.MATCH) {
            isMatch = true;
          }
        });

        if (!isMatch) {
          this.setState({ isTurnOffButtonDisabled: false });
        } else {
          this.setState({ isTurnOffButtonDisabled: true });
        }
      }
    }
  }

  handleSceneChange(scene, fieldId) {
    SchedulingApi.setStateForField(fieldId, scene.perfectPlayScene, this.props.selectedSite.id)
      .then(() => {
        const previousStates = new Map(this.state.fieldStates);
        previousStates.delete(fieldId);
        previousStates.set(fieldId, scene.sceneId);
        this.setState({
          fieldStates: previousStates
        });

        this.handleTurnOffButtonState();
      })
      .catch(() => {
        this.setState({
          isLoading: false
        });
      });
  }

  compareTwoSceneStates = (previousState, newState) => {
    if (previousState.size !== newState.size) {
      return false;
    }
    /* eslint-disable */

    for (const [key, value] of previousState.entries()) {
      const testVal = newState.get(key);
      if (testVal !== value || (testVal === undefined && !newState.has(key))) {
        return false;
      }
    }

    return true;
  };

  handleSwitchoff = () => {
    SchedulingApi.switchOffSite(this.props.selectedSite.id)
      .then(() => {
        this.fetchFieldStates(this.props.selectedSite.id);
      })
      .catch(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  handleSceneTranslate = scene => {
    const result = Labels.SceneTypeForTranslation.filter(
      st => st.Key === scene
    )[0];
    const sceneName = result === undefined ? scene : i18next.t(result.Value);
    return sceneName;
  };

    /* eslint-enable */
    fetchFieldStates(siteId) {
      if (!ScheduleManagement.getIsNetworkDisconnected()) {
        SchedulingApi.getStateForSite(siteId)
          .then(() => {
            const states = ScheduleManagement.getStates();
            if (!this.compareTwoSceneStates(states, this.state.fieldStates)) {
              this.setState({
                fieldStates: states
              });
            }
          })
          .catch(() => {
            this.setState({
              isLoading: false
            });
          });
      }
    }

  render() {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.selectedSite.id));
    let isSiteInMatchModeOnLoading = false;
    if (this.state.isLoading) {
      return (
        <div className="mx-auto my-auto d-block">
          <GifImageContainer alt="Loading" src="/images/loading.gif" />
        </div>
      );
    }
    const { fields } = this.props;
    if (fields.length === 0) {
      return (
        <div className="no-fields">
          <p>{i18next.t(Labels.NO_FIELDS)}</p>
        </div>
      );
    }
    if (this.props.comp !== ApplicationTypes.Schedule) {
      fields.forEach(field => {
        const currentState = this.state.fieldStates.get(field.id);
        // eslint-disable-next-line no-param-reassign
        field.state = field.customScenes.find(
          item => item.sceneId === currentState
        ).displayScene;

        if (field.state === ControlSceneTypes.MATCH) {
          isSiteInMatchModeOnLoading = true;
        }
        const fieldPermissions = this.state.fieldPermissions.find(
          item => item.id === field.id
        );
        field.scenePermissions = fieldPermissions.buttons; // eslint-disable-line no-param-reassign
      });
    }

    const sites = ScheduleManagement.getSites();
    const fieldTypeList = [];
    const resultArr = fields.reduce(
      (result, item) => ({
        ...result,
        [item.pitchType !== "" ? item.pitchType : "general"]: [
          ...(result[item.pitchType] || []),
          ...item
        ]
      }),
      {}
    );

    Object.keys(resultArr).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(resultArr, key)) {
        let pitchType;
        const detectedFields = resultArr[key].map(item => {
          // }

          pitchType = (
            <div className="row eua-field-type-name">
              {
                i18next.t(
                  Labels.PitchTypeForTranslation.filter(
                    pt => pt.Key === item.pitchType.toUpperCase()
                  )[0].Value
                )}
            </div>
          );
          return (
            <li
              className={
                this.props.comp === ApplicationTypes.Schedule
                  ? "list-group-item"
                  : "eua-field-details pl-2"
              }
              key={item.id}
            >
              <NavLink
                activeClassName="col eua-selected-navlink-sidebar"
                to={`/Sites/${this.props.selectedSite.id}/${this.props.comp}/${item.id}`}
              >
                <div className="col eua-field-name">{item.name}</div>
                {this.props.comp !== ApplicationTypes.Schedule && (
                  <div className="col eua-field-state">
                    {item.state !== ControlSceneTypes.OFF
                      ? this.handleSceneTranslate(item.state)
                      : i18next.t(Labels.NO_SCENE_ACTIVE)}
                  </div>
                )}
              </NavLink>
            </li>
          );
        });

        fieldTypeList.push(
          <div className="pt-3 pl-3" key={key}>
            {pitchType}
            {detectedFields}
          </div>
        );
      }
    });

    return (
      <div className="row w-100 row-margin h-100">
        <div
          className={
            this.props.comp === ApplicationTypes.Schedule
              ? "mt-3"
              : "col-3 eua-field-control-selection-container pr-0 pl-0"
          }
        >
          {sites.length === 1 && (
            <div className="eua-schedule-header-icon pl-3 pt-2">
              <SiteIcon tooltip={this.props.selectedSite.name} />
            </div>
          )}
          {this.props.comp === ApplicationTypes.Schedule && (
            <h2 style={fieldHeaderStyle}>{i18next.t(Labels.FIELD_SCHEDULE)}</h2>
          )}
          {fieldTypeList}
          {this.props.comp !== ApplicationTypes.Schedule && (
            <div className="row justify-content-center row-margin">
              <button
                type="button"
                className="col-10 btn btn-dark eua-field-off-button"
                onClick={this.handleSwitchoff}
                disabled={
                  isSiteInMatchModeOnLoading
                    ? true
                    : this.state.isTurnOffButtonDisabled
                }
              >
                {i18next.t(Labels.TURN_OFF_ALL_LIGHTING)}
              </button>
            </div>
          )}
        </div>

        <Switch>
          <Route
            path="/Sites/:siteId/Schedule/:fieldId"
            render={props => (
              <FieldSchedule
                key={props.match.params.fieldId}
                fields={fields}
                {...props}
              />
            )}
          />
          <Route
            path="/Sites/:siteId/Control/:fieldId"
            render={props => (
              <FieldControl
                key={props.match.params.fieldId}
                handleSceneChange={this.handleSceneChange}
                fields={fields}
                {...props}
              />
            )}
          />
          <Redirect
            to={`/Sites/${this.props.selectedSite.id}/${this.props.comp}/${this.props.defaultField.id}`}
          />
        </Switch>
      </div>
    );
  }
}

FieldRow.propTypes = {
  fields: PropTypes.instanceOf(Array),
  comp: PropTypes.string,
  defaultField: PropTypes.instanceOf(Object),
  selectedSite: PropTypes.instanceOf(Object)
};
FieldRow.defaultProps = {
  fields: [],
  comp: "",
  defaultField: {},
  selectedSite: {}
};
export default FieldRow;
