import React from "react";
import i18next from "i18next";
import Modal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';
import Grid from 'react-bootstrap/lib/Grid';
import { ModalHeader } from "react-bootstrap";
import GifImageContainer from "../components/GifImageContainer";
import LanguageManagement from "./LanguageManagement";
import LanguageApi from '../api/LanguageApi';
import LanguageMapper from "./LanguageMapper";
import * as Labels from "../constants/Labels";
import "../css/Language.css";

class LanguageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      languageNames: [],
      languageCodes: [],
      userLanguage: ''
    };
    this.fetchLanguages = this.fetchLanguages.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.fetchLanguages();
  }

  handleChange(e) {
    this.setState({
      userLanguage: e.target.value
    });
  }

  onSave() {
    this.props.onSave(this.state.userLanguage);
  }

  fetchLanguages() {
    LanguageApi.getLanguages()
      .then(() => {
        this.setState({
          languageCodes: LanguageManagement.getLanguageCodes(),
          languageNames: LanguageManagement.getLanguageNames(),
          userLanguage: LanguageManagement.getUserLanguage(),
          isLoading: false,
        });
      })
  }

  render() {
    let modalBody = '';
    if (this.state.isLoading) {
      modalBody = (
        <div className="loader">
          <GifImageContainer
            alt="Loading"
            src="/images/loading.gif"
          />
        </div>
      );
    }

    else {
      modalBody = (
        <LanguageMapper
          languageNames={this.state.languageNames}
          languageCodes={this.state.languageCodes}
          userLanguage={this.state.userLanguage}
          handleChange={this.handleChange}
        />
      );
    }
    
    return (
      <Grid id="languageContainer" fluid>
        <div>
          <Modal
            container={this}
            show={this.props.show}
            onHide={this.props.handleClose}
            id="language-prefernce-container"
          >
            <ModalHeader>
              <Modal.Title className="preferences">
                {i18next.t(Labels.PREFERENCES)}
              </Modal.Title>
              <div>
                <div role="button" onKeyPress={this.props.handleClose} onClick={this.props.handleClose} tabIndex={0} className="closeButton">&times;</div>
              </div>
            </ModalHeader>
            <Modal.Body>
              <div id="languageSettingsForm">
                {modalBody}
                <button
                  type="button"
                  className="doneButton"
                  onClick={this.onSave}
                >
                  {i18next.t(Labels.SAVE_BUTTON)}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Grid>
    );
  }
}

LanguageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default LanguageModal;
