import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import SchedulingApi from "./MasterSchedulingApi";
import ScheduleManagement from "./MasterScheduleManagement";
import AddMasterScheduleModal from "./AddMasterScheduleModal";
import GifImageContainer from "../components/GifImageContainer";
import "../css/Schedule.css";
import CalendarToolBar from "../calendar/CalendarToolbar";
import CustomEvent from "../calendar/CustomEvent";
import * as Labels from "../constants/Labels";
import LanguageSelector from "../Language/LanguageSelector";
import EditMasterScheduleModal from "./EditMasterScheduleModal";
import MasterScheduleModal from "../datamodels/MasterSchedule";
import {
  MasterScheduleTypes
} from "../constants/ScheduleTypeConstants";
import EditedScheduleManagement from "../FieldSchedule/EditedScheduleManagement";

moment.locale('en-GB');
const localizer = momentLocalizer(moment);

class MasterSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      showAddMasterScheduleModal: false,
      showEditMasterScheduleModal: false,
      isLoading: true,
      startOfMonth: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      endOfMonth: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      isEditMode: false
    };
    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleAddMasterScheduleButtonClick = this.handleAddMasterScheduleButtonClick.bind(
      this
    );
    this.close = this.close.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.eventStyleGetter = this.eventStyleGetter.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.updateTimeIndicator = this.updateTimeIndicator.bind(this);
    this.handleSlotPropGetter = this.handleSlotPropGetter.bind(this);
  }

  componentDidMount() {
    this.getSchedule(this.state.startOfMonth, this.state.endOfMonth);
  }

  handleSlotPropGetter() {
    this.updateTimeIndicator("week");
  }

  handleNavigate(date, view) {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.selectedSite.id));
    this.updateTimeIndicator("week");
    localStorage.setItem("CalendarLocale", LanguageSelector.SelectLanguage(this.props.selectedSite.id));
    if (view === "day") {
      if (
        moment(date).format("YYYY-MM-DD") >= this.state.startOfMonth &&
        moment(date).format("YYYY-MM-DD") <= this.state.endOfMonth
      )
        return;
    }
    if (view === "week") {
      const startOfWeek = moment(date)
        .startOf("week")
        .format("YYYY-MM-DD");
      const endOfWeek = moment(date)
        .endOf("week")
        .format("YYYY-MM-DD");
      if (
        this.state.startOfMonth <= startOfWeek &&
        this.state.endOfMonth >= endOfWeek
      )
        return;
    }

    const startOfMonth = moment(date)
      .startOf("month")
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(date)
      .endOf("month")
      .add(7, "d")
      .format("YYYY-MM-DD");
    this.getSchedule(startOfMonth, endOfMonth);
  }

  handleViewChange(view) {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.selectedSite.id));
    localStorage.setItem("CalendarLocale", LanguageSelector.SelectLanguage(this.props.selectedSite.id));
    if (view === "month") {
      const monthDiff = moment(this.state.endOfMonth).diff(
        moment(this.state.startOfMonth),
        "months",
        true
      );
      if (monthDiff < 1) {
        const startOfMonth = moment(this.state.startOfMonth)
          .startOf("month")
          .subtract(7, "d")
          .format("YYYY-MM-DD");
        const endOfMonth = moment(this.state.endOfMonth)
          .endOf("month")
          .add(7, "d")
          .format("YYYY-MM-DD");
        this.getSchedule(startOfMonth, endOfMonth);
      }
    }
  }

  handleAddMasterScheduleButtonClick() {
    const newEvent = new MasterScheduleModal({
      start: new Date(),
      end: new Date(),
      schedulingFrequency: 0,
      parentSchedulingFrequency: 0,
      masterScheduleType: MasterScheduleTypes[0].Key
    });
    this.setState({
      // add newMasterSchedule here
      isEditMode: false,
      showAddMasterScheduleModal: true,
      showEditMasterScheduleModal: false,
      selectedEvent: newEvent
    });
  }

  handleEventClick(event) {
    const daysOfWeek = [];
    const schedules = ScheduleManagement.getMasterSchedule();
    schedules.forEach(schedule => {
      if (
        schedule.parentSchedulingFrequency !== 0 &&
        schedule.parentSchedulingFrequency !== 1
      ) {
        const eventScheduleId =
          event.parentScheduleId === null
            ? event.scheduleId
            : event.parentScheduleId;
        const scheduleIdToCompare =
          schedule.parentScheduleId === null
            ? schedule.scheduleId
            : schedule.parentScheduleId;
        if (eventScheduleId === scheduleIdToCompare) {
          if (!daysOfWeek.includes(schedule.dayOfWeek)) {
            daysOfWeek.push(schedule.dayOfWeek);
          }
        }
      }
    });
    if (!event.isEditable) return;
    this.setState({
      selectedEvent: { ...event, daysOfWeek },
      showAddMasterScheduleModal: false,
      isEditMode: true,
      showEditMasterScheduleModal: true
    });
  }
  
  handleSave() {
    this.setState({
      showAddMasterScheduleModal: false,
      isLoading: false,
      showEditMasterScheduleModal: false
    });
    const startOfMonth = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment()
      .endOf("month")
      .format("YYYY-MM-DD");
    this.getSchedule(startOfMonth, endOfMonth);
  }

  getSchedule(startOfMonth, endOfMonth) {
    SchedulingApi.getMasterScheduleForSite(
      this.props.selectedSite.id,
      startOfMonth,
      endOfMonth
    )
      .then(() => {
        const data = ScheduleManagement.getMasterSchedule();
        data.forEach(item => {
          item.customScenes = this.props.fields[0].customScenes; // eslint-disable-line no-param-reassign
        });
        this.setState({
          events: data,
          isLoading: false,
          selectedEvent: undefined,
          startOfMonth,
          endOfMonth,
          twilightEventList: ScheduleManagement.getTwilightEventList()
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  updateTimeIndicator = view => {
    const timeIndicator = document.querySelector('.rbc-current-time-indicator');
    if (timeIndicator) {
      const nDayOfWeek = new Date().getDay();
      let left;
      let width;

      if (view === "day") {
        left = 0;
        width = 100;
      } if (view === "week") {
        left = (nDayOfWeek - 1) * -100;
        width = 700;
      }

      timeIndicator.style.setProperty('--width', `${width}%`);
      timeIndicator.style.setProperty('--left', `${left}%`);
    }
  };

  eventStyleGetter(event) {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.selectedSite.id));
    this.updateTimeIndicator("week");
    const events = EditedScheduleManagement.getEditedSchedulesList();
    const editedEventStyle = "edited-event";
    const twilightRestrictionStyle = {
      border: events.includes(event.parentScheduleId) ? "1px solid #FF37BE" : "2px solid #3e5ed7",
      backgroundColor: "#ebebec !important"
    };
    if (event.isTwilight)
      return {
        className: events.includes(event.parentScheduleId) ? `${editedEventStyle}` : "",
        style: {
          backgroundColor: `#${event.hexColor}`,
          border: events.includes(event.parentScheduleId) ? "1px solid #FF37BE" : "2px solid #3e5ed7",
          borderRadius: "2px"
        }
      };
    const eventStartDate =
      event.schedulingFrequency === 0
        ? event.scheduleStartDate
        : event.recurringScheduleStartDate;
    if (
      this.state.twilightEventList.filter(
        e => e.start.getTime() === new Date(eventStartDate).getTime()
      ).length > 0
    ) {
      return {
        className: events.includes(event.parentScheduleId) ? `${editedEventStyle}` : "",
        style: twilightRestrictionStyle
      };
    }
    return {
      className: events.includes(event.parentScheduleId) ? `${editedEventStyle}` : "",
      style: {
        backgroundColor: "#3174AD",
        border: events.includes(event.parentScheduleId) ? "1px solid #FF37BE" : "2px solid #3e5ed7",
          borderRadius: "2px"
      }
    };
  }

  close() {
    this.setState({
      showAddMasterScheduleModal: false,
      showEditMasterScheduleModal: false
    });
  }

  render() {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.selectedSite.id));
    if (this.state.isLoading) {
      return (
        <div className="mx-auto">
          <GifImageContainer alt="Loading" src="/images/loading.gif" />
        </div>
      );
    }
    let modal = "";
    if (this.state.showAddMasterScheduleModal) {
      modal = (
        <AddMasterScheduleModal
          show={this.state.showAddMasterScheduleModal}
          close={this.close}
          siteId={this.props.selectedSite.id}
          onSave={this.handleSave}
          schedule={this.state.selectedEvent}
          isEditMode={this.state.isEditMode}
          isSeries={this.state.showEditMasterScheduleModal}
          isCreate={this.state.showAddMasterScheduleModal}
        />
      );
    }
    if (this.state.showEditMasterScheduleModal) {
      modal = (
        <EditMasterScheduleModal
          show={this.state.showEditMasterScheduleModal}
          close={this.close}
          siteId={this.props.selectedSite.id}
          save={this.handleSave}
          event={this.state.selectedEvent}
          isEditMode={this.state.isEditMode}
        />
      );
    }
    const isSelectable = true;
    //  let displayContent;
    localStorage.setItem("CalendarLocale", LanguageSelector.SelectLanguage(this.props.selectedSite.id));
    return (
      <div className="col eua-calendar h-100">
        <div style={{}}>
          <button
            id="addMasterScheduleButton"
            onClick={this.handleAddMasterScheduleButtonClick}
            onMouseDown={event => event.preventDefault()}
            data-toggle="modal"
            data-target="#scheduleModal"
            type="button"
            className="btn eua-btn eua-interact-btn"
            style={{ position: "absolute", right: "2%" }}
          >
            {i18next.t(Labels.MASTERSCHEDULE_NEW_RULE)}
          </button>
        </div>
        <div>
          <Calendar
            localizer={localizer}
            culture={LanguageSelector.SelectLanguage(this.props.selectedSite.id)}
            defaultDate={new Date()}
            defaultView="week"
            events={this.state.events}
            selectable={isSelectable}
            onNavigate={this.handleNavigate}
            onSelectEvent={this.handleEventClick}
            onSelecting={() => true}
            eventPropGetter={this.eventStyleGetter}
            scrollToTime={new Date()}
            onView={view => this.handleViewChange(view)}
            components={{
              toolbar: CalendarToolBar,
              event: CustomEvent
            }}
            views={["week", "month"]}
            step={60}
            timeslots={1}
            slotPropGetter={this.handleSlotPropGetter}
          />
        </div>
        {modal}
      </div>
    );
  }
}
MasterSchedule.propTypes = {
  selectedSite: PropTypes.instanceOf(Object),
  fields: PropTypes.instanceOf(Array)
};

MasterSchedule.defaultProps = {
  selectedSite: {},
  fields: []
};
export default MasterSchedule;