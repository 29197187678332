import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import IntervalGenerator from "../helpers/IntervalGenerator";
// import DownArrow from "../Icons/DownArrowPointer";
function concatDateTime(date, time) {
  const newDate = moment(date)
    .format("YYYY-MM-DD")
    .toString();
  return `${newDate} ${time}`;
}

class CustomDropdown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.itemRefs = {};
  }

  checkDisableForFieldSchedule(item) {
    let returnVal = false;
    if (
      moment(concatDateTime(this.props.startTime, item)).isBefore(new Date())
    ) {
      returnVal = true;
    } else {
      const currentDaySchedules = this.props.masterData.filter(
        currentItem =>
          moment(currentItem.start).format("YYYY-MM-DD") ===
          moment(this.props.startTime).format("YYYY-MM-DD")
      );
      if (currentDaySchedules.length > 0) {
        const twilightSchedules = [];
        const fixedSchedules = [];

        currentDaySchedules.map(scheduleItem => {
          if (scheduleItem.isTwilight) {
            twilightSchedules.push(scheduleItem);
          } else {
            fixedSchedules.push(scheduleItem);
          }
          return scheduleItem;
        });

        const currentDateData = new Date(
          moment(concatDateTime(this.props.startTime, item))
        );

        if (twilightSchedules.length > 0) {
          twilightSchedules.map(schedule => {
            if (
              schedule.sunRiseFixedTime ===
              moment(schedule.start)
                .format("hh:mm:ss")
                .toString()
            ) {
              // sunrise event
              const roundOffSeconds = schedule.end.getSeconds();
              const roundOffMin = schedule.end.getMinutes() % 30;
              const trimmingTime = moment.duration(
                `00:${roundOffMin}:${roundOffSeconds}`
              );
              const roundOffEndTime = moment(schedule.end)
                .subtract(trimmingTime)
                .format();
              if (!returnVal) {
                returnVal =
                  currentDateData <= new Date(roundOffEndTime) &&
                  currentDateData >= schedule.start;
              }
            } else if (!returnVal) {
              returnVal =
                currentDateData <= schedule.end &&
                currentDateData >= schedule.start;
            }
            return returnVal;
          });
        } else if (fixedSchedules.length > 0) {
          fixedSchedules.map(schedule => {
            if (!returnVal) {
              returnVal =
                currentDateData <= schedule.end &&
                currentDateData >= schedule.start;
            }
            return returnVal;
          });
        }
        returnVal = !returnVal;
      } else {
        // No Master Schedule for the day then we should allow user to create Field Schedules for that day
        // returnVal false means enable the dropdown value
        returnVal = false;
      }
    }
    return returnVal;
  }

  render() {
    const timeviews = [];
    const intervals = IntervalGenerator.fetchIntervals(
      this.props.startTime,
      144
    );
    intervals.forEach(item => {
      let isDisabled;

      if (this.props.isFieldScheduleChild) {
        isDisabled = this.checkDisableForFieldSchedule(item);
      } else {
        isDisabled = moment(
          concatDateTime(this.props.startTime, item)
        ).isBefore(new Date());
      }

      const disabledlinkStyle = isDisabled ? "eua-dropdown-disabled-link" : "";
      // this.selectedItem = item;
      timeviews.push(
        <li
          key={item}
          className={`dropdown-item eua-time-select-li ${disabledlinkStyle}`}
          role="option"
          aria-selected={false}
          data-value={item}
          ref={el => {
            this.itemRefs[item] = el;
          }}
          onClick={event =>
            this.props.onSelectChange(event.target.getAttribute("data-value"))
          }
          onKeyPress={event =>
            this.props.onSelectChange(event.target.getAttribute("data-value"))
          }
        >
          {item}
        </li>
      );
    });
    return (
      <div
        className={`dropdown dropdown-background ${this.props.containerClassName}`}
      >
        <button
          className="btn btn-block btn-secondary dropdown-toggle eua-time-select-btn"
          type="button"
          id="dropdownMenuButton7"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={() =>
            this.itemRefs[
              IntervalGenerator.getCurrentHHcolonMM(new Date())
            ].scrollIntoView({ block: "end" })
          }
        >
          <span>{this.props.selectedTime}</span>
        </button>
        <ul
          className="dropdown-menu eua-time-select-ul"
          aria-labelledby="dropdownMenuButton7"
        >
          {timeviews}
        </ul>
      </div>
    );
  }
}
CustomDropdown.propTypes = {
  containerClassName: PropTypes.string,
  selectedTime: PropTypes.string,
  startTime: PropTypes.string,
  onSelectChange: PropTypes.func.isRequired,
  masterData: PropTypes.objectOf(PropTypes.shape),
  isFieldScheduleChild: PropTypes.bool
};

CustomDropdown.defaultProps = {
  containerClassName: null,
  startTime: null,
  selectedTime: null,
  masterData: [],
  isFieldScheduleChild: false
};
export default CustomDropdown;
