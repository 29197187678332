import LanguageManagement from "./LanguageManagement";
import ScheduleManagment from "../Schedule/ScheduleManagement";
import * as LanguageConstants from "../constants/LanguageConstants"

class LanguageSelector {
  constructor()
  {
    this.userLanguage = null;
    this.siteLanguage = null;
    this.cultureLanguage = null;
  }

  SelectLanguage(siteId = null)
  {
    this.userLanguage = LanguageManagement.getUserLanguage();
    const sites = ScheduleManagment.getSites();

    this.siteLanguage = ((typeof sites === 'undefined' || siteId === null || siteId === "null") ? null : (sites.find(site => site.id === siteId)).language);

    if (this.userLanguage === null || typeof this.userLanguage === 'undefined' || this.userLanguage === "null")
      this.cultureLanguage = ((this.siteLanguage === null) ? LanguageConstants.DEFAULTCULTURE : this.siteLanguage) ;
    else
      this.cultureLanguage = this.userLanguage;

    localStorage.setItem(LanguageConstants.CULTURE, this.cultureLanguage);
    return this.cultureLanguage;
  }
}

const CultureLanaguage = new LanguageSelector();
export default CultureLanaguage;