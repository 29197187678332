function doubleZeros(digit) {
  return digit < 10 ? `0${digit}` : `${digit}`;
}
function formatDateHHcolonMM(date) {
  // funny name but specific
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${doubleZeros(hour)}:${doubleZeros(minute)}`;
}

function createHalfHourIntervals(from, until) {
  const time = new Date(from.replace(/-/g, "/"));
  const intervals = []; // more clear name than hours

  for (let i = 0; i <= until; ) {
    intervals.push(formatDateHHcolonMM(time));
    if (i < until - 1) time.setMinutes(time.getMinutes() + 10);
    else time.setMinutes(time.getMinutes() + 9);
    i += 1;
  }
  return intervals;
}

function fetchIntervals(from, until) {
  return createHalfHourIntervals(from, until);
}
function getCurrentHHcolonMM(date) {
  let hour = date.getHours();
  let minute = date.getMinutes();
  minute = minute <= 30 ? 30 : minute;
  if (minute > 30) {
    hour += 1;
    minute = 0;
  }
  return `${doubleZeros(hour)}:${doubleZeros(minute)}`;
}
export default { fetchIntervals, formatDateHHcolonMM, getCurrentHHcolonMM };
