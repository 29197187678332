import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import * as Labels from '../constants/Labels';
import "../css/Language.css";

const LanguageMapper = props => {
  const options = props.languageCodes.map((item, index) => {
    return (<option key={item} value={item}>{props.languageNames[index]}</option>);
  });

  const onChange = (e) => {
    props.handleChange(e);
  };
  return (
    <div>
      <form>
        <div>
          <p className='language'>{i18next.t(Labels.LANGUAGE)}</p>
        </div>
        <div>
          <select value={props.userLanguage} className="languageSettingDropdown" onChange={onChange}>
            {options}
          </select>
        </div>
      </form>
    </div>
  );
};

LanguageMapper.propTypes = {
  userLanguage: PropTypes.string,
  languageNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  languageCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired
};

LanguageMapper.defaultProps = {
  userLanguage: "",
}

export default LanguageMapper;
