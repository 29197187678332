/* eslint-disable no-param-reassign */
import React from "react";
import i18next from "i18next";
import { Redirect } from "react-router-dom";
import { siteCheckboxColors } from "../constants/ColorPatternConstants";
import { MonitorApplication } from "../constants/AppConstants";
import { MonitorTypesList } from "../constants/MonitorTypeConstants";
import MonitorDashboard from "./MonitorDashboard";
import SiteManagement from "../Schedule/ScheduleManagement";
import LanguageSelector from "../Language/LanguageSelector";

const Monitor = () => {
  const sites = SiteManagement.getSites();
  const sitesWithPermissions = sites.filter(site =>
    site.appPermissions.includes(MonitorApplication.Key)
  );
  sitesWithPermissions.forEach((site, index) => {
    const colorPreset = siteCheckboxColors.get(index + 1);
    site.color =
      colorPreset !== undefined
        ? colorPreset
        : { Maincolor: "#00B8D4", bgColor: "#CCF1F6" };
  });

  const language = LanguageSelector.SelectLanguage();
  i18next.changeLanguage(language);

  const selectedApp = () => {
    // if(props.match.params.app !==undefined && props.match.params.app !==null){
    //   const isValidApp = MonitorTypesList.find(item=> item.replace(/ +/g, "") === props.match.params.app);
    //   return isValidApp;
    // };
    return MonitorTypesList[0];
  }
  return sitesWithPermissions.length > 0 ? (
    <MonitorDashboard SitesWithPermission={sitesWithPermissions} selectedApp={selectedApp()}/>
  ) : (
    <Redirect to="/AccessDenied" />
  );
};



Monitor.defaultProps = {
  match: {}
};

export default Monitor;
