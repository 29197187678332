import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import NextIcon from "../Icons/ChevronRight";
import BackIcon from "../Icons/ChevronLeft";
import { EnergyFrequencyTypes } from "../constants/MonitorTypeConstants";
import EnergyAggregationHelper from "../helpers/EnergyAggregationHelper";

const RangeNavigator = props => {
  const label = (start, end, rangeType) => {
    switch (rangeType) {
      case EnergyFrequencyTypes.Day:
        return `${end.format("MM")}/${end.format("DD")}/${end.format("YY")}`;
      case EnergyFrequencyTypes.SevenDays:
      case EnergyFrequencyTypes.Month:
        return `${start.format("MM")}/${start.format("DD")}/${start.format(
          "YY"
        )} - ${end.format("MM")}/${end.format("DD")}/${end.format("YY")}`;
      case EnergyFrequencyTypes.ThreeMonths:
      case EnergyFrequencyTypes.Year:
        return `${start.format("MM")}/${start.format("YY")} - ${end.format(
          "MM"
        )}/${end.format("YY")}`;
      default:
        return "";
    }
  };

  const isFrontDisabled = (endDate, aggregationType) => {
    const end = EnergyAggregationHelper.getEndDateforAggregation(
      aggregationType,
      endDate
    );
    const yesterdayDate = moment().subtract(2, "days");
    const isDisabled = end.isAfter(yesterdayDate);
    return isDisabled;
  };
  const isDisabled = isFrontDisabled(props.endDate, props.rangeType);
  return (
    <div className="eua-monitor-range-container">
      <button
        type="button"
        className="eua-calendar-navigation-icon eua-range-button"
        onClick={() => props.handleRangeClick("back")}
        aria-label="previousIcon"
      >
        <BackIcon className="prev-icon" />
      </button>
      <div className="eua-monitor-datelabel">
        {label(props.startDate, props.endDate, props.rangeType)}
      </div>
      {!isDisabled && (
        <button
          type="button"
          className="eua-calendar-navigation-icon eua-range-button"
          onClick={() => props.handleRangeClick("front")}
          aria-label="nextIcon"
        >
          <NextIcon className="next-icon" />
        </button>
      )}
    </div>
  );
};

RangeNavigator.propTypes = {
  rangeType: PropTypes.string,
  endDate: PropTypes.instanceOf(moment),
  startDate: PropTypes.instanceOf(moment),
  handleRangeClick: PropTypes.instanceOf(Function)
};
RangeNavigator.defaultProps = {
  rangeType: "",
  endDate: moment(),
  startDate: moment(),
  handleRangeClick: null
};

export default RangeNavigator;
