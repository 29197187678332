import React from "react";
import i18next from "i18next";
import MainHeader from "./MainHeader";
import UserManagement from "./UserManagement";
import SchedulingApi from "./api/SchedulingApi";
import HomeApi from "./api/HomeApi";
import GifImageContainer from "./components/GifImageContainer";
import ScheduleManagement from "./Schedule/ScheduleManagement";
import Routes from "./Routes";
import AcceptLicense from "./About/AcceptLicense";
import LanguageSelector from "./Language/LanguageSelector";
import AccessDenied from "./AccessDenied";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      isLoading: true,
      hasAccepted: false,
      accessDenied: false
    };
  }

  componentDidMount() {
    this.loadData();
   
  }

  loadData = () => {
    HomeApi.getLoggedInUserData()
      .then(response => {
        if (response.status === 200) {
          const hasAccepted = UserManagement.getHasAccepted();
          if (hasAccepted) {
            SchedulingApi.getSites()
              .then(() => {
                const sites = ScheduleManagement.getSites();
                const language = LanguageSelector.SelectLanguage();
                i18next.changeLanguage(language).then(() => {
                  this.setState({
                    sites,
                    isLoading: false,
                    userName: UserManagement.getUserName(),
                    hasAccepted: UserManagement.getHasAccepted()
                  });
                }).catch(() => {
                  this.setState({
                    sites,
                    isLoading: false,
                    userName: UserManagement.getUserName(),
                    hasAccepted: UserManagement.getHasAccepted()
                  });
                });
                
              })
              .catch(() => {
                this.setState({ isLoading: false });
              });
          } else {
            this.setState({
              isLoading: false,
              userName: UserManagement.getUserName(),
              hasAccepted: UserManagement.getHasAccepted()
            });
          }
        }       
      })
      .catch(() => {
        this.setState({ isLoading: false, accessDenied: true });
      });
  };

  handleLogoutClick = () => {
    HomeApi.signOut();
    this.setState({ userName: "" });
  };

  handleUserAcceptance = () => {
    HomeApi.setUserAgreement({ hasAccepted: true }).then(() => {
      this.loadData();
    });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="loading-screen">
          <GifImageContainer alt="Loading" src="/images/loading.gif" />
        </div>
      );
    }

    return (
      <div className="elements-container">
        {this.state.hasAccepted && (
          <>
            <MainHeader
              userName={this.state.userName}
              sites={this.state.sites}
              onLogoutClick={this.handleLogoutClick}
            />
            <Routes />
          </>
        )}
        {!this.state.accessDenied &&
          (<AcceptLicense
          show={!this.state.hasAccepted}
          handleAcceptClick={this.handleUserAcceptance}
          />)
        }
        {this.state.accessDenied && (
          <AccessDenied />
        )}
      </div>
    );
  }
}

export default Home;
