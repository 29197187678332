import moment from "moment";

function concatDateTime(date, time) {
  const newDate = moment(date)
    .format("YYYY-MM-DD")
    .toString();
  return moment(`${newDate} ${time}`).toDate();
}
class FieldSchedule {
  constructor(data) {
    this.title = `${data.scene}`;
    this.start =
      data.schedulingFrequency === 0
        ? concatDateTime(data.scheduleStartDate, data.startTime)
        : concatDateTime(data.recurringScheduleStartDate, data.startTime);
    this.end =
      data.schedulingFrequency === 0
        ? concatDateTime(data.scheduleEndDate, data.endTime)
        : concatDateTime(data.recurringScheduleEndDate, data.endTime);
    this.scheduleId = data.scheduleId;
    this.parentScheduleId = data.parentScheduleId;
    this.scene = data.scene;
    this.scheduleEndDate = data.scheduleEndDate;
    this.scheduleStartDate = data.scheduleStartDate;
    this.endTime = data.endTime;
    this.startTime = data.startTime;
    this.programName = data.programName;
    this.fieldId = data.fieldId;
    this.description = data.description;
    this.userName = data.userName;
    this.schedulingFrequency = data.schedulingFrequency;
    this.dayOfWeek = data.dayOfWeek;
    this.daysOfWeek = data.daysOfWeek;
    this.isNonEditable = data.isNonEditable;
    this.siteId = data.siteId;
    this.parentStartTime = data.parentStartTime;
    this.parentEndTime = data.parentEndTime;
    this.parentSchedulingFrequency = data.parentSchedulingFrequency;
    this.isHalfFieldEnabled = data.isHalfFieldEnabled;
    this.EditType = data.EditType;
    this.recurringScheduleStartDate = data.recurringScheduleStartDate;
    this.recurringScheduleEndDate = data.recurringScheduleEndDate;
    this.OldStartTime = data.oldStartTime;
    this.DeleteType = data.DeleteType;
    this.SceneEdit = data.SceneEdit;
  }
}

export default FieldSchedule;
