import ScheduleManagement from "./MasterScheduleManagement";
import Service from "../helpers/Service";
import ApiErrorHandler from "../helpers/ApiErrorHandler";
import LanguageSelector from "../Language/LanguageSelector";

class MasterSchedulingApi {
  static getMasterScheduleForSite(site, startDate, endDate) {
    LanguageSelector.SelectLanguage(site);
    return Service.get(
      `api/MasterSchedule/GetSchedules/${site}/${startDate}/${endDate}`
    )
      .then(json => {
        ScheduleManagement.setMasterSchedule(json.data);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  static createMasterSchedule(schedule) {
    LanguageSelector.SelectLanguage(schedule.siteId);
    return Service.post("api/MasterSchedule/AddSchedule", schedule)
      .then(json => json)
      .catch(error => {
        ApiErrorHandler.handleError(error);
        return error;
      });
  }

  static removeMasterSchedule(masterSchedule) {
    LanguageSelector.SelectLanguage(masterSchedule.siteId);
    return Service.delete("api/MasterSchedule/DeleteSchedule", masterSchedule)
      .then(json => json)
      .catch(error => {
        ApiErrorHandler.handleError(error);
        return error;
      });
  }

  static editMasterSchedule(schedule) {
    LanguageSelector.SelectLanguage(schedule.siteId);
    return Service.put("api/MasterSchedule/EditSchedule", schedule)
      .then(json => json)
      .catch(error => {
        ApiErrorHandler.handleError(error);
        return error;
      });
  }
}

export default MasterSchedulingApi;
