const Schedules = {
  FieldSchedule: "Field Schedule",
  MasterSchedule: "Master Schedule"
};

const ScheduleTypes = {
  FixedSchedule: "FixedSchedule",
  TwilightSchedule: "TwilightSchedule",
  MasterFixed: "MasterFixed",
  MasterTwilight: "MasterTwilight"
};

const MasterScheduleTypes = [
  { Key: 0, Value: ScheduleTypes.FixedSchedule },
  { Key: 1, Value: ScheduleTypes.TwilightSchedule },
  { Key: 2, Value: ScheduleTypes.MasterFixed },
  { Key: 3, Value: ScheduleTypes.MasterTwilight }
];
const ScheduleOffSetSign = [
  { Key: 0, Value: "+" },
  { Key: 1, Value: "-" }
];
const TwilightOffset = [
  {
    Key: "90 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 1,
      offsetMinutes: 30
    }
  },
  {
    Key: "80 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 1,
      offsetMinutes: 20
    }
  },
  {
    Key: "70 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 1,
      offsetMinutes: 10
    }
  },
  {
    Key: "60 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 1,
      offsetMinutes: 0
    }
  },
  {
    Key: "50 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 0,
      offsetMinutes: 50
    }
  },
  {
    Key: "40 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 0,
      offsetMinutes: 40
    }
  },
  {
    Key: "30 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 0,
      offsetMinutes: 30
    }
  },
  {
    Key: "20 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 0,
      offsetMinutes: 20
    }
  },
  {
    Key: "10 minutes",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 0,
      offsetMinutes: 10
    }
  },
  {
    Key: "0 minute",
    Value: {
      offsetTimeSign: ScheduleOffSetSign[0],
      offsetHours: 0,
      offsetMinutes: 0
    }
  }
];

const DefaultSunriseOffset = TwilightOffset.findIndex(
  x => x.Key === "90 minutes"
);

const DefaultSunsetOffset = TwilightOffset.findIndex(
  x => x.Key === "90 minutes"
);

const DefaultSunriseOffsetTimeSign = ScheduleOffSetSign[0];
const DefaultSunsetOffsetTimeSign = ScheduleOffSetSign[1];

export {
  Schedules,
  ScheduleTypes,
  MasterScheduleTypes,
  TwilightOffset,
  ScheduleOffSetSign,
  DefaultSunriseOffset,
  DefaultSunsetOffset,
  DefaultSunriseOffsetTimeSign,
  DefaultSunsetOffsetTimeSign
};
