import React from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import SiteIcon from "./Icons/SiteIcon";
import "./css/MainDashboard.css";
import AppContainer from "./components/AppContainer";
import * as Labels from "./constants/Labels";
import LanguageSelector from "./Language/LanguageSelector";

const Site = props => {
  i18next.changeLanguage(LanguageSelector.SelectLanguage());
  const detectedSites = props.sites.map(item => {
    const SiteItem = (
      <div className="row pb-2 pl-2">
        <div className="col-3 eua-site-container-icon mt-2">
          <SiteIcon />
        </div>
        <div className="col-8 ml-4 mt-1">
          <div className="row w-100 p-0 eua-site-item">
            <p className="home-site-name p-0"> {item.name} </p>
          </div>
          <div className="row eua-site-apps">
            <AppContainer site={item} />
          </div>
        </div>
      </div>
    );

    return (
      <li
        id="home-site-item"
        className="row col-8 pb-3 list-group-item"
        key={item.id}
        role="option"
        aria-selected={item.id === props.defaultSelectedSite.id}
        tabIndex={0}
      >
        {SiteItem}
      </li>
    );
  });
  return (
    <div className="text-center">
      <div>
        <h1 className="col-6 ml-5 pt-4 pb-4 pl-0">
          <b>{i18next.t(Labels.FACILITIES)}</b>
        </h1>
      </div>

      <ul className="row row-margin justify-content-center">{detectedSites}</ul>
    </div>
  );
};

Site.propTypes = {
  sites: PropTypes.instanceOf(Array),
  defaultSelectedSite: PropTypes.instanceOf(Object)
};
Site.defaultProps = {
  sites: [],
  defaultSelectedSite: {}
};
export default Site;
