import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import {Facilities, PitchTypesLables} from "../constants/PitchTypeConstants";
import "../css/control.css";
import {ControlSceneTypes} from "../constants/ScenesConstants";
import SceneRow from "./SceneRow";
import * as Labels from "../constants/Labels";

class FieldControl extends React.Component {
  constructor(props) {
    super(props);
    this.handleSceneChange = this.handleSceneChange.bind(this);
    this.handleScenes = this.handleScenes.bind(this);
  }

  handleScenes = (selectedField, enable) => {
    const facility = PitchTypesLables.filter(
      pt => pt.Key === selectedField.pitchType
    )[0].Facilities;
    return selectedField.customScenes.filter(item => {
      const currentScenePermission = selectedField.scenePermissions.find(
        scene => scene.name === item.perfectPlayScene
      );
      if (
        facility === Facilities.NonSportsFacilities &&
        (item.perfectPlayScene === ControlSceneTypes.HALFFIELDLEFT ||
          item.perfectPlayScene === ControlSceneTypes.HALFFIELDRIGHT)
      )
        return null;
      if (
        (item.perfectPlayScene === ControlSceneTypes.HALFFIELDLEFT ||
          item.perfectPlayScene === ControlSceneTypes.HALFFIELDRIGHT) &&
        !currentScenePermission.isEnable
      )
        return null;
      item.isEnable = currentScenePermission.isEnable; // eslint-disable-line no-param-reassign
      if (currentScenePermission.isEnable === enable && item.perfectPlayScene !== ControlSceneTypes.OFF)
        return item;

      return null;
    });
  };

  handleSceneChange(scene) {
    this.props.handleSceneChange(scene, this.props.match.params.fieldId);
  }

  render() {
    const selectedField = this.props.fields.find(
      field => field.id === this.props.match.params.fieldId
    );
    const scenesEnabled = this.handleScenes(selectedField, true);
    const scenesDisabled = this.handleScenes(selectedField, false);
    const scenesOff = selectedField.customScenes.filter(
      item => item.perfectPlayScene === ControlSceneTypes.OFF
    );
    const scenes = scenesEnabled.concat(scenesDisabled, scenesOff);
    const allScenes = (
      <SceneRow
        key={selectedField.id}
        scenes={scenes}
        active={selectedField.state}
        handleSceneClick={scene => this.handleSceneChange(scene)}
      />
    );

    return (
      <div
        key={selectedField.id - selectedField.name}
        className="col pt-4 pr-0"
      >
        <div
          key={selectedField.id - selectedField.name}
          className="scene-header pl-4"
        >
          {i18next.t(Labels.FIELD_LIGHTING_CONTROL)} - {selectedField.name}
        </div>
        {allScenes}
      </div>
    );
  }
}

FieldControl.propTypes = {
  match: PropTypes.instanceOf(Object),
  fields: PropTypes.instanceOf(Array),
  handleSceneChange: PropTypes.func
};

FieldControl.defaultProps = {
  match: {},
  fields: [],
  handleSceneChange: null
};
export default FieldControl;
