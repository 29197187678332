import moment from "moment";
import { EnergyFrequencyTypes } from "../constants/MonitorTypeConstants";

function getStartDateforAggregation(aggregationOption, selectedDate) {
  // const diff = moment.preciseDiff(starts, ends, true);
  switch (aggregationOption) {
    case EnergyFrequencyTypes.Day:
      return selectedDate;
    case EnergyFrequencyTypes.SevenDays:
      return moment(selectedDate).subtract(6, "days");
    case EnergyFrequencyTypes.Month:
      return moment(selectedDate).subtract(30, "days");
    case EnergyFrequencyTypes.ThreeMonths:
      return moment(selectedDate).subtract(2, "months");
    case EnergyFrequencyTypes.Year:
      return moment(selectedDate).subtract(1, "years");
    case EnergyFrequencyTypes.FiveYears:
      return moment(selectedDate).subtract(5, "years");
    default:
      return false;
  }
}

function getEndDateforAggregation(aggregationOption, selectedDate) {
  // const diff = moment.preciseDiff(starts, ends, true);
  switch (aggregationOption) {
    case EnergyFrequencyTypes.Day:
      return selectedDate;
    case EnergyFrequencyTypes.SevenDays:
      return moment(selectedDate).add(6, "days");
    case EnergyFrequencyTypes.Month:
      return moment(selectedDate).add(30, "days");
    case EnergyFrequencyTypes.ThreeMonths:
      return moment(selectedDate).add(2, "months");
    case EnergyFrequencyTypes.Year:
      return moment(selectedDate).add(1, "years");
    case EnergyFrequencyTypes.FiveYears:
      return moment(selectedDate).add(5, "years");
    default:
      return false;
  }
}

function getRangeview(rangeType){
  switch(rangeType){
    case EnergyFrequencyTypes.Day:
      return "Hourly View";
        case EnergyFrequencyTypes.SevenDays:
      return "Daily View";
        case EnergyFrequencyTypes.Month:
      return "Daily View";
              case EnergyFrequencyTypes.ThreeMonths:
      return "Monthly View";
              case EnergyFrequencyTypes.Year:
      return "Monthly View";
              case EnergyFrequencyTypes.FiveYears:
      return "Yearly View";
      
      default:
        return ""

  }
}
function getDateFormat(aggregationOption) {
  // const diff = moment.preciseDiff(starts, ends, true);
  switch (aggregationOption) {
    case EnergyFrequencyTypes.ThreeMonths:
      return "YYYYMM";
    case EnergyFrequencyTypes.Year:
      return "YYYYMM";
    case EnergyFrequencyTypes.FiveYears:
      return "YYYY";
    default:
      return "DDMMYYYY";
  }
}

function hextorgba(hex, alpha = 1) {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};


export default {
  getStartDateforAggregation,
  getEndDateforAggregation,
  getDateFormat,
  getRangeview,
  hextorgba
};
