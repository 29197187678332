import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import NavigationComponent from "./CalendarNavigationToolbar";
import { Views } from "../constants/CalendarConstants";
import "../css/calendar.css";
import * as Labels from "../constants/Labels";

const CalendarToolBar = toolbar => {
  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setDate(now.getDate());
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("TODAY");
  };
  // const { view } = toolbar;
  const views = [];
  const DayView = "day";
  Views.forEach(item => {
    if (toolbar.views.find(v => v === item.Key))
      views.push(
        <li
          key={item.Key}
          className="list-group-item eua-calendar-select-li"
          role="option"
          aria-selected={false}
          data-value={item.Key}
          onClick={event =>
            toolbar.onView(event.target.getAttribute("data-value"))
          }
          onKeyPress={event =>
            toolbar.onView(event.target.getAttribute("data-value"))
          }
        >
          {i18next.t(item.Value.toUpperCase())}
        </li>
      );
  });
  return (
    <div className="toolbar-container">
      <div className="eua-calendar-toolbar-container">
        {toolbar.views.find(v => v === DayView) && (
          <button
          type="button"
            onClick={goToCurrent}
            className="btn eua-btn"
            style={{ marginRight: "auto", border: "1px solid #3c3c41" }}
          >
            {i18next.t(Labels.TODAY)}
          </button>
        )}
        <NavigationComponent {...toolbar} />
        <div className="dropdown dropdown-background">
          <button
            className="btn btn-secondary dropdown-toggle eua-calendar-select-view"
            type="button"
            id="dropdownMenuButton2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="week-name">
              {i18next.t((toolbar.view).toUpperCase())}
            </span>
          </button>
          <div
            className="dropdown-menu dropdown-menu-list-calendar p-0"
            aria-labelledby="dropdownMenuButton2"
          >
            {views}
          </div>
        </div>
      </div>
    </div>
  );
};

CalendarToolBar.propTypes = {
  view: PropTypes.string,
  onView: PropTypes.func,
  btnClick: PropTypes.func,
  views: PropTypes.arrayOf(PropTypes.string)
};

CalendarToolBar.defaultProps = {
  view: null,
  onView: null,
  btnClick: null,
  views: null
};
export default CalendarToolBar;
