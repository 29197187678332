import React from "react";
import { createRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import history from "./helpers/history";
import Home from "./Home";
import Authenticator from "./helpers/Auth";

if (Authenticator.isUserAuthenticated()) {
  createRoot(document.getElementById("app")).render(
    <Router history={history}>
      <Home/>
    </Router>
  );
}
