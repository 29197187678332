import FieldSchedule from "../datamodels/FieldSchedule";

function createFieldScheduleEvent(event) {
  return new FieldSchedule(event);
}
class FieldScheduleManagement {
  constructor() {
    this.fieldScheduleEvents = [];
    this.newFieldEvent = {};
    this.editedSchedules =[] ;
  }

  addEditedScheduleList(data)
  {
    this.editedSchedules.push(data);
  }

  getEditedSchedulesList()
  {
    return this.editedSchedules;
  }

  removeEditedScheduleList(data)
  {
    this.editedSchedules.pop(data);
  }

  setFieldSchedule(data) {
    this.fieldScheduleEvents = data.map(createFieldScheduleEvent);
  }

  getFieldSchedule() {
    return this.fieldScheduleEvents;
  }

  getNewFieldSchedule(data) {
    this.newFieldEvent = createFieldScheduleEvent(data);
    return this.newFieldEvent;
  }
}

const instance = new FieldScheduleManagement();
export default instance;
