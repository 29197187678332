function formatLanguagesData(data) {
  const languageNames = [];
  const languageCodes = [];
  if (data && data.constructor === Array) {
    data.forEach(item => {
      languageNames.push(item.language);
      languageCodes.push(item.language_code);
    });
  }
  return {
    languageNames,
    languageCodes
  };
}

class LanguageManagement {
  constructor() {
    this.isSaveInProgress = false;
    this.languageNames = [];
    this.languageCodes = [];
    this.userLanguage = null;
  }

  setLanguagesReceived(data) {
    const formattedData = formatLanguagesData(data);
    this.languageNames = formattedData.languageNames;
    this.languagesCode = formattedData.languageCodes;
  }

  setUserLanguage(userLanguage) {
    this.userLanguage = userLanguage;
  }

  getLanguageNames() {
    return this.languageNames;
  }

  getLanguageCodes() {
    return this.languagesCode;
  }

  getUserLanguage() {
    return this.userLanguage;
  }
}

const instance = new LanguageManagement();
export default instance;
