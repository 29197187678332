// eslint-disable-next-line import/prefer-default-export
export const siteCheckboxColors = new Map([
  [1, { Maincolor: "#B1EE8B", bgColor: "#B1EE8B" }],
  [2, { Maincolor: "#008A9F", bgColor: "#008A9F" }],
  [3, { Maincolor: "#94B0FF", bgColor: "#94B0FF" }],
  [4, { Maincolor: "#8000BF", bgColor: "#8000BF" }],
  [5, { Maincolor: "#FF9BDF", bgColor: "#FF9BDF" }],
  [6, { Maincolor: "#BF298F", bgColor: "#BF298F" }],
  [7, { Maincolor: "#D480FF", bgColor: "#D480FF" }],
  [8, { Maincolor: "#1F4ABF", bgColor: "#1F4ABF" }],
  [9, { Maincolor: "#80DCEA", bgColor: "#80DCEA" }],
  [10, { Maincolor:"#4BA611", bgColor:"#4BA611" }]
]);