/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  select,
  scaleLinear,
  scaleTime,
  line,
  axisLeft,

  nest,
  scaleOrdinal,
  curveCardinal,
  min,
  max,
  mouse,
  event,
  bisector,
  

} from "d3";
import "../css/Graph.css";
import GraphUtils from "./GraphUtils";
import { EnergyFrequencyTypes } from "../constants/MonitorTypeConstants";
// import EnergyAggregationHelper from "../helpers/EnergyAggregationHelper";

class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = { yaxisLabel: "kWh", width: 0,modifiedData:"" };
    this.myRef = React.createRef();
  }



  componentDidMount() {
    const modifiedData = GraphUtils.flattenData(
      this.props.data,
      this.props.siteStates
    );
    this.setState({modifiedData});
    const width = this.getWidth();
   // const height = this.getHeight();
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ width }, () => {
      this.drawLineChart(modifiedData, this.props.rangeType);
    });
    this.props.getEnergyFlattenData(modifiedData);
    let resizedFn;
        window.addEventListener("resize", () => {
            clearTimeout(resizedFn);
            resizedFn = setTimeout(() => {
                this.redrawChart();
            }, 200)
        });
  }


  componentDidUpdate(prevProps) {
    if (
      this.props.data !== prevProps.data ||
      this.props.rangeType !== prevProps.rangeType ||
      this.props.endDate !== prevProps.endDate ||
      this.props.rangeType === "Hourly" ||
      this.props.siteStates !== prevProps.siteStates
    ) {
      const modifiedData = GraphUtils.flattenData(
        this.props.data,
        this.props.siteStates
      );
      this.drawLineChart(modifiedData, this.props.rangeType);
    }

    if (
      this.props.data !== prevProps.data ||
      this.props.rangeType !== prevProps.rangeType ||
      this.props.endDate !== prevProps.endDate ||
      this.props.siteStates !== prevProps.siteStates
    ) {
       const modifiedData = GraphUtils.flattenData(
        this.props.data,
        this.props.siteStates
      );
    this.props.getEnergyFlattenData(modifiedData);
  }

  }

  componentWillUnmount(){
    this.ref=null;
  }


  getWidth() {
    return this.myRef.current.parentElement.offsetWidth;
  }

  getHeight() {
    return this.myRef.current.parentElement.offsetHeight;
  }

    // resize the chart for resposive
    // eslint-disable-next-line react/sort-comp
    redrawChart() {
      select(".lineChart svg").remove();
      const width =window.innerWidth;
      const modifiedData = GraphUtils.flattenData(
        this.props.data,
        this.props.siteStates
      );
      this.setState({width,modifiedData});
      this.drawLineChart(this.state.modifiedData, this.props.rangeType);
  }


  // The main method which creates the chart
  drawLineChart = (data, rangeType) => {

    // const view = EnergyAggregationHelper.getRangeview(rangeType);
    // converting the data into key value pairs
    const nested = nest()
      .key(d => d.siteName)
      .entries(data);

    // Cleaning up when component re renders
    const cleanUp = select(".lineChart");
    cleanUp.selectAll("*").remove();
    // const lastReadingMap = new Map();
    // nested.forEach((k)=>{
    //   const maxReading = k.values.sort((a,b) => Date.parse(b.date)-Date.parse(a.date))[0];
    //   if(maxReading.date.getHours() ===0){
    //     lastReadingMap.set(k.key,maxReading.date)
    //   }
    // })
    const margin = { top: 60, left: 40, bottom: 40, right: 60 };
    const width = this.state.width - margin.left - margin.right;
    const height = 450 - margin.top - margin.bottom;

    const isAnySiteSelected = this.props.data.some((site) => this.props.siteStates.get(site.siteId) === true)

    // Creating SVG
    const svg = select(".lineChart")
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      // .attr("viewBox",`0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");

    // value on x-axis
    const xValue = d => d.date;

    // value on y-axis
    const yValue = d => d.energy;

    // adding tooltip(when hovered) to the DOM
    const tooltip = select(".lineChart")
    .append("div");
     // .attr("class", "tooltip")
      // .style("display", "none");
     // .style("opacity", "0");

      // tooltip.append("span").attr("class","tooltip-left-triangle");

    // create x-axis scale
    const xScale = scaleTime()
      .domain( [min(data,xValue), max(data,xValue)])
      .range([0, width]);

    // create y-axis scale
    const yScale = scaleLinear()
      .domain([0, max(data, yValue)])
      .range([height, 0])
      .nice();

    // appending rect for tooltip hover purpose
    const rect = svg
      .append("rect")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .attr("class", "overlay")
      .attr("width", width)
      .attr("height", height)

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // creating x-axis
    const xAxis = GraphUtils.timeScale(rangeType, xScale);
    console.log(xAxis);


     const findTicks=()=>{

      switch (rangeType) {


        case EnergyFrequencyTypes.Day:
          return  Math.min( Math.round(width/80),24,nested[0].values.length===2?1:nested[0].values.length);

        case EnergyFrequencyTypes.Month:
          return  Math.min( Math.round(width/80),31,nested[0].values.length < 4? nested[0].values.length%2===0?nested[0].values.length-1:nested[0].values.length>2?nested[0].values.length-1:nested[0].values.length:nested[0].values.length);

        case EnergyFrequencyTypes.SevenDays:
          return  Math.min( Math.round(width/80),7,nested[0].values.length < 4? nested[0].values.length%2===0?nested[0].values.length-1:nested[0].values.length>2?nested[0].values.length-1:nested[0].values.length:nested[0].values.length);


        case EnergyFrequencyTypes.Year:
          return  Math.min( Math.round(width/80),12,nested[0].values.length===2?1:nested[0].values.length);

        case EnergyFrequencyTypes.ThreeMonths:
          return  Math.min( Math.round(width/80),3,nested[0].values.length===2?1:nested[0].values.length);

        case EnergyFrequencyTypes.FiveYears:
          return  Math.min( Math.round(width/80),5,nested[0].values.length===2?1:nested[0].values.length);

        default:
          return Math.round(width/80);

     }
    }

    xAxis
    .ticks( findTicks());


    // adding x-axis to dom
    const xAxisG = g
      .append("g")
      .call(xAxis)
      .attr("transform", `translate(5,${height+7})`);

    // making text inclined for x-axis for month view
    if (nested[0].values.length > 7) {
      xAxisG
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", ".8em");


    }

    // removes verticle lines
    xAxisG.select(".domain").remove();
    xAxisG.selectAll("line").remove();

    // creating y-axis - horizontal lines
    const yAxis = axisLeft(yScale)
      .tickPadding(10).ticks(5)
      .tickSize(-width-10);



    const yAxisG = g.append("g").call(yAxis);

    // removing some extra tick lines for axis
   // yAxisG.selectAll("line").remove();
    yAxisG.selectAll(".domain").remove();

    // adding y-axis label
    yAxisG
      .append("text")
      .attr("class", "axis-label")
      .attr("y", -25)
      .attr("x", -4)
      .text(this.state.yaxisLabel)
      .attr("font-size", 14);


  // creating line graph
  const lineGenerator = line()
  // .defined(d=> d.energy >100)
  .defined(d=> d.isinterpolate===false)
    .curve(curveCardinal.tension(0.85))
    .x(d => xScale(xValue(d)))
    .y(d => yScale(yValue(d)));

    const lineGeneratorMissing = line()
    .defined(d=> d)
      .curve(curveCardinal.tension(0.85))
      .x(d => xScale(xValue(d)))
      .y(d => yScale(yValue(d)));

    // creating color scale
    const color = scaleOrdinal()
      .domain(nested.map(d => d.key))
      .range(
        nested.map(d =>
          d.values[0].active ? d.values[0].color : d.values[0].bgColor
        )
      );


    // adding space to area
    const source = g
      .selectAll(".area")
      .data(nested)
      .enter()
      .append("g")
      .attr("class", d => `area ${d.key.replace(/ +/g, "")}`);

      const focusLine = source
      .append("g")
      .attr("class", "focusLine")
      .style("display", "none");



    // adding linegenerator with the data to the DOM
    source
      .selectAll(".line")
      .data(nested)
      .enter()
      .append("path")
      .attr("class", "line")
      .attr("stroke", d => color(d.key))
      .attr("stroke-width", d => (d.values[0].active ? "4" : "2"))
      .style("opacity",d => (d.values[0].active ? "1" : "0.4"))
      .on("click", d => this.props.handleSiteClick(d.values[0].siteId))
      .on("mouseover", () => {
        tooltip.style("display", "none");
        focusLine.style("display", "none");
        // focusCircle.style("display", "none");
      })
      .attr("d", d => lineGenerator(d.values));

      source
      .selectAll(".dashline")
      .data(nested)
      .enter()
      .append("path")
      .attr("class", "line")
      .attr("stroke", d => color(d.key))
      .attr("stroke-dasharray","4")
      .attr("stroke-width", d => (d.values[0].active ? "2" : "1"))
      .style("opacity",d => (d.values[0].active ? "0.5" : "0.2"))
      .on("click", d => this.props.handleSiteClick(d.values[0].siteId))
      .on("mouseover", () => {
        tooltip.style("display", "none");
        focusLine.style("display", "none");
       // focusCircle.style("display", "none");
      })
      .attr("d", d => lineGeneratorMissing(d.values));





      // change direction of tooltip left/right
      const tooltipLeftRight=(d)=>{
       if(xScale(d.date) < width-130) {
         return  xScale(d.date) + 73;
        }

           return  (xScale(d.date) - 120);
      }

       // change css class of tooltip left/right
      const tooltipRight=(d)=>{
        if(xScale(d.date) < width-130) {
          return  "tooltip";
         }
            return  "tooltipRight";
      }

      const getProperHeight=()=>{

        if(width > 466)
        return 250;
        return 510;
      }

  // for circle when hovered on the graph
  const focusCircle = source
  .append("g")
  // .attr("class", "focusCircle")
  .style("display", "none")
  .attr("fill", "white")
  .attr("stroke-width", "0")

    // method which executes on mousehover
    const mousemove = () => {
      const bisectDate = bisector(d => {
        return d.date;
      }).left;

      const currentData = nested.filter(
        site => this.props.siteStates.get(site.values[0].siteId) === true
      );
      if (currentData.length !== 0) {
        const x0 = xScale.invert(mouse(event.currentTarget)[0]);
        const i = bisectDate(currentData[0].values, x0, 1);
        const d0 = currentData[0].values[i - 1];
        const d1 = currentData[0].values[i];
        const d = (d1!==undefined) && x0 - d0.date > d1.date - x0 ? d1 : d0;


        focusLine.attr(
          "transform",
          `translate(${xScale(d.date)},0)`
        );
        focusCircle
        .attr("fill", "white")
        .attr("stroke-width", "4")
        .attr("stroke", currentData[0].values[0].color);

        tooltip.attr(
          "style",
          `
           left:${tooltipLeftRight(d)}px;
           top:${yScale(d.energy)+getProperHeight()}px;
          `
        );
       // tooltip.attr("style",`${tooltipRight(d)}`)
        tooltip.attr("class",tooltipRight(d))
        tooltip.style("opacity","1")
        tooltip.select(".tooltip-date").text(GraphUtils.dateFormatter(d.date,rangeType));
        tooltip.select(".tooltip-site").text(currentData[0].key);
        tooltip.select(".tooltip-energy").text(`${d.energy} kWh`);
        focusLine.select(".x-hover-line").attr("y2", height);
        focusCircle.attr(
          "transform",
          `translate(${xScale(d.date)},${yScale(d.energy)})`
        );
      }
    };




    // adding divs for adding data into tooltip
    tooltip.append("div").attr("class", "tooltip-date");

    tooltip.append("div").attr("class", "tooltip-site");

    tooltip.append("div").attr("class", "tooltip-energy");

    // added for an issue(extra dotted line) caused when no site is selected
    if (isAnySiteSelected){
      rect
        .on("mouseover touchstart ", () => {
          focusLine.style("display", null);
          tooltip.style("display", null);
          focusCircle.style("display", null);
        })
        .on("mouseout touchend", () => {
          focusLine.style("display", "none");
          tooltip.style("display", "none");
          focusCircle.style("display", "none");
        })
        .on("mousemove touchmove", mousemove);
    }


    g.selectAll(".clip-path")
      .data(nested)
      .enter()
      .append("g")
      .attr("clip-path", d => `url(#${`${d.key.replace(/ +/g, "")}clip`})`)
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height)
      .attr("class", "clip-path");

    // adding dotted line towards x-axis
    focusLine
    .append("line")
    .attr("class", "x-hover-line hover-line");

    // adding circle to the line graph when hovered
    focusCircle.append("circle").attr("r", 6).style("opacity","1");
  };



  render() {
    return <div className="lineChart" ref={this.myRef} />;
  }
}

Graph.propTypes = {
  data: PropTypes.instanceOf(Array),
  rangeType: PropTypes.string,
  endDate: PropTypes.instanceOf(moment),
  siteStates: PropTypes.instanceOf(Map),
  handleSiteClick: PropTypes.func,
  getEnergyFlattenData: PropTypes.func
};

Graph.defaultProps = {
  rangeType: "",
  data: [],
  endDate: moment(),
  siteStates: new Map(),
  handleSiteClick: null,
  getEnergyFlattenData: null
};

export default Graph;
