import React from "react";
import PropTypes from "prop-types";

const CustomCheckbox = ({ className, checked, handleChange, ...props }) => (
  <div className="CheckboxContainer">
    <input type="checkbox" className="HiddenCheckbox" {...props} />
    <div
      className={checked ? "StyledCheckboxChecked" : "StyledCheckboxUnchecked"}
      style={{ backgroundColor: checked ? props.color : "inherit" }}
    >
      <svg
        className={checked ? "svgstyleChecked" : "svgstyleUnchecked"}
        fill="none"
        stroke="white"
        strokeWidth="2px"
        viewBox="0 0 26 22"
      >
        <polyline points="17 6 9 17 4 12" />
      </svg>
    </div>
  </div>
);

CustomCheckbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  color: PropTypes.string,
  handleChange: PropTypes.func
};

CustomCheckbox.defaultProps = {
  className: "",
  checked: false,
  color: "",
  handleChange: null
};

export default CustomCheckbox;
