import React from "react";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import {ApplicationTypes} from "../constants/AppConstants";

const Slider = props => {
  const location = useLocation();
  const isSelected  = location.pathname.includes(ApplicationTypes.Control);
  const Iconcolor = isSelected ? "#FF375F" : "#3c3c41";
  const disabledColor = "#BEBEBE";
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <title>{props.tooltip}</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M21.5,17.3108 L20.781,17.3108 C20.293,15.9658 19.017,14.9998 17.503,14.9998 C15.989,14.9998 14.713,15.9658 14.225,17.3108 L0.5,17.3108 C0.224,17.3108 0,17.5348 0,17.8108 L0,19.8108 C0,20.0868 0.224,20.3108 0.5,20.3108 L14.521,20.3108 C15.136,21.3198 16.235,21.9998 17.503,21.9998 C18.771,21.9998 19.87,21.3198 20.484,20.3108 L21.5,20.3108 C21.776,20.3108 22,20.0868 22,19.8108 L22,17.8108 C22,17.5348 21.776,17.3108 21.5,17.3108 M21.5,1.9998 L18.653,1.9998 C18.09,0.8208 16.896,-0.0002 15.503,-0.0002 C14.109,-0.0002 12.916,0.8208 12.353,1.9998 L0.5,1.9998 C0.224,1.9998 0,2.2238 0,2.4998 L0,4.4998 C0,4.7758 0.224,4.9998 0.5,4.9998 L12.353,4.9998 C12.916,6.1788 14.109,6.9998 15.503,6.9998 C16.896,6.9998 18.09,6.1788 18.653,4.9998 L21.5,4.9998 C21.776,4.9998 22,4.7758 22,4.4998 L22,2.4998 C22,2.2238 21.776,1.9998 21.5,1.9998 M21.5,9.4998 L8.915,9.4998 C8.352,8.3208 7.158,7.4998 5.765,7.4998 C4.371,7.4998 3.178,8.3208 2.614,9.4998 L0.5,9.4998 C0.224,9.4998 0,9.7238 0,9.9998 L0,11.9998 C0,12.2758 0.224,12.4998 0.5,12.4998 L2.614,12.4998 C3.178,13.6788 4.371,14.4998 5.765,14.4998 C7.158,14.4998 8.352,13.6788 8.915,12.4998 L21.5,12.4998 C21.776,12.4998 22,12.2758 22,11.9998 L22,9.9998 C22,9.7238 21.776,9.4998 21.5,9.4998"
          id="path-slider"
        />
      </defs>
      <g id="VD2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="↳-Color-Copy">
          <mask id="mask-slider" fill="white">
            <use href="#path-slider" />
          </mask>
          <use id="Mask" fill={Iconcolor} href="#path-slider" />
          <g
            id="color-/-100-dark-grey"
            mask="url(#mask-slider)"
            fill={props.isDisabled ? disabledColor : Iconcolor}
          >
            <g transform="translate(-1.000000, -1.000000)" id="icon_color">
              <rect x="0" y="0" width="24" height="24" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
Slider.propTypes = {
  tooltip: PropTypes.string,
  isDisabled: PropTypes.bool
};

Slider.defaultProps = {
  tooltip: null,
  isDisabled: false
};
export default Slider;
