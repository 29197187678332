import Service from "../helpers/Service";
import LanguageManagement from "../Language/LanguageManagement";
import ApiErrorHandler from "../helpers/ApiErrorHandler";
import LanguageSelector from "../Language/LanguageSelector";

class LanguageApi {
  static getLanguages() {
    LanguageSelector.SelectLanguage();
    return Service.get("/api/User/languages")
      .then(json => {
        LanguageManagement.setLanguagesReceived(json.data);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  static setUserLanguage(userLanguage) {
    LanguageSelector.SelectLanguage();
    return Service.post(`/api/User/language/${userLanguage}`).catch(error => {
      ApiErrorHandler.handleError(error);
      return error;
    });
  }
}

export default LanguageApi;
