import React, { Component } from "react";
import i18next from "i18next";
import moment from "moment";
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import * as Labels from "../constants/Labels";
import {
  daysOfWeekKVP
} from "../constants/DayOfWeekConstants";
import AddMasterScheduleModal from "./AddMasterScheduleModal";
import DeleteMasterScheduleModal from "./DeleteMasterScheduleModal";
import { Frequency } from "../constants/FrequencyConstants";
import LanguageSelector from "../Language/LanguageSelector";
import RepeatIcon from "../Icons/Repeat";
import SchedulingUtilities from "../helpers/SchedulingUtilities";
import TwilightIcon from "../Icons/Twilight";
import Info from "../Icons/Info";
import EditedScheduleManagement from "../FieldSchedule/EditedScheduleManagement";

class EditMasterScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleDetails: true,
      editSeries: false,
      delete: false,
      isCreate: false,
      frequencyType: Frequency.filter(
        f => f.Key === this.props.event.parentSchedulingFrequency
      )[0].Value,
      recurrence: "",
      scheduleDate: "",
      scheduleTime: "",
      scheduleType: ""
    };
    this.handleEditOccurrence = this.handleEditOccurrence.bind(this);
    this.handleEditSeries = this.handleEditSeries.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.extractScheduleDetails = this.extractScheduleDetails.bind(this);
  }

  componentDidMount() {
    this.extractScheduleDetails();
  }

  handleEditSeries() {
    this.setState({
      editSeries: true,
      scheduleDetails: false
    });
  };

  handleEditOccurrence() {
    const editBoolean = this.props.event.parentSchedulingFrequency === 0;
    this.setState({
      editSeries: editBoolean,
      scheduleDetails: false
    });
  };

  handleDelete() {
    this.setState({
      delete: true,
      scheduleDetails: false
    });
  };

  extractScheduleDetails() {
    let recurrenceType = "";
    switch (this.state.frequencyType) {
      case "Weekly": recurrenceType = i18next.t(Labels.EVERY); break
      case "BiWeekly": recurrenceType = i18next.t(Labels.EVERY_ALTERNATE); break
      default: recurrenceType = i18next.t(this.state.frequencyType.toUpperCase());
    }
    if (this.props.event.daysOfWeek != null) {
      let days = '';
      const daysArray = [];
      this.props.event.daysOfWeek.forEach(day => daysArray.push(day));
      daysArray.sort();
      for (let i = 0; i < daysArray.length; i += 1) {
        if (daysOfWeekKVP.find(d => d.Key === daysArray[i])) {
          days = `${days} ${i18next.t((daysOfWeekKVP[daysArray[i]].Value).toUpperCase())}${i === daysArray.length - 1 ? '' : ','}`
        }
      }
      recurrenceType = `${recurrenceType} ${days}`;
    }

    const startTime = SchedulingUtilities.concatDateTime(this.props.event.sunrise, this.props.event.parentSunRiseFixedTime);
    const endTime = SchedulingUtilities.concatDateTime(this.props.event.sunset, this.props.event.parentSunSetFixedTime);
    const month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const scheduleStart = `${i18next.t(month[(new Date(this.props.event.scheduleStartDate)).getMonth()])} ${new Date(this.props.event.scheduleStartDate).getDate()}, ${new Date(this.props.event.scheduleStartDate).getFullYear()}`;
    const scheduleEnd = this.state.frequencyType !== "Never" ? ` - ${i18next.t(month[new Date(this.props.event.scheduleEndDate).getMonth()])} ${new Date(this.props.event.scheduleEndDate).getDate()}, ${new Date(this.props.event.scheduleEndDate).getFullYear()}` : '';
    const scheduleStartTime = this.props.event.parentMasterScheduleType === 1 ? `${(moment(startTime)).format("HH")}:${(moment(startTime)).format("mm")}` : `${(moment(this.props.event.scheduleStartDate)).format("HH")}:${(moment(this.props.event.scheduleStartDate)).format("mm")}`;
    const scheduleEndTime = this.props.event.parentMasterScheduleType === 1 ? `${(moment(endTime)).format("HH")}:${(moment(endTime)).format("mm")}` : `${(moment(this.props.event.scheduleEndDate)).format("HH")}:${(moment(this.props.event.scheduleEndDate)).format("mm")}`;
    const displayScheduleDate = `${scheduleStart}${scheduleEnd}`;
    const displayScheduleTime = `${scheduleStartTime} - ${scheduleEndTime}`;
    const displayScheduleType = this.props.event.parentMasterScheduleType === 1 ? i18next.t(Labels.TWILIGHT_SCHEDULE) : i18next.t(Labels.FIXED_SCHEDULE);
    this.setState({
      recurrence: recurrenceType,
      scheduleDate: displayScheduleDate,
      scheduleTime: displayScheduleTime,
      scheduleType: displayScheduleType
    });
  }

  render() {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.siteId));
    let modal = "";
    let editScheduleMessage = "";
  
    if (this.state.scheduleDetails) {
      const editedSchedulesList = EditedScheduleManagement.getEditedSchedulesList();
      let disableButtons = false;
      if (editedSchedulesList.includes(this.props.event.id)) {
        disableButtons = true;
        editScheduleMessage = (
          <table className="editmodel-table">
            <tr>
              <td aria-label="nextIcon"><Info/></td>
              <td>{i18next.t(Labels.EDIT_SCHEDULE_PROGRESS_MESSAGE)}</td>
            </tr>
          </table>
        );
      }  
      modal = (
        <div className="schedule-contents-div">
          <div className="recurrenceIcon">{this.props.event.parentMasterScheduleType === 1 ? <TwilightIcon /> : null}</div><span>{this.state.scheduleType}</span><br />
          <div className="recurrenceIcon">{this.state.frequencyType === "Never" ? null : <RepeatIcon />}</div><span>{this.state.recurrence}</span><br />
          <span className="emptySpace">{this.state.scheduleDate}</span><br />
          <span className="emptySpace">{this.state.scheduleTime}</span><br />

          {editScheduleMessage}

          <button
            type="button"
            className="editOccurrenceButton"
            onClick={this.handleEditOccurrence}
            disabled={disableButtons === true || (this.props.event.parentSchedulingFrequency !== 0 && SchedulingUtilities.getOnlyDate(new Date(this.props.event.recurringScheduleStartDate)) === SchedulingUtilities.getOnlyDate(new Date())) || moment(SchedulingUtilities.getOnlyDate(new Date(this.props.event.recurringScheduleStartDate))).isBefore(moment(SchedulingUtilities.getOnlyDate(new Date())))}
          >
            {i18next.t(Labels.EDIT_OCCURRENCE)}
          </button><br />
          <button
            type="button"
            className="editSeriesButton"
            onClick={this.handleEditSeries}
            disabled={this.props.event.parentSchedulingFrequency === 0 || disableButtons === true}
          >
            {i18next.t(Labels.EDIT_SERIES)}
          </button><br />
          <button
            type="button"
            className="deleteButton"
            onClick={this.handleDelete}
            disabled={disableButtons === true}
          >
            {i18next.t(Labels.DELETE)}
          </button>
        </div>
      );
    }

    else if (this.state.delete) {
      modal = (
        <DeleteMasterScheduleModal
          event={this.props.event}
          show={this.state.delete}
          close={this.props.close}
          save={this.props.save}
          siteId={this.props.siteId}
        />
      );
    }

    else {
      modal = (
        <AddMasterScheduleModal
          schedule={this.props.event}
          close={this.props.close}
          onSave={this.props.save}
          show={this.state.editSeries || !this.state.editSeries}
          isSeries={this.state.editSeries}
          isCreate={this.state.isCreate}
          siteId={this.props.siteId}
        />
      );
    }

    const handleReturnModal = () => {
      if (this.state.editOccurrence || this.state.editSeries || this.state.delete) {
        return modal;
      }
      return (<Modal
        show={this.props.show}
        onHide={this.props.close}
        className="edit-field-schedule-container"
      >
        <Modal.Body className="edit-field-schedule-modalbody">
          {modal}
        </Modal.Body>
      </Modal>)
    };

    return (
      <div>
        {handleReturnModal()}
      </div>
    );
  }
}

EditMasterScheduleModal.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired
};

export default EditMasterScheduleModal;
