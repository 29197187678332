export const MATCH = "MATCH";
export const COMFORT = "COMFORT";
export const TRAINING = "TRAINING";
export const HALFFIELDLEFT = "HALFFIELDLEFT";
export const HALFFIELDRIGHT = "HALFFIELDRIGHT";
export const OFF = "OFF";
export const TURN_OFF_FIELD_LIGHTING = "TURN_OFF_FIELD_LIGHTING";
export const FACILITIES = "FACILITIES";
export const MONITOR = "MONITOR";
export const FIELDS = "FIELDS";
export const FIELD_SCHEDULE = "FIELD_SCHEDULE";
export const MASTER_SCHEDULE = "MASTER_SCHEDULE";
export const CONTROL = "CONTROL";
export const SCHEDULE = "SCHEDULE";
export const MON = "MON";
export const TUE = "TUE";
export const WED = "WED";
export const THU = "THU";
export const FRI = "FRI";
export const SAT = "SAT";
export const SUN = "SUN";
export const WEEK = "WEEK";
export const DAY = "DAY";
export const MONTH = "MONTH";
export const SUNDAY = "SUNDAY";
export const MONDAY = "MONDAY";
export const TUESDAY = "TUESDAY";
export const WEDNESDAY = "WEDNESDAY";
export const THURSDAY = "THURSDAY";
export const FRIDAY = "FRIDAY";
export const SATURDAY = "SATURDAY";
export const JAN = "JAN";
export const FEB = "FEB";
export const MAR = "MAR";
export const APR = "APR";
export const MAY = "MAY";
export const JUN = "JUN";
export const JUL = "JUL";
export const AUG = "AUG";
export const SEP = "SEP";
export const OCT = "OCT";
export const NOV = "NOV";
export const DEC = "DEC";
export const TODAY = "TODAY";
export const CREATE_EVENT_BUTTON = "CREATE_EVENT_BUTTON";
export const NEW_EVENT = "NEW_EVENT";
export const LABEL_BASEBALL = "LABEL_BASEBALL";
export const LABEL_PARKINGLOT = "LABEL_PARKINGLOT";
export const LABEL_KORFBALL = "LABEL_KORFBALL";
export const LABEL_SWIMMINGPOOL = "LABEL_SWIMMINGPOOL";
export const LABEL_HANDBALL = "LABEL_HANDBALL";
export const LABEL_AIRPORT = "LABEL_AIRPORT";
export const LABEL_GENERAL = "LABEL_GENERAL";
export const LABEL_HOCKEY = "LABEL_HOCKEY";
export const LABEL_TENNIS = "LABEL_TENNIS";
export const LABEL_RUGBY = "LABEL_RUGBY";
export const LABEL_GOLF = "LABEL_GOLF";
export const LABEL_ATHLETICS = "LABEL_ATHLETICS";
export const LABEL_BASKETBALL = "LABEL_BASKETBALL";
export const LABEL_TABLETENNIS = "LABEL_TABLETENNIS";
export const LABEL_FOOTBALL = "LABEL_FOOTBALL";
export const LABEL_HARBOR = "LABEL_HARBOR";
export const SIGNOUT = "SIGNOUT";
export const NOTFOUND_404 = "NOTFOUND_404";
export const RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND";
export const CREATE_RULE = "CREATE_RULE";
export const CANCEL_BUTTON = "CANCEL_BUTTON";
export const REMOVE_BUTTON = "REMOVE_BUTTON";
export const SAVE_BUTTON = "SAVE_BUTTON";
export const SUNSET_SETTING = "SUNSET_SETTING";
export const POWER_ON = "POWER_ON";
export const POWER_OFF = "POWER_OFF";
export const FROM = "FROM";
export const TO = "TO";
export const CREATE_NEW_RULE = "CREATE_NEW_RULE";
export const EDIT_RULE = "EDIT_RULE";
export const SUNRISE_SETTING = "SUNRISE_SETTING";
export const DATE = "DATE";
export const MASTERSCHEDULE_NEW_RULE = "MASTERSCHEDULE_NEW_RULE";
export const RANGE = "RANGE";
export const GENERATE = "GENERATE";
export const EXPORT = "EXPORT";
export const NO_FIELDS = "NO_FIELDS";
export const NO_SCENE_ACTIVE = "NO_SCENE_ACTIVE";
export const TURN_OFF_ALL_LIGHTING = "TURN_OFF_ALL_LIGHTING";
export const USAGE_PAGE = "USAGE_PAGE";
export const ALERTS_PAGE = "ALERTS_PAGE";
export const ACCEPT = "ACCEPT";
export const SPORTS = "SPORTS";
export const LICENSE_AGREEMENT = "LICENSE_AGREEMENT";
export const IAS_LICENSE_AGREEMENT = "IAS_LICENSE_AGREEMENT";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const ABOUT = "ABOUT";
export const TWILIGHT_ENABLE_LABEL = "TWILIGHT_ENABLE_LABEL";
export const TWILIGHT_ENABLED_LABEL = "TWILIGHT_ENABLED_LABEL";
export const TWILIGHT_RULE_INFO = "TWILIGHT_RULE_INFO";
export const TWILIGHT_ENABLE_MSG = "TWILIGHT_ENABLE_MSG";
export const REPEAT = "REPEAT";
export const END_REPEAT = "END_REPEAT";
export const NETWORK_DIALOG_CLOSE = "NETWORK_DIALOG_CLOSE";
export const NO_INTERNET = "NO_INTERNET";
export const INTERNET_LOST = "INTERNET_LOST";
export const START_END_TIME_REQUIRED = "START_END_TIME_REQUIRED";
export const MASTER_SCHEDULE_SAVE_SUCCESS = "MASTER_SCHEDULE_SAVE_SUCCESS";
export const MASTER_SCHEDULE_DELETE_CONFIRM = "MASTER_SCHEDULE_DELETE_CONFIRM";
export const MASTER_SCHEDULE_DELETE_SUCCESS = "MASTER_SCHEDULE_DELETE_SUCCESS";
export const MASTER_SCHEDULE_DELETE_FAILURE = "MASTER_SCHEDULE_DELETE_FAILURE";
export const FIELD_SCHEDULE_SAVE_SUCCESS = "FIELD_SCHEDULE_SAVE_SUCCESS";
export const FIELD_SCHEDULE_DELETE_CONFIRM = "FIELD_SCHEDULE_DELETE_CONFIRM";
export const FIELD_SCHEDULE_DELETE_SUCCESS = "FIELD_SCHEDULE_DELETE_SUCCESS";
export const FIELD_SCHEDULE_DELETE_FAILURE = "FIELD_SCHEDULE_DELETE_FAILURE";
export const USER_NO_SCENE_PERMISSION = "USER_NO_SCENE_PERMISSION";
export const FIRST = "FIRST";
export const SECOND = "SECOND";
export const THIRD = "THIRD";
export const FOURTH = "FOURTH";
export const FIFTH = "FIFTH";
export const MINUTES = "MINUTES";
export const NEVER = "NEVER";
export const DAILY = "DAILY";
export const EVERY_ALTERNATE = "EVERY_ALTERNATE";
export const PREFERENCES = "PREFERENCES";
export const LANGUAGE = "LANGUAGE";
export const LANGUAGE_SPELLING = "LANGUAGE_SPELLING";
export const LABELGENERAL = "LABEL_GENERAL";
export const DEFAULT_ERROR_MESSAGE = "DEFAULT_ERROR_MESSAGE";
export const ENERGY_USAGE = "ENERGY_USAGE";
export const DEFAULT_MONITOR_MESSAGE_PART_ONE = "DEFAULT_MONITOR_MESSAGE_PART_ONE";
export const DEFAULT_MONITOR_MESSAGE_PART_TWO = "DEFAULT_MONITOR_MESSAGE_PART_TWO";
export const MONITOR_ERROR_MESSAGE = "MONITOR_ERROR_MESSAGE";
export const NONE_SELECTED = "NONE_SELECTED";
export const SELECTED = "SELECTED";
export const FIELD_LIGHTING_CONTROL = "FIELD_LIGHTING_CONTROL";
export const SCENE = "SCENE";
export const CREATE_EVENT = "CREATE_EVENT";
export const OK = "OK";
export const EVERY = "EVERY";
export const MINUTE = "MINUTE";
export const ACCESS_DENIED_LABEL = "ACCESS_DENIED_LABEL";
export const ACCESS_DENIED_MESSAGE = "ACCESS_DENIED_MESSAGE";
export const WEEKLY = "WEEKLY";
export const BIWEEKLY = "BIWEEKLY";
export const EDIT_OCCURRENCE = "EDIT_OCCURRENCE";
export const EDIT_SERIES = "EDIT_SERIES";
export const DELETE = "DELETE";
export const HALF_FIELD = "HALF_FIELD";
export const EVENT = "EVENT";
export const DELETE_SERIES_OCCURRENCE_MESSAGE = "DELETE_SERIES_OCCURRENCE_MESSAGE";
export const DELETE_OCCURRENCE = "DELETE_OCCURRENCE";
export const DELETE_SERIES = "DELETE_SERIES";
export const FIELD_SCHEDULE_UPDATE_SUCCESS = "FIELD_SCHEDULE_UPDATE_SUCCESS";
export const MASTER_SCHEDULE_UPDATE_SUCCESS = "MASTER_SCHEDULE_UPDATE_SUCCESS";
export const FIXED_SCHEDULE = "FIXED_SCHEDULE";
export const EDIT_SCHEDULE_PROGRESS_MESSAGE = "EDIT_SCHEDULE_PROGRESS_MESSAGE";
export const EDIT_SCHEDULE_ACCEPT_MESSAGE = "EDIT_SCHEDULE_ACCEPT_MESSAGE";
export const TWILIGHT_SCHEDULE = "TWILIGHT_SCHEDULE";
export const POLLING_MESSAGE = "POLLING_MESSAGE";
export const PENDING_SAVE = "PENDING_SAVE";
export const ACTUALS = "ACTUALS";
export const PitchTypeForTranslation = [
  {
    Key: "GENERAL",
    Value: "LABEL_GENERAL"
  },
  {
    Key: "HOCKEY",
    Value: "LABEL_HOCKEY"
  },
  {
    Key: "TENNIS",
    Value: "LABEL_TENNIS"
  },
  {
    Key: "RUGBY",
    Value: "LABEL_RUGBY"
  },
  {
    Key: "GOLF",
    Value: "LABEL_GOLF"
  },
  {
    Key: "ATHLETICS",
    Value: "LABEL_ATHLETICS"
  },
  {
    Key: "BASKETBALL",
    Value: "LABEL_BASKETBALL"
  },
  {
    Key: "TABLE TENNIS",
    Value: "LABEL_TABLETENNIS"
  },
  {
    Key: "FOOTBALL",
    Value: "LABEL_FOOTBALL"
  },
  {
    Key: "HARBOR",
    Value: "LABEL_HARBOR"
  },
  {
    Key: "BASEBALL",
    Value: "LABEL_BASEBALL"
  },
  {
    Key: "PARKING",
    Value: "LABEL_PARKINGLOT"
  },
  {
    Key: "KORFBALL",
    Value: "LABEL_KORFBALL"
  },
  {
    Key: "SWIMMINGPOOL",
    Value: "LABEL_SWIMMINGPOOL"
  },
  {
    Key: "HANDBALL",
    Value: "LABEL_HANDBALL"
  },
  {
    Key: "AIRPORT",
    Value: "LABEL_AIRPORT"
  },
];

export const SceneTypeForTranslation = [
  {
    Key: "COMFORT",
    Value: "COMFORT"
  },
  {
    Key: "Comfort",
    Value: "COMFORT"
  },
  {
    Key: "Match",
    Value: "MATCH"
  },
  {
    Key: "Training",
    Value: "TRAINING"
  },
  {
    Key: "Half Field (right)",
    Value: "HALFFIELDRIGHT"
  },
  {
    Key: "Half Field (left)",
    Value: "HALFFIELDLEFT"
  },
  {
    Key: "Off",
    Value: "OFF"
  },
  {
    Key: "TURN_OFF_ALL_LIGHTING",
    Value: "TURN_OFF_ALL_LIGHTING"
  },
  {
    Key: "TURN_OFF_FIELD_LIGHTING",
    Value: "TURN_OFF_FIELD_LIGHTING"
  },
  {
    Key: "training-left",
    Value: "HALFFIELDLEFT"
  },
  {
    Key: "training-right",
    Value: "HALFFIELDRIGHT"
  },
  {
    Key: "TrainingLeft",
    Value: "HALFFIELDLEFT"
  },
  {
    Key: "TrainingRight",
    Value: "HALFFIELDRIGHT"
  }
];

export const MonitorDropDown = [
  {
    Key: "A Day",
    Value: "A_DAY"
  },
  {
    Key: "days",
    Value: "DAYS"
  },
  {
    Key: "Days",
    Value: "DAYS"
  },
  {
    Key: "months",
    Value: "MONTHS"
  },
  {
    Key: "Months",
    Value: "MONTHS"
  },
  {
    Key: "Years",
    Value: "YEARS"
  },
  {
    Key: "years",
    Value: "YEARS"
  }];
 