import React from "react";
import PropTypes from "prop-types";

const SceneHalfFieldRight = props => {
  const svgStyle = {
    fill: props.active === "true" ? "#FF375F" : "#3c3c41"
  };
  return (
    /*
    * pre - 24-04-2023
    * <div className="scene-container" > removed scene-container as it doesn't have any response on UI on multiple resolutions
    */
    
    <div className="scene-container">
      <svg id="icon-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 48">
        <title>{props.label}</title>
        <desc>Created with sketchtool.</desc>
        <defs>
          <path
            style={svgStyle}
            d="M9,54 L33,54 L33,18 L9,18 L9,54 Z M39,54 L42,54 L42,60 L9,60 C5.688,60 3,57.312 3,54 L3,48 L3,24 L3,18 C3,14.688 5.688,12 9,12 L42,12 L42,18 L39,18 L39,54 Z M48,60 L48,54 L54,54 L54,60 L48,60 Z M48,18 L48,12 L54,12 L54,18 L48,18 Z M63,12 C66.312,12 69,14.688 69,18 L69,21 L63,21 L63,18 L60,18 L60,12 L63,12 Z M63,33 L63,27 L69,27 L69,33 L63,33 Z M63,54 L63,51 L69,51 L69,54 C69,57.312 66.312,60 63,60 L60,60 L60,54 L63,54 Z M63,45 L63,39 L69,39 L69,45 L63,45 Z"
            id="path-2"
          />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-646.000000, -440.000000)">
            <g transform="translate(336.000000, 44.000000)">
              <g transform="translate(343.000000, 420.000000) rotate(180.000000) translate(-343.000000, -420.000000) translate(307.000000, 384.000000)">
                <mask fill="white">
                  <use href="#path-2" />
                </mask>
                <use style={svgStyle} href="#path-2" />
                <g mask="url(#mask-2)" style={svgStyle}>
                  <rect style={svgStyle} x="0" y="0" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

SceneHalfFieldRight.propTypes = {
  active: PropTypes.string,
  label: PropTypes.string
};

SceneHalfFieldRight.defaultProps = {
  active: "",
  label: ""
};

export default SceneHalfFieldRight;
