import EnergyManagement from "../Consumption/EnergyManagement";
import Service from "../helpers/Service";
import ApiErrorHandler from "../helpers/ApiErrorHandler";
import LanguageSelector from "../Language/LanguageSelector";

class EnergyApi {
  static getEnergyData(sites, fromDate, toDate, aggtype) {
    LanguageSelector.SelectLanguage();
    const payload = {
      SiteIds: sites,
      StartDateTime: fromDate,
      EndDateTime: toDate,
      ReportType: aggtype
    };
    return Service.post("/api/Energy/Consumption", payload)
      .then(json => {
        EnergyManagement.setEnergyData(json.data);
        return json;
      })
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }
}
export default EnergyApi;
