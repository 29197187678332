import React from "react";
import PropTypes from "prop-types";
import Scene from "./Scene";

class SceneRow extends React.Component {
  constructor(props) {
    super(props);
    this.scenesPerRow = this.scenesPerRow.bind(this);
    this.scenesInRow = this.scenesInRow.bind(this);
    this.handleSceneChange = this.handleSceneChange.bind(this);
  }

  handleSceneChange(scene) {
    this.props.handleSceneClick(scene);
  }

  scenesInRow(scenes) {
    if (scenes.length === 2) {
      scenes.push("");
    }
    return (
      <ul className="d-flex flex-wrap list-unstyled justify-content-around pt-4">
        {scenes.map(item => (
          <Scene
            sceneName={item}
            active={this.props.active === item.displayScene ? "true" : "false"}
            isEnabled={item.isEnable}
            onSceneClick={scene => this.handleSceneChange(scene)}
            key={item.sceneId}
          />
        ))}
      </ul>
    );
  }

  scenesPerRow(scenes) {
    const result = [];
    if (scenes.length <= 4) {
      while (scenes.length >= 2) {
        result.push(this.scenesInRow(scenes.slice(0, 2)));
        scenes.shift();
        scenes.shift();
      }
      if (scenes.length !== 0) {
        result.push(this.scenesInRow(scenes));
      }
    }
    if (scenes.length > 4) {
      while (scenes.length >= 3) {
        result.push(this.scenesInRow(scenes.slice(0, 3)));
        scenes.shift();
        scenes.shift();
        scenes.shift();
      }
      if (scenes.length !== 0) {
        result.push(this.scenesInRow(scenes));
      }
    }
    return result;
  }

  render() {
    const result = this.scenesPerRow(this.props.scenes);
    return result;
  }
}
SceneRow.propTypes = {
  scenes: PropTypes.instanceOf(Array),
  active: PropTypes.string,
  handleSceneClick: PropTypes.func
};

SceneRow.defaultProps = {
  scenes: [],
  active: "",
  handleSceneClick: null
};
export default SceneRow;
