import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { FormGroup, ControlLabel } from "react-bootstrap";
import Datetime from "react-datetime";
import "../css/Schedule.css";
import {
  MasterFrequency,
  FrequencyTypes
} from "../constants/FrequencyConstants";
import {
  daysOfWeekKVP
} from "../constants/DayOfWeekConstants";
import * as Labels from "../constants/Labels";
import LanguageSelector from "../Language/LanguageSelector";

// const RepeatStyle = {
//   marginBottom: "10px"
// };

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["validStartSelection"] }] */
class RecurringSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frequencyType: this.props.defaultfrequencyType,
      start: this.props.start,
      end: this.props.end
    };
    this.handleFrequencyTypeSelection = this.handleFrequencyTypeSelection.bind(
      this
    );
    this.getOptionText = this.getOptionText.bind(this);
    this.validEndSelection = this.validEndSelection.bind(this);
    this.handleRecurringEndDateChange = this.handleRecurringEndDateChange.bind(
      this
    );
    this.handleDaysOfWeekChange = this.handleDaysOfWeekChange.bind(this);
    this.isChecked = this.isChecked.bind(this);
  }

  handleFrequencyTypeSelection(frequencyType) {
    this.setState({
      frequencyType
    });
    this.props.onFrequencyTypeChange(frequencyType);
  }

  handleRecurringEndDateChange(e) {
    const endDate = e.toDate();
    if (moment(endDate).isSameOrBefore(this.state.start)) return;
    this.setState({
      end: endDate
    });
    this.props.onRecurringEndDateChange(endDate);
  }

  handleDaysOfWeekChange(e, item) {
    this.props.onDaysOfWeekChange(item.Key, e.target.checked)
  }
  
  getOptionText(frequencyType) {
    switch (frequencyType) {
      case FrequencyTypes.Never:
        return i18next.t(Labels.NEVER);
      case FrequencyTypes.Daily:
        return i18next.t(Labels.DAILY);
      case FrequencyTypes.Weekly:
        return i18next.t(Labels.WEEKLY);
      case FrequencyTypes.Monthly:
        return `${i18next.t(Labels.EVERY)} ${i18next.t((this.props.weekOfMonth).toUpperCase())} ${i18next.t((this.props.dayOfWeek).toUpperCase())}`;
      case FrequencyTypes.BiWeekly:
        return i18next.t(Labels.BIWEEKLY);
      default:
        return FrequencyTypes.Never;
    }
  }

  validEndSelection(currentDate) {
    const startDate = moment(this.state.start).subtract(1, "day");
    return currentDate.isAfter(startDate);
  }

  isChecked(dayOfWeek) {
    const checked = this.props.defaultDaysOfWeek.includes(dayOfWeek.Key);

    return checked;
  }

  render() {
    const frequencyTypes = [];

    MasterFrequency.forEach(item => {
      frequencyTypes.push(
        // <option key={item.Key} value={item.Value}>
        //   {this.getOptionText(item.Value)}
        // </option>
        <li
          key={item.Key}
          className="dropdown-item eua-time-select-li"
          role="option"
          aria-selected={false}
          data-value={item.Value}
          onClick={event =>
            this.handleFrequencyTypeSelection(
              event.target.getAttribute("data-value")
            )
          }
          onKeyPress={event =>
            this.handleFrequencyTypeSelection(
              event.target.getAttribute("data-value")
            )
          }
        >
          {this.getOptionText(item.Value)}
        </li>
      );
    });

    const daysofWeekList = [];
    daysOfWeekKVP.forEach(item => {
      daysofWeekList.push(
        <label className="checkbox-container">
          <input
            type="checkbox"
            id={item.Key}
            name={item.Value}
            value={item.Value}
            onChange={event => this.handleDaysOfWeekChange(event, item)}
            checked={this.isChecked(item)}
          />
          {i18next.t(item.Value.toUpperCase())}
        </label>
      );
    });

    let recurringSchedule = null;
    if (this.state.frequencyType !== FrequencyTypes.Never)
      recurringSchedule = (
        <FormGroup controlId="formControlsDate" className="col-md-6">
          <ControlLabel>{i18next.t(Labels.END_REPEAT)}</ControlLabel>
          <Datetime
            locale={LanguageSelector.SelectLanguage(this.props.siteId)}
            defaultValue={this.state.end}
            value={this.state.end}
            inputProps={{ readOnly: true }}
            closeOnSelect
            disableOnClickOutside={false}
            onChange={this.handleRecurringEndDateChange}
            isValidDate={this.validEndSelection}
            timeFormat={false}
          />
        </FormGroup>
      );

    let dayOfWeekCheckboxList = null;
    if (this.state.frequencyType === FrequencyTypes.Weekly || this.state.frequencyType === FrequencyTypes.BiWeekly)
      dayOfWeekCheckboxList = (
        <FormGroup className="checkbox-formgroup">
          {daysofWeekList}
        </FormGroup>
      );
    return (
      <>
        <FormGroup controlId="formControlsFromTime" className="col-6">
          <ControlLabel>{i18next.t(Labels.REPEAT)}</ControlLabel>
          <div className="dropdown dropdown-background">
            <button
              style={{
                border: "inherit !important",
                borderColor: "inherit !important",
                backgroundColor: "inherit !important"
              }}
              className="btn btn-secondary dropdown-toggle eua-time-select-btn"
              type="button"
              id="dropdownMenuButton5"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>{this.getOptionText(this.state.frequencyType)}</span>
            </button>
            <ul
              className=" dropdown-menu eua-time-select-ul"
              aria-labelledby="dropdownMenuButton5"
            >
              {frequencyTypes}
            </ul>
          </div>
        </FormGroup>
        {recurringSchedule}
        {dayOfWeekCheckboxList}
      </>
    );
  }
}

RecurringSchedule.propTypes = {
  onFrequencyTypeChange: PropTypes.func.isRequired,
  dayOfWeek: PropTypes.string,
  weekOfMonth: PropTypes.string,
  defaultfrequencyType: PropTypes.string,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
  onRecurringEndDateChange: PropTypes.func.isRequired,
  siteId: PropTypes.string,
  onDaysOfWeekChange: PropTypes.func.isRequired,
  defaultDaysOfWeek: PropTypes.instanceOf(Array).isRequired,
};

RecurringSchedule.defaultProps = {
  defaultfrequencyType: FrequencyTypes.Never,
  start: new Date(),
  end: new Date(),
  dayOfWeek: null,
  weekOfMonth: null,
  siteId: null
};
export default RecurringSchedule;