import React from "react";

const Caret = () => (
  <svg viewBox="0 0 16 16" version="1.1">
    <desc>Created with sketchtool.</desc>
    <defs>
      <polygon
        id="path-1000"
        points="2.66666667 6 7.99566856 11.3333333 13.3333333 6"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1000" />
        </mask>
        <use fill="#FFFFFF" xlinkHref="#path-1000" />
      </g>
    </g>
  </svg>
);

export default Caret;
