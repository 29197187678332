import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import "../css/Schedule.css";
import GifImageContainer from "../components/GifImageContainer";
import SchedulingApi from "../api/SchedulingApi";
import FieldRow from "../FieldRow";
import ScheduleManagement from "../Schedule/ScheduleManagement";
import { ApplicationTypes } from "../constants/AppConstants";

class Control extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      isLoading: true
    };
    this.fetchFields = this.fetchFields.bind(this);
  }

  componentDidMount() {
    this.fetchFields(this.props.selectedSite.id);
  }

  fetchFields(siteId) {
    const customScenes = SchedulingApi.getScenes(siteId);
    const fieldsForSite = SchedulingApi.getFieldsForSite(siteId);

    Promise.all([fieldsForSite, customScenes]).then(() => {
      const fields = ScheduleManagement.getFields();
      const scenes = ScheduleManagement.getScenes();
      const fieldswithCustomScenes = fields.map(field => {
        // eslint-disable-next-line no-param-reassign
        field.customScenes = scenes.fieldLevelCustomScenes.find(
          f => f.fieldId === field.id
        ).customScenes;
        return field;
      });
      this.setState({ fields: fieldswithCustomScenes, isLoading: false });
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="eua-LoadingContainer-Screen flexbox-centered-items content">
          <GifImageContainer alt="Loading" src="/images/loading.gif" />
        </div>
      );
    }
    const defaultField = this.props.selectedField
      ? this.state.fields.find(field => field.id === this.props.selectedField)
      : this.state.fields[0];
    if (this.props.selectedField && !defaultField) {
       return <Redirect to="/NotFound" />
    }
    return (
      <FieldRow
        key={`${this.props.selectedSite.Id}control`}
        comp={ApplicationTypes.Control}
        fields={this.state.fields}
        defaultField={defaultField}
        selectedSite={this.props.selectedSite}
      />
    );
  }
}

Control.propTypes = {
  selectedSite: PropTypes.instanceOf(Object),
  selectedField: PropTypes.string
};

Control.defaultProps = {
  selectedSite: {},
  selectedField: ""
};
export default Control;
