import React from "react";

const User = () => (
  <svg viewBox="0 0 22 22" version="1.1">
    <desc>Created with sketchtool.</desc>
    <defs>
      <path
        d="M20.1753752,19.3520249 C20.1753752,19.3474416 20.1726252,19.3437749 20.1726252,19.3391916 C20.0103752,15.7348583 16.9487085,12.8336083 13.2187918,12.8336083 L8.79037511,12.8336083 C5.05954174,12.8336083 1.9987917,15.7348583 1.83562503,19.3391916 C1.83562503,19.3437749 1.83287503,19.3474416 1.83287503,19.3520249 C1.83287503,19.3529416 1.8337917,19.3547749 1.8337917,19.3566083 C1.8337917,19.3602749 1.83287503,19.3639416 1.83287503,19.3676083 C1.8337917,19.3666916 1.83562503,19.3666916 1.8365417,19.3666916 C1.8447917,19.8021083 2.20137504,20.1504416 2.64595838,20.1623583 C2.64595838,20.1641916 2.64595838,20.1651083 2.64504171,20.1669416 L19.3632086,20.1669416 C19.3622919,20.1651083 19.3632086,20.1641916 19.3622919,20.1623583 C19.8077919,20.1504416 20.1634586,19.8021083 20.1726252,19.3666916 C20.1735419,19.3666916 20.1744586,19.3666916 20.1753752,19.3676083 C20.1753752,19.3639416 20.1744586,19.3602749 20.1744586,19.3566083 C20.1744586,19.3547749 20.1753752,19.3529416 20.1753752,19.3520249 M11.0013751,10.9993579 C13.5377918,10.9993579 15.5929585,8.94694132 15.5929585,6.41602475 C15.5929585,3.88510819 13.5377918,1.83360827 11.0013751,1.83360827 C8.4667917,1.83360827 6.41070833,3.88510819 6.41070833,6.41602475 C6.41070833,8.94694132 8.4667917,10.9993579 11.0013751,10.9993579"
        id="path-1222"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1222" />
        </mask>
        <use fill="#FFFFFF" xlinkHref="#path-1222" />
      </g>
    </g>
  </svg>
);

export default User;
