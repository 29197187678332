import React from "react";

const Filter = () => (
  <svg width="17px" height="16px" viewBox="0 0 17 16">
    <defs>
      <path
        d="M13.3774672,1.33333333 L2.62746675,1.33333333 C2.06946672,1.33333333 1.79013337,1.986 2.18480006,2.36733333 L6.66880027,7.99266667 L6.66880027,12.6666667 L9.33546706,14.6666667 L9.33546706,8 L13.8201339,2.36733333 C14.2148006,1.986 13.9354673,1.33333333 13.3774672,1.33333333"
        id="path-filter"
      />
    </defs>
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="icon-right-icon-/-action-and-navigation-/-filter"
        transform="translate(0.893094, 0.000000)"
      >
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-filter" />
        </mask>
        <use id="↳-Color" fill="#3C3C41" xlinkHref="#path-filter" />
      </g>
    </g>
  </svg>
);
export default Filter;
