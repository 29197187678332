import React from "react";
import moment from "moment";
import "../css/calendar.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import NextIcon from "../Icons/ChevronRight";
import BackIcon from "../Icons/ChevronLeft";

const DayView = "day";
const CalendarNavigationToolbar = toolbar => {
  const goToBack = () => {
    toolbar.onNavigate("PREV");
  };

  const goToNext = () => {
    toolbar.onNavigate("NEXT");
  };

  const label = () => {
    const date = moment(toolbar.date).locale(localStorage.getItem("CalendarLocale"));
    return (
      <span>
        {toolbar.view !== DayView && (
          <span> {`${date.format("MMM")} ${date.format("YYYY")}`}</span>
        )}
        {toolbar.view === DayView && (
          <span>
            {`${date.format("MMM")} ${date.format("DD")}, ${date.format(
              "YYYY"
            )}`}
          </span>
        )}
      </span>
    );
  };

  return (
    <div className="eua-calendar-navigation-container">
      <button
      type="button"
        className="eua-calendar-navigation-icon eua-icon-button"
        onClick={goToBack}
        aria-label="backIcon"
      >
        <BackIcon className="prev-icon" />
      </button>
      <div className="eua-calendar-datelabel">{label()}</div>
      <button
      type="button"
        className="eua-calendar-navigation-icon eua-icon-button"
        onClick={goToNext}
        aria-label="nextIcon"
      >
        <NextIcon className="next-icon" />
      </button>
    </div>
  );
};

export default CalendarNavigationToolbar;
