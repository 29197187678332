/* eslint-disable no-restricted-globals */
import React,{useState} from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import { EnergyFrequencyTypes } from "../constants/MonitorTypeConstants";
// import SiteGraph from "../Icons/SiteGraph";
import * as Labels from "../constants/Labels";

const SiteConsumptionDetails = props => {
  const [inHover, setHover] = useState(false);
  const average = rangeType => {
    switch (rangeType) {
      case EnergyFrequencyTypes.Day:
        return "Hourly average";
      case EnergyFrequencyTypes.SevenDays:
      case EnergyFrequencyTypes.Month:
        return "Daily average";
      case EnergyFrequencyTypes.Year:
      case EnergyFrequencyTypes.ThreeMonths:
        return "Monthly average";
        case EnergyFrequencyTypes.FiveYears:
        return "Yearly average";
      default:
        return "";
    }
  };
  const avgType = average(props.rangeType);
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      role="list"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
      className={
        props.siteActiveState
          ? "row site-consumption-list-active "
          : "row site-consumption-list "
      }
      onClick={() => props.handleSiteClick(props.siteDetails.siteId)}
      onKeyPress={() => props.handleSiteClick(props.siteDetails.siteId)}
    >{inHover  && <span className="tooltiptext">{props.siteDetails.siteName}</span>}
      <div
        className="col-sm-1 site-consumption-listitem "
      
        style={{ backgroundColor: props.siteDetails.color.Maincolor }}
      />
      
      <div
        className="col-sm-11"
      
        
        // style={{ borderColor: props.siteDetails.color.Maincolor }}
      >
         
        <p className="site-consumption-sitename mt-2 mb-2">
          {props.siteDetails.siteName}
        </p>
        <p className="site-consumption-total mb-1">
          * {i18next.t(Labels.ACTUALS)}:  {isNaN(props.siteDetails.total) ? 0:props.siteDetails.total} kWh
        </p>
        <p className="site-consumption-average mb-1">
          {avgType}: {isNaN(props.siteDetails.avg) ? 0:props.siteDetails.avg} kWh
        </p>
        {/* <div className="energy-graph-site-icon">
          <SiteGraph
            backgroundColor={props.siteDetails.color.bgColor}
            Maincolor={props.siteDetails.color.Maincolor}
          />
        </div> */}
      </div>
    </div>
  );
};
SiteConsumptionDetails.propTypes = {
  rangeType: PropTypes.string,
  siteDetails: PropTypes.instanceOf(Object),
  siteActiveState: PropTypes.bool,
  handleSiteClick: PropTypes.func
};

SiteConsumptionDetails.defaultProps = {
  rangeType: "",
  siteDetails: {},
  siteActiveState: false,
  handleSiteClick: null
};

export default SiteConsumptionDetails;
