import React from "react";
import i18next from "i18next";
import { Redirect } from "react-router-dom";
import ScheduleManagement from "./Schedule/ScheduleManagement";
import "./css/Schedule.css";
import { Applications } from "./constants/AppConstants";
import Site from "./Site";
import LanguageSelector from "./Language/LanguageSelector";

const MainDashboard = () => {
  const sites = ScheduleManagement.getSites();
  const defaultselectedSite = sites[0];

  const language = LanguageSelector.SelectLanguage(defaultselectedSite.id);
  i18next.changeLanguage(language);
  let selectedApp;
  if (
    defaultselectedSite.appPermissions.filter(a => a === Applications[0].Key)
      .length > 0
  )
    selectedApp = Applications[0].Value;
  else
    selectedApp = Applications.filter(
      a => a.Key === defaultselectedSite.appPermissions[0]
    )[0].Value;

  let dashboardDisplay;

  if (sites.length > 1)
    dashboardDisplay = (
      <Site sites={sites} defaultSelectedSite={defaultselectedSite} />
    );
  else
    dashboardDisplay = (
      <Redirect
        to={`/Sites/${defaultselectedSite.id}/${selectedApp}`}
      />
    );

  return dashboardDisplay;
};

export default MainDashboard;
