class EditedScheduleManagement {
  constructor() {
    this.editedSchedules =[] ;
  }

  addEditedScheduleList(data)
  {
    this.editedSchedules.push(data);
  }

  getEditedSchedulesList()
  {
    return this.editedSchedules;
  }

  removeEditedScheduleList(data)
  {
    this.editedSchedules.pop(data);
  }
}

const instance = new EditedScheduleManagement();
export default instance;
