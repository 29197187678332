const labelGeneral = "General fields";
const labelHockey = "Hockey fields";
const labelTennis = "Tennis fields";
const labelRugby = "Rugby fields";
const labelGolf = "Golf fields";
const labelAthlectics = "Athletics fields";
const labelBasketball = "Basketball fields";
const labelTableTennis = "Table tennis fields";
const labelFootball = "Football fields";
const labelHarbor = "Harbor";
const labelBaseBall = "Baseball fields";
const labelParkingLot = "Parking Lot";
const labelKorfball = "Korfball fields";
const labelSwimmingpool = "Swimming pools";
const labelHandball = "Handball fields";
const labelAirport = "Airport fields";
const PitchTypes = {
  General: "general",
  Hockey: "hockey",
  Tennis: "tennis",
  Rugby: "rugby",
  Golf: "golf",
  Athlectics: "athletics",
  Basketball: "basketball",
  TableTennis: "table tennis",
  Football: "football",
  Harbor: "harbor",
  Baseball: "baseball",
  Parking: "parking",
  Korfball: "korfball",
  Swimmingpool: "swimmingpool",
  Handball: "handball",
  Airport: "airport"
};

const Facilities = {
  SportsFacilities: "Sports",
  NonSportsFacilities: "Non-Sports"
};

const PitchTypesLables = [
  {
    Key: PitchTypes.General,
    Value: labelGeneral,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Hockey,
    Value: labelHockey,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Tennis,
    Value: labelTennis,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Rugby,
    Value: labelRugby,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Golf,
    Value: labelGolf,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Athlectics,
    Value: labelAthlectics,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Basketball,
    Value: labelBasketball,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.TableTennis,
    Value: labelTableTennis,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Football,
    Value: labelFootball,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Harbor,
    Value: labelHarbor,
    Facilities: Facilities.NonSportsFacilities
  },
  {
    Key: PitchTypes.Baseball,
    Value: labelBaseBall,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Parking,
    Value: labelParkingLot,
    Facilities: Facilities.NonSportsFacilities
  },
  {
    Key: PitchTypes.Korfball,
    Value: labelKorfball,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Swimmingpool,
    Value: labelSwimmingpool,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Handball,
    Value: labelHandball,
    Facilities: Facilities.SportsFacilities
  },
  {
    Key: PitchTypes.Airport,
    Value: labelAirport,
    Facilities: Facilities.NonSportsFacilities
  }
];

export { PitchTypes, PitchTypesLables, Facilities };
