import React from "react";

const svgStyle = {
  fill: "#1432FF",
  fillRule: "evenodd",
  clipRule: "evenodd"
};

const Info = () => (
  <svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    style={svgStyle}
    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM8.182 3.636C8.182 3.134 8.589 2.727 9.091 2.727H10C10.502 2.727 10.909 3.134 10.909 3.636V4.545C10.909 5.047 10.502 5.455 10 5.455H9.091C8.589 5.455 8.182 5.047 8.182 4.545V3.636ZM14 17H6V15H9V9H7V7H11V15H14V17Z"
  />
</svg>
);

export default Info;
