import React from 'react';
import i18next from "i18next";
import HomeApi from './api/HomeApi';
import * as Labels from "./constants/Labels";

const textDanger = {
  color: '#b94a48',
};

const logoutIconContainerStyle = {
  cursor: 'pointer',
  textDecoration: 'underline',
};

const AccessDenied = () => (

  <div className="Not-Found">
  <h1 style={textDanger}>{i18next.t(Labels.ACCESS_DENIED_LABEL)}</h1>
    <h2 className={textDanger}>
      {i18next.t(Labels.ACCESS_DENIED)}
    </h2>
    <p>
      <span
        role="menuitem"
        tabIndex="0"
        style={logoutIconContainerStyle}
        onClick={() => HomeApi.signOut()}
        onKeyUp={() => HomeApi.signOut()}
      >
        {i18next.t(Labels.SIGNOUT)}
      </span>
      &nbsp;{i18next.t(Labels.ACCESS_DENIED_MESSAGE)}</p>

  </div>
);

export default AccessDenied;
