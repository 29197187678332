/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import i18next from "i18next";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import moment from "moment";
import Datetime from "react-datetime";
import CalendarIcon from "../Icons/calendar";
import {
  EnergyFrequencyTypes,
  EnergyFrequencyTypesList
} from "../constants/MonitorTypeConstants";
import EnergyAggregationHelper from "../helpers/EnergyAggregationHelper";
import MultiSelectDropDownList from "../components/MultiSelectDropDownList";
import * as Labels from "../constants/Labels";
import LanguageSelector from "../Language/LanguageSelector";

class AggregationFilter extends React.Component {
  constructor(props) {
    super(props);

    // const defaultDate = moment()
    //   .subtract(1, "day")
    //   .format("DD-MM-YYYY");
    this.state = {
      aggregationType: EnergyFrequencyTypes.Month,
      end: moment().subtract(1, "days"),
      start: moment().subtract(31, "days"),
      openPicker: false
    };
    this.validEndDateSelection = this.validEndDateSelection.bind(this);
    this.handleAggregationTypeSelection = this.handleAggregationTypeSelection.bind(
      this
    );
    this.handleGenerateClick = this.handleGenerateClick.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleDatePickerClick = this.handleDatePickerClick.bind(this);
    this.closeDatePicker = this.closeDatePicker.bind(this);
    this.handleRangeTranslation = this.handleRangeTranslation.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = findDOMNode(this.refs.datePickerRef);

    if (!domNode || !domNode.contains(event.target)) {
      if(this.state.openPicker) {
        this.setState({
         openPicker:false
        });
      }
    }
  };

  handleRangeTranslation = range => {
    const reg = /([a-zA-Z])\w+/g;
    const result = range.match(reg);
    let rangeTrans = range;
    if (range === "A Day") {
      const resultRange = Labels.MonitorDropDown.filter(dt => dt.Key === range)[0];
      return i18next.t(resultRange.Value);
    }

    result.map((item) => {
      const resultRange = Labels.MonitorDropDown.filter(dt => dt.Key === item)[0];
      rangeTrans = rangeTrans.replace(item, i18next.t(resultRange.Value));
      return rangeTrans;
    });
    return rangeTrans;
  }
  
  handleAggregationTypeSelection(aggregationType) {
    const start = EnergyAggregationHelper.getStartDateforAggregation(
      aggregationType,
      this.state.end
    );
    this.setState({
      aggregationType,
      start
    });
  }

  handleEndDateChange(date) {
    if (moment(date).isSameOrAfter(moment().format())) return;
    const start = EnergyAggregationHelper.getStartDateforAggregation(
      this.state.aggregationType,
      date
    );
    this.setState({ end: date, start, openPicker: false });
  }

  handleGenerateClick() {
    this.props.onGenerate(
      this.state.aggregationType,
      this.state.start,
      this.state.end
    );
  }

  handleDatePickerClick() {
    const togglePicker = this.state.openPicker;
    this.setState({ openPicker: !togglePicker });
  }

  validEndDateSelection=(currentDate) => {
    return currentDate.isBefore(moment().subtract(1, "day"));
  }

  closeDatePicker() {
    if (this.state.openPicker) {
      this.setState({ openPicker: false });
    }
  }

  render() {
    const aggregationTypes = [];

    EnergyFrequencyTypesList.forEach(item => {
      aggregationTypes.push(
        <li
          key={item.Key}
          className="dropdown-item"
          role="option"
          aria-selected={false}
          data-value={item.Key}
          onClick={event =>
            this.handleAggregationTypeSelection(
              event.target.getAttribute("data-value")
            )
          }
          onKeyPress={event =>
            this.handleAggregationTypeSelection(
              event.target.getAttribute("data-value")
            )
          }
        >
          {this.handleRangeTranslation(item.Value.Display)}
        </li>
      );
    });
    const siteOptions = this.props.sitesWithPermission.map(i => ({
      key: i.id,
      value: i.name,
      color: i.color.Maincolor
    }));

    return (
      <>
        <div className="row row-margin-preference">
          <div className="col-sm-3 dropdown">
            <p className="energy-dropdown-title mb-0">{i18next.t(Labels.RANGE)}</p>
            <button
              className="col btn btn-secondary dropdown-toggle energy-range-dropdown"
              type="button"
              id="dropdownMenuButton10"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="energy-range-text">
                {
                  this.handleRangeTranslation(EnergyFrequencyTypesList.filter(
                    v => v.Key === this.state.aggregationType
                  )[0].Value.Display)
                  // shd add translation func to display default value
                }
              </span>
            </button>
            <div
              className="dropdown-menu p-0 monitor-range-dropdown"
              aria-labelledby="dropdownMenuButton2"
            >
              {aggregationTypes}
            </div>
          </div>

          <div className="col-sm-3" >
            <p className="energy-dropdown-title row row-margin mb-0">{i18next.t(Labels.FROM)}</p>
            <div    ref="datePickerRef" className="row row-margin energy-datepicker-container">
              <div
                onClick={this.handleDatePickerClick}
                onKeyPress={this.handleDatePickerClick}
                className="energy-calendar-icon-container col-1"
              >
                <CalendarIcon width={16} height={16} />
              </div>
              <div
                className="col pl-1 pr-0"
              >
                {(this.state.aggregationType ===
                  EnergyFrequencyTypes.ThreeMonths ||
                  this.state.aggregationType === EnergyFrequencyTypes.Year) && (
                  <Datetime
                    locale={LanguageSelector.SelectLanguage()}
                    defaultValue={this.state.end}
                    value={
                      this.state.end // value={this.state.event.scheduleStartDate}
                    }
                    inputProps={{
                      readOnly: true,
                      onClick: this.handleDatePickerClick,
                      className: "energy-datepicker-inputbox"
                    }}
                    open={this.state.openPicker}
                    onChange={this.handleEndDateChange}
                    isValidDate={this.validEndDateSelection}
                    timeFormat={false}
                    dateFormat="MM/YYYY"
                  />
                )}
                {this.state.aggregationType ===
                  EnergyFrequencyTypes.FiveYears && (
                  <Datetime
                    locale={LanguageSelector.SelectLanguage()}
                    defaultValue={this.state.end}
                    value={
                      this.state.end // value={this.state.event.scheduleStartDate}
                    }
                    inputProps={{
                      readOnly: true,
                      onClick: this.handleDatePickerClick,
                      className: "energy-datepicker-inputbox"
                    }}
                    open={this.state.openPicker}
                    onChange={this.handleEndDateChange}
                    isValidDate={this.validEndDateSelection}
                    dateFormat="YYYY"
                    timeFormat={false}
                  />
                )}
                {this.state.aggregationType !==
                  EnergyFrequencyTypes.FiveYears &&
                  this.state.aggregationType !==
                    EnergyFrequencyTypes.ThreeMonths &&
                  this.state.aggregationType !== EnergyFrequencyTypes.Year && (
                    <Datetime
                      locale={LanguageSelector.SelectLanguage()}
                      defaultValue={this.state.end}
                      value={
                        this.state.end // value={this.state.event.scheduleStartDate}
                      }
                      inputProps={{
                        readOnly: true,
                        onClick: this.handleDatePickerClick,
                        className: "energy-datepicker-inputbox"
                      }}
                      open={this.state.openPicker}
                      onChange={this.handleEndDateChange}
                      isValidDate={this.validEndDateSelection}
                      timeFormat={false}
                      dateFormat="MM/DD/YYYY"
                    />
                  )}
              </div>
              <span
                onClick={this.handleDatePickerClick}
                onKeyPress={this.handleDatePickerClick}
                className="dropdown-toggle pt-2 mt-1 pr-2 mr-1"
              />
            </div>
          </div>
        
          <div className="col-sm-3">
            <p className="energy-dropdown-title mb-0">{i18next.t(Labels.FACILITIES)}</p>
            <MultiSelectDropDownList
              dataOptions={siteOptions}
              selectChange={this.props.handleSiteCheck}
            />
          </div>
        
          <div className=" col-sm-2" style={{marginLeft:"auto"}}>
            <button
              type="button"
              className={
                this.props.isButtonEnabled
                  ? "btn eua-monitor-generate-button eua-interact-btn"
                  : "btn eua-monitor-generate-button eua-interact-btn eua-monitor-generate-disabled"
              }
              onClick={this.handleGenerateClick}
            >
              {i18next.t(Labels.GENERATE)}
            </button>
          </div>
        </div>
      </>
    );
  }
}

AggregationFilter.propTypes = {
  onGenerate: PropTypes.func,
  sitesWithPermission: PropTypes.instanceOf(Array),
  handleSiteCheck: PropTypes.func,
  isButtonEnabled: PropTypes.bool
};

AggregationFilter.defaultProps = {
  onGenerate: null,
  sitesWithPermission: [],
  handleSiteCheck: null,
  isButtonEnabled: false
};

export default AggregationFilter;
