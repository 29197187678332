import React from "react";
import i18next from "i18next";
import * as Labels from "../constants/Labels"

const NotFound = () => {
  return (
    <div className="Not-Found">
      <h1> {i18next.t(Labels.NOTFOUND_404)}</h1>
      <h2>
        {i18next.t(Labels.RESOURCE_NOT_FOUND)}
      </h2>
    </div>
  );
};

export default NotFound;
