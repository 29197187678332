import ScheduleManagement from "./FieldScheduleManagement";
import Service from "../helpers/Service";
import ApiErrorHandler from "../helpers/ApiErrorHandler";
import LanguageSelector from "../Language/LanguageSelector";
import MasterScheduleManagement from "../MasterSchedule/MasterScheduleManagement";

class FieldSchedulingApi {
  static getFieldSchedule(siteId, fieldId, startDate, endDate) {
    LanguageSelector.SelectLanguage(siteId);
    return Service.get(
      `api/Schedule/GetSchedule/${siteId}/${fieldId}/${startDate}/${endDate}`
    )
      .then(json => {
        ScheduleManagement.setFieldSchedule(json.data.fieldSchedules);
        MasterScheduleManagement.setMasterSchedule(json.data.masterSchedules);
        return json;
      })
      .catch(error => {
        ScheduleManagement.setFieldSchedule([]);
        MasterScheduleManagement.setMasterSchedule([]);
        ApiErrorHandler.handleError(error);
      });
  }

  static createFieldSchedule(fieldSchedule) {
    LanguageSelector.SelectLanguage(fieldSchedule.siteId);
    return Service.post("api/Schedule/CreateScheduleForField", fieldSchedule)
      .then(json => json)
      .catch(error => {
        ApiErrorHandler.handleError(error);
      });
  }

  static editFieldSchedule(fieldSchedule) {
    LanguageSelector.SelectLanguage(fieldSchedule.siteId);
    return Service.put("api/Schedule/Edit", fieldSchedule)
      .then(json => json)
      .catch(error => {
        ApiErrorHandler.handleError(error);
        return error;
      });
  };

  static removeFieldSchedule(fieldSchedule) {
    LanguageSelector.SelectLanguage(fieldSchedule.siteId);
    return Service.delete("api/Schedule/Remove", fieldSchedule)
      .then(json => json)
      .catch(error => {
        ApiErrorHandler.handleError(error);
        return error;
      });
  }
}

export default FieldSchedulingApi;
