import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import SiteConsumptionDetails from "./SiteConsumptionDetails";
import "../css/ReactMultiCarousel.css";
import CustomRightArrow from "./CustomRightArrow";
import CustomLeftArrow from "./CustomLeftArrow";
// import ChevronLeft from "../Icons/ChevronLeft";
// import ChevronRight from "../Icons/ChevronRight";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
function groupIntoFours(children) {
  const output = [];
  let currentGroup = [];

  children.forEach((child, index) => {
    currentGroup.push(child);

    if (index % 4 === 3) {
      output.push(currentGroup);
      currentGroup = [];
    }
  });
  if (currentGroup.length > 0) {
    output.push(currentGroup);
    currentGroup = [];
  }
  return output;
}
function getState(data) {
  return {
    siteGroup: groupIntoFours(data),
    activeSiteIndex: 0
  };
}

class SiteListFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = getState(props.energyData);
    this.TogglePrev = this.TogglePrev.bind(this);
    this.ToggleNext = this.ToggleNext.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.energyData !== prevProps.energyData) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        siteGroup: groupIntoFours(this.props.energyData),
        activeSiteIndex: 0
      });
    }
  }

  TogglePrev() {
   
    const current = this.state.activeSiteIndex;
    let prev = current - 1;
    if (prev < 0) {
      prev = this.state.siteGroup.length - 1;
    }
    // update our state
    this.setState({ activeSiteIndex: prev });
  }

  ToggleNext() {
    
    // take a copy of our state
    const current = this.state.activeSiteIndex;
    // next video
    let next = current + 1;
    if (next >= this.state.siteGroup.length) {
      next = 0;
    }
    this.setState({ activeSiteIndex: next });
  }

  render() {
    return (
      <Carousel
        responsive={responsive}
        arrows
        infinite
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        renderButtonGroupOutside
        partialVisible={false}
        centerMode={false}
        swipeable
        removeArrowOnDeviceType={["mobile"]}
      >
        {this.props.energyData.map(site => {
          return (
            <SiteConsumptionDetails
              key={site.siteId}
              siteDetails={site}
              rangeType={this.props.rangeType}
              siteActiveState={this.props.sitesStates.get(site.siteId)}
              handleSiteClick={this.props.handleSiteClick}
            />
          );
        })}
      </Carousel>
    );
  }
}

SiteListFooter.propTypes = {
  energyData: PropTypes.instanceOf(Array),
  rangeType: PropTypes.string,
  handleSiteClick: PropTypes.func,
  sitesStates: PropTypes.instanceOf(Map)
};

SiteListFooter.defaultProps = {
  energyData: [],
  rangeType: "",
  handleSiteClick: null,
  sitesStates: new Map()
};

export default SiteListFooter;
