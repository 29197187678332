const MonitorTypes = {
  Alerts: "Alerts",
  Consumption: "Energy usage",
  Usage: "Field usage"
};
const EnergyFrequencyTypes = {
  Day: "Hourly",
  SevenDays: "Daily",
  Month: "Monthly",
  Year: "Yearly",
  ThreeMonths: "3 months",
  FiveYears: "FiveYears"
};

const EnergyFrequencyTypesList = [
  {
    Key: EnergyFrequencyTypes.Day,
    Value: { Option: "Hour", Display: "A Day", apiValue: "Hourly" }
  },
  {
    Key: EnergyFrequencyTypes.SevenDays,
    Value: { Option: "Day", Display: "7 days", apiValue: "Daily" }
  },
  {
    Key: EnergyFrequencyTypes.Month,
    Value: { Option: "Day", Display: "31 Days", apiValue: "Daily" }
  },
  {
    Key: EnergyFrequencyTypes.ThreeMonths,
    Value: { Option: "Month", Display: "3 months", apiValue: "Monthly" }
  },
  {
    Key: EnergyFrequencyTypes.Year,
    Value: { Option: "Month", Display: "12 months", apiValue: "Monthly" }
  },
  {
    Key: EnergyFrequencyTypes.FiveYears,
    Value: { Option: "Year", Display: "5 Years", apiValue: "Yearly" }
  }
];

const MonitorTypesList = [
  // MonitorTypes.Alerts,
  MonitorTypes.Consumption,
  // MonitorTypes.Usage
];

export {
  MonitorTypes,
  MonitorTypesList,
  EnergyFrequencyTypesList,
  EnergyFrequencyTypes
};
