import React from "react";

const AboutContent = () => {
  return (
    <div className="about-content pl-4 pt-4">
      <p className="about-interact-sports">Interact Sports</p>
      <p>This website is provided for you by:</p>
      <p>
        Signify Netherlands B.V.
        <br />
        High Tech Campus 48 <br />
        5656 AE Eindhoven, The Netherlands
      </p>
      <p>
        For any technical problems, please contact your local Signify
        support. To disclose a vulnerability, <br />
        please refer to our
        <a
          className="about-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.signify.com/global/vulnerability-disclosure"
        >
          Responsible Disclosure Statement.
        </a>
      </p>
      <p>Copyright©2018-2019 Signify Holding. All rights reserved.</p>
    </div>
  );
};

export default AboutContent;
