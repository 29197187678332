/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import Select, { components } from "react-select";
import CustomCheckbox from "./CustomCheckbox";
import "../css/Monitor.css";
import Filter from "../Icons/Filter";
import * as Labels from "../constants/Labels";

const Option = input => {
  const { isSelected, value, data } = input;
  return (
    <div>
      <components.Option {...input} >
        <div style={{display:"flex"}}>
        <CustomCheckbox
          checked={isSelected}
          color={data.color}
        />
        <span className="msdOptionLabel">{value}</span>
        </div>
      </components.Option>
    </div>
  );
};
Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};

const CustomStyle = {
  multiValueLabel: base => ({
    ...base,
    color: "#3C3C41",
    backgroundColor: "#EBEBEC",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "24px"
  }),
  multiValueRemove: base => ({ ...base, display: "none" }),
  control: (base, state) => ({
    ...base,
    boxSizing: "border-box",

    borderRadius: "2px",
    backgroundColor: "#EBEBEC",
    height: "44px",
    width: "100%",
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? "none" : base.borderColor,
    ":hover": {
      borderColor: state.isFocused ? "none" : base.borderColor
    }
  }),
  option: base => ({
    ...base,
    backgroundColor: "#FFF",
    color: "#3C3C41",
    ":hover": {
      backgroundColor: "#D8D8D9"
    }
  })
};

class MultiSelectDropDownList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: false
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      if (this.state.menuIsOpen) {
        this.setState({
          menuIsOpen: false
        });
      }
    }
  };

  handleClick = () => {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen
    });
  };

  render() {
    const { dataOptions } = this.props;
    const ValueContainer = parameter => {
      const { getValue, selectProps } = parameter;
      const valueLength = getValue().length;
      return (
        <div
          style={{ width: "80%", height: "100%", cursor: "pointer" }}
          onClick={selectProps.onClick}
          onTouchEnd={selectProps.onClick}
          onKeyPress={selectProps.onClick}
        >
          <components.ValueContainer {...parameter}>
            {/* {`${nbValues} Selected`} */}
            {!selectProps.inputValue && valueLength === 0 ? (
              <p className="mb-0 mt-1 pt-1 pl-1">{i18next.t(Labels.NONE_SELECTED)}</p>
            ) : (
              <p className="mb-0 mt-1 pt-1 pl-1">{valueLength} {i18next.t(Labels.SELECTED)}</p>
            )}
          </components.ValueContainer>
        </div>
      );
    };
    const DropdownIndicator = props => (
      <div
        style={{ cursor: "pointer" }}
        onClick={props.selectProps.onClick}
        onTouchEnd={props.selectProps.onClick}
        onKeyPress={props.selectProps.onClick}
      >
        <components.DropdownIndicator {...props}>
          <div
            style={{
             
              top: "33%"
            }}
          >
            <Filter />
          </div>
        </components.DropdownIndicator>
      </div>
    );
    return (
      <Select
        className="float-right eua-monitor-filter-dropdown"
        closeMenuOnSelect={false}
        isMulti
        isClearable={false}
        components={{
          Option,
          ValueContainer,
          IndicatorSeparator: () => null,
          DropdownIndicator
        }}
        menuIsOpen={this.state.menuIsOpen}
        options={dataOptions}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
      
        onChange={(values, actionMeta) => {
        
          if (values && values.length < 10) {
            this.props.selectChange(values);
          } else {
            values.splice(-1, 1);
            
            this.setState({
              ...values,
              [actionMeta.action]: "deselect-option"
            });
          }
        }}
        styles={CustomStyle}
        onClick={this.handleClick}
      />
    );
  }
}
MultiSelectDropDownList.propTypes = {
  dataOptions: PropTypes.instanceOf(Object).isRequired,
  selectChange: PropTypes.func
};
MultiSelectDropDownList.defaultProps = {
  selectChange: () => {}
};
export default MultiSelectDropDownList;
