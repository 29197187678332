import React from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import { Link } from "react-router-dom";
import CalendarIcon from "../Icons/calendar";
import SliderIcon from "../Icons/Slider";
import BarChartIcon from "../Icons/BarChart";
import GearWheelIcon from "../Icons/GearWheel";
import { ApplicationTypes, Applications } from "../constants/AppConstants";

const dashboardDisabledButton = {
  color: "#BEBEBE",
  borderRadius: "5px",
  cursor: "auto"
};

const dashboardButton = {
  cursor: "pointer"
};
class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.getAppIcon = this.getAppIcon.bind(this);
  }

  getAppIcon = (applicationName, isDisabled) => {
    switch (applicationName) {
      case ApplicationTypes.Schedule:
        return <CalendarIcon isDisabled={isDisabled} />;
      case ApplicationTypes.Control:
        return <SliderIcon isDisabled={isDisabled} />;
      case ApplicationTypes.Monitor:
        return <BarChartIcon />;
      default:
        return <GearWheelIcon />;
    }
  };

  render() {
    const applicationList = [];
    Applications.forEach(appItem => {
      const isDisabled = !this.props.site.appPermissions.includes(appItem.Key);
      const AppIcon = this.getAppIcon(appItem.Value, isDisabled);
      applicationList.push(
        <li
          className="col"
          key={appItem.Key + this.props.site.id}
          role="option"
          aria-selected="false"
        >
          <Link
            className={isDisabled ? "disabled-link" : ""}
            to={`/Sites/${this.props.site.id}/${appItem.Value}`}
          >
            <div
              className="row"
              style={isDisabled ? dashboardDisabledButton : dashboardButton}
            >
              <div className="col-2">{AppIcon}</div>
              <div className="col">{i18next.t(appItem.Value.toUpperCase())}</div>
            </div>
          </Link>
        </li>
      );
    });

    return <ul className="row list-inline">{applicationList}</ul>;
  }
}
AppContainer.propTypes = {
  site: PropTypes.instanceOf(Object)
};
AppContainer.defaultProps = {
  site: null
};
export default AppContainer;
