import moment from "moment";
import {
  MasterScheduleTypes,
  ScheduleTypes
  // TwilightOffset
} from "../constants/ScheduleTypeConstants";

function concatDateTime(date, time) {
  const newDate = moment(date)
    .format("YYYY-MM-DD")
    .toString();
  return moment(`${newDate} ${time}`).toDate();
}
// function getTwilightEventTitle(sunRiseFixedTime, sunSetFixedTime, offSetTime) {
//   const sunriseOffset = TwilightOffset.filter(
//     t =>
//       t.Value.offsetHours === offSetTime.sunRiseOffsetHours &&
//       t.Value.offsetMinutes === offSetTime.sunRiseOffsetMins
//   )[0].Key;
//   const sunsetOffset = TwilightOffset.filter(
//     t =>
//       t.Value.offsetHours === offSetTime.sunSetOffsetHours &&
//       t.Value.offsetMinutes === offSetTime.sunSetOffsetMins
//   )[0].Key;
//   return `Twilight Schedule - \nSunrise Setting: From ${sunRiseFixedTime} till ${sunriseOffset} after sunrise. \nSunset Setting: From ${sunsetOffset} before sunset till ${sunSetFixedTime}.`;
// }
class MasterSchedule {
  constructor(data) {
    this.isTwilight =
      ScheduleTypes.TwilightSchedule ===
      MasterScheduleTypes.filter(f => f.Key === data.masterScheduleType)[0]
        .Value;
    this.id = data.id;
    this.scheduleId = data.scheduleId;
    this.siteId = data.siteId;
    // data.id; // `${data.description} | ${data.userName}`;
    this.controllerNodeId = data.ControllerNodeId;
    this.programName = data.ProgramName;
    this.start =
      data.schedulingFrequency === 0
        ? concatDateTime(data.scheduleStartDate, data.startTime)
        : concatDateTime(data.recurringScheduleStartDate, data.startTime);
    this.end =
      data.schedulingFrequency === 0
        ? concatDateTime(data.scheduleEndDate, data.endTime)
        : concatDateTime(data.recurringScheduleEndDate, data.endTime);
    this.masterScheduleType =
      data.masterScheduleType != null
        ? data.masterScheduleType
        : MasterScheduleTypes[0].Key;
    this.scheduleEndDate = data.scheduleEndDate;
    this.scheduleStartDate = data.scheduleStartDate;
    this.recurringScheduleStartDate = data.recurringScheduleStartDate;
    this.recurringScheduleEndDate = data.recurringScheduleEndDate;
    this.endTime = data.endTime;
    this.startTime = data.startTime;
    this.schedulingFrequency = data.schedulingFrequency;
    this.dayOfWeek = data.dayOfWeek;
    this.sunRiseFixedTime = data.sunRiseFixedTime;
    this.sunSetFixedTime = data.sunSetFixedTime;
    this.offSetTime = data.offSetTime;
    this.allDay = this.isTwilight;
    this.sunrise = data.sunrise;
    this.sunset = data.sunset;
    //   this.title =
    //     this.isTwilight === true && data.id != null && !data.isEdit
    //       ? getTwilightEventTitle(
    //           data.sunRiseFixedTime,
    //           data.sunSetFixedTime,
    //           data.offSetTime
    //         )
    //       : null;
    this.parentEndTime = data.parentEndTime;
    this.parentScheduleId = data.parentScheduleId;
    this.parentSchedulingFrequency = data.parentSchedulingFrequency;
    this.parentStartTime = data.parentStartTime;
    this.EditMasterType = data.EditMasterType;
    this.DeleteType = data.DeleteType;
    this.OldMasterScheduleType = data.OldMasterScheduleType;
    this.userName = data.userName;
    this.parentSunRiseFixedTime = data.parentSunRiseFixedTime;
    this.parentSunSetFixedTime = data.parentSunSetFixedTime;
    this.parentMasterScheduleType = data.parentMasterScheduleType;
    this.parentOffSetTime = data.parentOffSetTime;
    this.isEditable = data.isEditable;
  }
}

export default MasterSchedule;
