import React from "react";

const Help = () => (
  <svg viewBox="0 0 24 24" version="1.1">
    <desc>Created with sketchtool.</desc>
    <defs>
      <path
        d="M12.9092,14.3354 L12.9092,14.7274 L11.0912,14.7274 L11.0912,14.3354 C11.1062,11.2124 14.1022,11.0144 14.1022,9.0114 C14.1022,8.0004 13.2372,7.4404 12.0632,7.4404 C11.0472,7.4404 9.8442,8.1294 9.8442,9.0114 L8.0202,9.0114 C8.0942,7.3234 9.5922,5.6364 12.0632,5.6364 C14.5362,5.6364 15.9792,7.0434 15.9792,8.8654 C15.9792,11.8814 12.9842,11.8264 12.9092,14.3354 L12.9092,14.3354 Z M11.0002,19.0004 L13.0002,19.0004 L13.0002,17.0004 L11.0002,17.0004 L11.0002,19.0004 Z M12.0002,2.0004 C6.4772,2.0004 2.0002,6.4774 2.0002,12.0004 C2.0002,17.5234 6.4772,22.0004 12.0002,22.0004 C17.5222,22.0004 22.0002,17.5234 22.0002,12.0004 C22.0002,6.4774 17.5222,2.0004 12.0002,2.0004 L12.0002,2.0004 Z"
        id="path-1111"
      />
    </defs>
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1111" />
        </mask>
        <use fill="#FFFFFF" xlinkHref="#path-1111" />
      </g>
    </g>
  </svg>
);

export default Help;
