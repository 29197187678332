import FeaturePermissionKeys from "../../config/featureConfig.json";
import { STAGE } from "../constants/AppConstants";

class Configurations {
  isStageLocked = featureKey => {
    const featureStage = FeaturePermissionKeys.StageLocks[featureKey];
    const stageValues = FeaturePermissionKeys.Stages;
    // First check if the feature key is valid, if not it is locked
    if (!featureStage) {
      return true;
    }

    // If stageValue is wrong, then it is locked
    if (stageValues[featureStage] === undefined) {
      return true;
    }

    if (stageValues[featureStage] < stageValues[STAGE]) {
      return true;
    }

    return false;
  };
}

const FeatureConfigurations = new Configurations();
export default FeatureConfigurations;
