import React from "react";
import i18next from "i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import * as Labels from "../constants/Labels";
import SchedulingApi from "./FieldSchedulingApi";
import NotificationActions from "../helpers/Notification";
import FieldSchedule from "../datamodels/FieldSchedule";
import SchedulingUtilities from "../helpers/SchedulingUtilities";
import "../css/Schedule.css";
import LanguageSelector from "../Language/LanguageSelector";

class DeleteFieldScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteSeries: false,
      deleteModal: this.props.show,
      onRemove: false
    };

    this.handleDeleteSeries = this.handleDeleteSeries.bind(this);
    this.handleDeleteOccurrence = this.handleDeleteOccurrence.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.removeOk = this.removeOk.bind(this);
  }

  handleDeleteSeries() {
    this.setState({
      deleteSeries: true,
    });
    this.onRemove();
  }

  handleDeleteOccurrence() {
    this.setState({
      deleteSeries: false,
    });
    this.onRemove();
  }

  handleCancelDelete() {
    this.setState({
      deleteSeries: false,
      deleteModal: true,
      onRemove: false
    });
  }

  onRemove = () => {
    this.setState({
      deleteModal: false,
      onRemove: true
    });
  }

  removeOk() {
    const deleteSchedule = new FieldSchedule({
      scheduleId: this.props.event.scheduleId,
      fieldId: this.props.event.fieldId,
      scheduleStartDate: !this.state.deleteSeries || this.props.event.parentSchedulingFrequency === 0 ? SchedulingUtilities.getOnlyDate(this.props.event.recurringScheduleStartTime) : SchedulingUtilities.getOnlyDate(this.props.event.actualStartDt),
      scheduleEndDate: !this.state.deleteSeries || this.props.event.parentSchedulingFrequency === 0 ? SchedulingUtilities.getOnlyDate(this.props.event.recurringScheduleEndTime) : SchedulingUtilities.getOnlyDate(this.props.event.actualEndDt),
      userName: this.props.event.userName,
      siteId: this.props.match.params.siteId,
      DeleteType: this.state.deleteSeries || this.props.event.parentSchedulingFrequency === 0 ? 0 : 1
    });
    SchedulingApi.removeFieldSchedule(deleteSchedule)
      .then(response => {
        if (response && (response.status === 202 || response.status === 200)) {
          NotificationActions.success(
            i18next.t(Labels.FIELD_SCHEDULE_DELETE_SUCCESS)
          );
          this.props.save();
        } else {
          /* eslint-disable no-console */
          console.log(response.message);
          /* eslint-enable no-console */
        }
      })
      .catch(error => {
        /* eslint-disable no-console */
        console.log(error.message);
        /* eslint-enable no-console */
      });
  }

  render() {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    let modal = '';
    if (this.state.onRemove) {
      modal = (
        <Modal show={this.state.onRemove} className="delete-schedule-container">
          <Modal.Body className="delete-schedule-modalbody">
            <div>
              <p className="delete-title">{i18next.t(Labels.REMOVE_BUTTON)}</p>
              <p className="delete-message">{i18next.t(Labels.FIELD_SCHEDULE_DELETE_CONFIRM)}</p>
              <div>
                <button
                  type="button"
                  onClick={this.removeOk}
                >
                  {i18next.t(Labels.OK)}
                </button>
                <br />
                <button
                  type="button"
                  onClick={this.handleCancelDelete}
                >
                  {i18next.t(Labels.CANCEL_BUTTON)}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }

    else if (this.state.deleteModal) {
      modal = (
        <Modal show={this.state.deleteModal} onHide={this.props.close} className="delete-schedule-container">
          <Modal.Body className="delete-schedule-modalbody">
            <div>
              <p className="delete-title">{`${i18next.t(Labels.DELETE)} ${i18next.t(Labels.EVENT)}`}</p>
              <p className="delete-message">{i18next.t(Labels.DELETE_SERIES_OCCURRENCE_MESSAGE)}</p>
              <div>
                <button
                  type="button"
                  onClick={this.handleDeleteOccurrence}
                  disabled={this.props.event.parentSchedulingFrequency !== 0 && SchedulingUtilities.getOnlyDate(this.props.event.recurringScheduleStartTime) === SchedulingUtilities.getOnlyDate(new Date()) || moment(SchedulingUtilities.getOnlyDate(new Date(this.props.event.recurringScheduleStartTime))).isBefore(moment(SchedulingUtilities.getOnlyDate(new Date())))}
                >
                  {i18next.t(Labels.DELETE_OCCURRENCE)}
                </button>
                <br />
                <button
                  type="button"
                  onClick={this.handleDeleteSeries}
                  disabled={this.props.event.parentSchedulingFrequency === 0}
                >
                  {i18next.t(Labels.DELETE_SERIES)}
                </button>
              </div>
              <button
                type="button"
                className="delete-cancel"
                onClick={this.props.save}
              >
                {i18next.t(Labels.CANCEL_BUTTON)}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
    return (
      <div>{modal}</div>
    );
  }
}

DeleteFieldScheduleModal.propTypes = {
  event: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object),
  show: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

DeleteFieldScheduleModal.defaultProps = {
  match: {}
}

export default DeleteFieldScheduleModal;
