import React from "react";
import PropTypes from "prop-types";

const SceneOff = props => {
  const svgStyle = {
    fill: props.active === "true" ? "#FF375F" : "#3c3c41"
  };
  return (
    <div className="scene-container ">
      <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="-10 0 66 66">
        <title>{props.label}</title>
        <desc>Created with sketchtool.</desc>
        <defs>
          <path
            d="M22.8798,65.9999992 C19.5678,65.9999992 16.8798,63.3119992 16.8798,59.9999992 L28.8798,59.9999992 C28.8798,63.3119992 26.1948,65.9999992 22.8798,65.9999992 Z M8.95379983,34.2920995 C4.04579994,30.0680996 1.2708,23.6840997 2.35679998,16.7240998 C3.70979995,8.02409989 11.3447998,1.07909999 20.6147996,0.0680999991 C21.4577995,-0.0218999997 24.7847995,-0.0218999997 25.6307994,0.0680999991 C32.4587993,0.812099989 38.3777991,4.79009994 41.5757991,10.3040999 L12.2897997,39.5900995 C11.7707998,37.5680995 10.6157998,35.7230995 8.95379983,34.2920995 Z M52.2425987,16.5644999 L43.0985989,25.7085003 C41.9705989,29.0325005 39.960599,31.9905007 37.2905991,34.2915008 C35.5955991,35.7495009 34.4225991,37.633501 33.9155991,39.6975011 C33.5915991,41.0145011 32.4245992,41.9955012 30.9725992,41.9955012 L26.8115993,41.9955012 L20.8265995,47.9805015 L29.1215993,47.9805015 C30.7805992,47.9805015 32.1215992,49.3215016 32.1215992,50.9805017 C32.1215992,52.6335018 30.7805992,53.9805018 29.1215993,53.9805018 L17.1215996,53.9805018 C16.4765996,53.9805018 15.9125996,53.7315018 15.4205996,53.3835018 L4.24259989,64.5645024 L0.000599999985,60.3194996 L48.0006,12.3194996 L52.2425987,16.5644999 Z"
            id="path-7"
          />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-880.000000, -431.000000)">
            <g transform="translate(336.000000, 44.000000)">
              <g transform="translate(529.000000, 384.000000)">
                <g transform="translate(15.000000, 3.000000)">
                  <mask fill="white">
                    <use href="#path-7" />
                  </mask>
                  <use id="Combined-Shape" style={svgStyle} href="#path-7" />
                  <g
                    id="🖌-/-02.-actionable-on-light"
                    mask="url(#mask-2)"
                    style={svgStyle}
                  >
                    <g
                      transform="translate(-9.000000, -3.000000)"
                      style={svgStyle}
                    >
                      <rect x="0" y="0" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

SceneOff.propTypes = {
  active: PropTypes.string,
  label: PropTypes.string
};

SceneOff.defaultProps = {
  active: "",
  label: ""
};

export default SceneOff;
