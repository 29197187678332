import React, { Component } from "react";
import i18next from "i18next";
import PropTypes from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Alert from "../components/Alert";
import SchedulingApi from "./FieldSchedulingApi";
import ScheduleManagement from "./FieldScheduleManagement";
import MasterScheduleManagement from "../MasterSchedule/MasterScheduleManagement";
import AddFieldSchedule from "./AddFieldScheduleModal";
import GifImageContainer from "../components/GifImageContainer";
import CalendarToolBar from "../calendar/CalendarToolbar";
import CustomEvent from "../calendar/CustomEvent";
import "../css/Schedule.css";
import SchedulingUtilities from "../helpers/SchedulingUtilities";
import { SceneTypes } from "../constants/ScenesConstants";
import * as Labels from "../constants/Labels";
import LanguageSelector from "../Language/LanguageSelector";
import EditFieldScheduleModal from "./EditFieldScheduleModal";
import EditedScheduleManagement from "./EditedScheduleManagement";

moment.locale('en-GB');
const localizer = momentLocalizer(moment);
// Calendar.momentLocalizer(moment);

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["showAlert","handleEventPropGetter","handleSlotPropGetter"] }] */
class FieldSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      showAddFieldSchedule: false,
      showEditFieldSchedule: false,
      isLoading: true,
      startOfMonth: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      endOfMonth: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
      masterScheduleEvents: [],
      fieldInfo: this.props.fields.find(
        field => field.id === this.props.match.params.fieldId
      ),
      view:"week"
    };

    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleAddFieldSchedule = this.handleAddFieldSchedule.bind(this);
    this.handleEditFieldSchedule = this.handleEditFieldSchedule.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleSlotPropGetter = this.handleSlotPropGetter.bind(this);
    this.handleEventPropGetter = this.handleEventPropGetter.bind(this);
    this.handleScene = this.handleScene.bind(this);
    this.handleSceneTranslate = this.handleSceneTranslate.bind(this);
    this.updateTimeIndicator = this.updateTimeIndicator.bind(this);
  }

  componentDidMount() {
    console.log("calender is rendered");
    const startOfWeek = moment()
      .startOf("week")
      .format("YYYY-MM-DD");
    const endOfWeek = moment()
      .endOf("week")
      .format("YYYY-MM-DD");
    if (
      this.state.startOfMonth <= startOfWeek &&
      this.state.endOfMonth >= endOfWeek
    )
      this.getSchedule(this.state.startOfMonth, this.state.endOfMonth);
    else this.getSchedule(startOfWeek, endOfWeek);
    document.addEventListener("click", this.handleClickOutside, true);
  }

  // to convert training-right & training-left scenes to TraningRight & TrainignLeft
  handleScene = sceneName => {
    if (sceneName.includes('-')) {
      const removeHyphenFromScene = sceneName.replace(/-/g, '');
      const formattedScene = removeHyphenFromScene.charAt(0).toUpperCase() + removeHyphenFromScene.slice(1, 8) + removeHyphenFromScene.charAt(8).toUpperCase() + removeHyphenFromScene.slice(9);
      return formattedScene;
    }
    return sceneName;
  };

  handleSceneTranslate = scene => {
    const result = Labels.SceneTypeForTranslation.filter(
      st => st.Key === scene
    )[0];
    const sceneName = result === undefined ? scene : result.Value;
    return sceneName;
  };

  handleSlotPropGetter(date) {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    this.updateTimeIndicator(this.state.view);
    const currentDate = moment(date).format("YYYY-MM-DD");
    let isDisabledColor = false;
    const currentDaySchedules = this.state.masterScheduleEvents.filter(item => moment(item.start).format("YYYY-MM-DD") === currentDate);
    const currentDateData = date;
    if (currentDaySchedules.length > 0) {
      const twilightSchedules = [];
      const fixedSchedules = [];
      // Seperation of Fixed and Twilight
      currentDaySchedules.map(item => {
        if (item.isTwilight) {
          twilightSchedules.push(item);
        } else {
          fixedSchedules.push(item);
        }
        return item;
      });

      // Assign Twilight higher priority and roundoff to next 15 min
      if (twilightSchedules.length > 0) {
        twilightSchedules.map(schedule => {
          if (schedule.sunRiseFixedTime === moment(schedule.start).format("hh:mm:ss").toString()) {
            // sunrise event
            const roundOffSeconds = schedule.end.getSeconds();
            const roundOffMin = schedule.end.getMinutes() % 15;
            const trimmingTime = moment.duration(`00:${roundOffMin}:${roundOffSeconds}`);
            const roundOffEndTime = moment(schedule.end).subtract(trimmingTime).format();
            if (!isDisabledColor) {
              isDisabledColor = currentDateData < new Date(roundOffEndTime) && currentDateData >= schedule.start;
            }
          } else if (!isDisabledColor) {
            isDisabledColor = currentDateData < schedule.end && currentDateData >= schedule.start;
          }
          return isDisabledColor;
        });
      }
      else if (fixedSchedules.length > 0) {
        fixedSchedules.map(schedule => {
          if (!isDisabledColor) {
            isDisabledColor = currentDateData < schedule.end && currentDateData >= schedule.start;
          }
          return isDisabledColor;
        });
      }
    } else {
      // No Master Schedule for the day then we should allow user to create Field Schedules for that day
      // isDisabledColor true means white color to the Slot of hour 
      isDisabledColor = true;
    }
    return {
      style: {
        backgroundColor: isDisabledColor ? "#fff" : "#F5F5F5"
      }
    }
  }

  handleEventPropGetter(event) {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    this.updateTimeIndicator(this.state.view);
    const events = EditedScheduleManagement.getEditedSchedulesList();
    const { masterScheduleEvents } = this.state;
    let eventStyle = "outside-schedule";

    const eventDay = moment(event.start).format("YYYY-MM-DD");
    const eventDayMasterSchedules = masterScheduleEvents.filter(item => moment(item.start).format("YYYY-MM-DD") === eventDay);

    if (eventDayMasterSchedules && eventDayMasterSchedules.length > 0) {
      // MasterSchedule there for event day
      const twilightSchedules = [];
      const fixedSchedules = [];

      // Seperation of Fixed and Twilight
      eventDayMasterSchedules.map(item => {
        if (item.isTwilight) {
          twilightSchedules.push(item);
        } else {
          fixedSchedules.push(item);
        }
        return item;
      });

      let prioritySchedules;
      // TwilightSchedule priority
      // Twilight Schedule check
      if (twilightSchedules.length > 0) {
        prioritySchedules = twilightSchedules;
      } else {
        prioritySchedules = fixedSchedules;
      }

      // check for within master schedules
      let isWithin = false;
      prioritySchedules.map(schedule => {
        if (!isWithin) {
          isWithin = event.end <= schedule.end && event.start >= schedule.start;
        }
        return isWithin;
      });

      // isWithin true  Within the master schedule and check for partial
      if (isWithin) {
        eventStyle = "within-schedule";
      } else {
        let partialSchedule = false;
        prioritySchedules.map(schedule => {
          if (!partialSchedule) {
            partialSchedule = (event.end > schedule.start && event.end < schedule.end) || (event.start > schedule.start && event.start < schedule.end);
          }
          return partialSchedule;
        });
        eventStyle = partialSchedule ? "partil-schedule" : "outside-schedule";
      }
    } else {
      // No Master Schedule for the day then we should allow user to create Field Schedules for that day
      eventStyle = "within-schedule";
    }

    if (events.includes(event.parentScheduleId))
    {
      const editedEventStyle = "edited-event";
      return {
        className: `${editedEventStyle}`,
        style: {
          border: "1px solid #FF37BE",
        borderRadius: "2px"
        }
      }
    }

    const pastEventStyle = event.end < new Date() ? "past-event" : "";

    return {
      className: `${eventStyle} ${pastEventStyle}`,
      style: {
        border: "1px solid #FF37BE",
        borderRadius: "2px"
      }
    }
  }

  handleNavigate(date, view) {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    this.setState({
      view
    });
    localStorage.setItem("CalendarLocale", LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    if (view === "day") {
      if (
        moment(date).format("YYYY-MM-DD") >= this.state.startOfMonth &&
        moment(date).format("YYYY-MM-DD") <= this.state.endOfMonth
      )
        return;
    }
    if (view === "week") {
      const startOfWeek = moment(date)
        .startOf("week")
        .format("YYYY-MM-DD");
      const endOfWeek = moment(date)
        .endOf("week")
        .format("YYYY-MM-DD");

      if (
        this.state.startOfMonth <= startOfWeek &&
        this.state.endOfMonth >= endOfWeek
      )
        return;
    }
    const startOfMonth = moment(date)
      .startOf("month")
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(date)
      .endOf("month")
      .add(7, "d")
      .format("YYYY-MM-DD");

    this.getSchedule(startOfMonth, endOfMonth);
  }

  handleViewChange(view) {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    this.setState({
      view
    });
    localStorage.setItem("CalendarLocale", LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    if (view === "month") {
      const monthDiff = moment(this.state.endOfMonth).diff(
        moment(this.state.startOfMonth),
        "months",
        true
      );
      if (monthDiff < 1) {
        const startOfMonth = moment(this.state.startOfMonth)
          .startOf("month")
          .subtract(7, "d")
          .format("YYYY-MM-DD");
        const endOfMonth = moment(this.state.endOfMonth)
          .endOf("month")
          .add(7, "d")
          .format("YYYY-MM-DD");
        this.getSchedule(startOfMonth, endOfMonth);
      }
    }
  }

  handleAddFieldSchedule() {
    // if (moment(slotInfo.start).isBefore(moment().format())) {
    //   this.setState({
    //     showAlert: true,
    //     message: "Select a future time slot."
    //   });
    //   return;
    // }

    const newEvent = {
      fieldId: this.state.selectedField.id,
      start: new Date(),
      scene: this.state.selectedField.customScenes.filter(
        scene => scene.perfectPlayScene === SceneTypes.COMFORT
      )[0].perfectPlayScene,
      end: new Date(),
      description: "",
      actualStartDt: new Date(),
      actualEndDt: new Date(new Date().getFullYear(), 11, 31),
      schedulingFrequency: 0,
      customScenes: this.state.selectedField.customScenes,
      parentSchedulingFrequency: 0
    };
    this.setState({
      showAddFieldSchedule: true,
      selectedEvent: newEvent
    });
  }

  handleEditFieldSchedule(event) {
    const daysOfWeek = [];
    const schedules = ScheduleManagement.getFieldSchedule();
    schedules.forEach(schedule => {
      if (
        schedule.parentSchedulingFrequency !== 0 &&
        schedule.parentSchedulingFrequency !== 1
      ) {
        const eventScheduleId =
          event.parentScheduleId === null
            ? event.scheduleId
            : event.parentScheduleId;
        const scheduleIdToCompare =
          schedule.parentScheduleId === null
            ? schedule.scheduleId
            : schedule.parentScheduleId;
        if (eventScheduleId === scheduleIdToCompare) {
          if (!daysOfWeek.includes(schedule.dayOfWeek)) {
            daysOfWeek.push(schedule.dayOfWeek);
          }
        }
      }
    });
    if (event.isNonEditable) return;
    this.setState({
      showEditFieldSchedule: true,
      selectedEvent: {
        ...event,
        start:
          event.parentSchedulingFrequency === 0
            ? event.start
            : SchedulingUtilities.concatDateTime(
              event.scheduleStartDate,
              event.parentStartTime
            ),
        end:
          event.parentSchedulingFrequency === 0
            ? SchedulingUtilities.concatDateTime(
              event.start,
              event.endTime
            )
            : SchedulingUtilities.concatDateTime(
              event.scheduleStartDate,
              event.parentEndTime
            ),
        actualStartDt: new Date(event.scheduleStartDate),
        actualEndDt: new Date(event.scheduleEndDate),
        recurringScheduleStartDate: event.recurringScheduleStartDate,
        recurringScheduleEndDate: event.recurringScheduleEndDate,
        recurringScheduleStartTime: SchedulingUtilities.concatDateTime(
          event.recurringScheduleStartDate,
          event.startTime
        ),
        recurringScheduleEndTime: SchedulingUtilities.concatDateTime(
          event.recurringScheduleEndDate,
          event.endTime
        ),
        daysOfWeek,
      }
    });
  }

  handleClose() {
    this.setState({
      showAddFieldSchedule: false,
      showEditFieldSchedule: false,
    });
  }

  handleSave() {
    this.setState({
      showAddFieldSchedule: false,
      isLoading: false,
      showEditFieldSchedule: false,
    });
    const startOfMonth = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment()
      .endOf("month")
      .format("YYYY-MM-DD");
    const startOfWeek = moment()
      .startOf("week")
      .format("YYYY-MM-DD");
    const endOfWeek = moment()
      .endOf("week")
      .format("YYYY-MM-DD");
    if (
      this.state.startOfMonth <= startOfWeek &&
      this.state.endOfMonth >= endOfWeek
    )
      this.getSchedule(startOfMonth, endOfMonth);
    else this.getSchedule(startOfWeek, endOfWeek);
  }

  getSchedule(startOfMonth, endOfMonth) {
    const selectedField = this.props.fields.find(
      field => field.id === this.props.match.params.fieldId
    );
    SchedulingApi.getFieldSchedule(
      this.props.match.params.siteId,
      this.props.match.params.fieldId,
      startOfMonth,
      endOfMonth
    )
      .then(() => {
        const masterScheduleData = MasterScheduleManagement.getMasterSchedule();
        const data = ScheduleManagement.getFieldSchedule();
        data.forEach(item => {
          item.customScenes = selectedField.customScenes; // eslint-disable-line no-param-reassign
          const val = selectedField.customScenes.find(
            scene => scene.perfectPlayScene === item.scene
          );
          // eslint-disable-next-line no-param-reassign
          item.title = val && val.displayScene;
          // eslint-disable-next-line no-param-reassign
          item.masterScheduleData = masterScheduleData;
        });

        this.setState({
          events: data,
          selectedField,
          isLoading: false,
          startOfMonth,
          endOfMonth,
          masterScheduleEvents: masterScheduleData
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  updateTimeIndicator = view => {
    const timeIndicator = document.querySelector('.rbc-current-time-indicator');
    if (timeIndicator) {
      const nDayOfWeek = new Date().getDay();
      let left;
      let width;

      if (view === "day") {
        left = 0;
        width = 100;
      } if (view === "week") {
        left = (nDayOfWeek - 1) * -100;
        width = 700;
      }

      timeIndicator.style.setProperty('--width', `${width}%`);
      timeIndicator.style.setProperty('--left', `${left}%`);
    }
  };

  closeAlert() {
    this.setState({
      showAlert: false,
      message: ""
    });
  };

  render() {
    i18next.changeLanguage(LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    if (this.state.isLoading) {
      return (
        <div className="eua-LoadingContainer-Screen flexbox-centered-items content">
          <GifImageContainer alt="Loading" src="/images/loading.gif" />
        </div>
      );
    }
    let modal = "";
    if (this.state.showAddFieldSchedule) {
      modal = (
        <AddFieldSchedule
          event={this.state.selectedEvent}
          close={this.handleClose}
          save={this.handleSave}
          show={this.state.showAddFieldSchedule}
          customScenes={this.state.selectedField.customScenes}
          masterData={this.state.masterScheduleEvents}
          startOfMonth={this.state.startOfMonth}
          endOfMonth={this.state.endOfMonth}
          match={this.props.match}
          isSeries={this.state.showEditFieldSchedule}
          isCreate={this.state.showAddFieldSchedule}
        />
      );
    }
    if (this.state.showEditFieldSchedule) {
      modal = (
        <EditFieldScheduleModal
          event={this.state.selectedEvent}
          close={this.handleClose}
          save={this.handleSave}
          show={this.state.showEditFieldSchedule}
          fieldName={this.state.fieldInfo.name}
          match={this.props.match}
          customScenes={this.state.selectedField.customScenes}
          masterData={this.state.masterScheduleEvents}
          startOfMonth={this.state.startOfMonth}
          endOfMonth={this.state.endOfMonth}
        />
      );
    }
    const isSelectable = true;
    localStorage.setItem("CalendarLocale", LanguageSelector.SelectLanguage(this.props.match.params.siteId));
    return (
      <div className="col eua-calendar h-100">
        <div className="field-calender">
          <button
            id="addMasterScheduleButton"
            onClick={this.handleAddFieldSchedule}
            onMouseDown={event => event.preventDefault()}
            data-toggle="modal"
            data-target="#fieldscheduleModal"
            type="button"
            className="btn eua-btn eua-interact-btn"
            style={{ position: "absolute", right: "2%" }}
          >
            {i18next.t(Labels.CREATE_EVENT_BUTTON)}
          </button>

          <Calendar
            localizer={localizer}
            culture={LanguageSelector.SelectLanguage(this.props.match.params.siteId)}
            defaultDate={new Date()}
            defaultView="week"
            events={this.state.events}
            selectable={isSelectable}
            onSelectEvent={event => this.handleEditFieldSchedule(event)}
            // onSelectSlot={slotInfo => this.handleAddFieldSchedule(slotInfo)}
            onNavigate={this.handleNavigate}
            onSelecting={() => false}
            onView={view => this.handleViewChange(view)}
            scrollToTime={new Date()}
            components={{
              toolbar: CalendarToolBar,
              event: CustomEvent
            }}
            slotPropGetter={this.handleSlotPropGetter}
            eventPropGetter={this.handleEventPropGetter}
            views={["day", "week", "month"]}
            step={15}
            timeslots={4}
          />
        </div>
        <Alert
          message={this.state.message}
          show={this.state.showAlert}
          close={this.closeAlert}
        />
        {modal}
      </div>
    );
  }
}
FieldSchedule.propTypes = {
  match: PropTypes.instanceOf(Object),
  fields: PropTypes.instanceOf(Array)
};

FieldSchedule.defaultProps = {
  match: {},
  fields: []
};

export default FieldSchedule;