import React from "react";

const ChevronRight = () => {
  const svgStyle = {
    display: "block",
    fill: "#3c3c41"
  };
  return (
    <svg width="22px" height="22px" viewBox="0 0 24 24" style={svgStyle}>
      <title />
      <polygon points="9.61 22 7.77 20.1 14.97 11.99 7.73 3.85 9.61 2 18.5 11.99 9.61 22" />
    </svg>
  );
};
export default ChevronRight;
