import React from "react";

const LeftArrow = () => {
  return (
    <svg width="24px" height="92px" viewBox="0 0 24 92">
      <g
        id="Global-dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="global_dashboard_day_highlighted_1024"
          transform="translate(0.000000, -800.000000)"
        >
          <g id="carousel" transform="translate(0.000000, 792.000000)">
            <g
              id="button-/-touch-hybrid-/-quiet-/-icon-only-/-01.default"
              transform="translate(0.000000, 8.000000)"
            >
              <rect id="bound" x="0" y="0" width="24" height="92" rx="2" />
              <polygon
                id="Fill-1"
                fill="#3C3C41"
                transform="translate(11.698000, 46.000000) scale(-1, 1) translate(-11.698000, -46.000000) "
                points="6 31 14 45.999 6 61 9.396 61 17.396 45.999 9.396 31"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default LeftArrow;
