import React from "react";

const ChevronRight = () => {
  const svgStyle = {
    display: "block",
    fill: "#3c3c41"
  };
  return (
    <svg width="22px" height="22px" viewBox="0 0 24 24" style={svgStyle}>
      <title />
      <polygon points="13.58 2 15.34 3.77 7.7 12.01 15.3 20.2 13.58 22 4.5 12.01 13.58 2" />
    </svg>
  );
};
export default ChevronRight;
